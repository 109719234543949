import React from 'react'
import video from "../assets/video.gif"
import Searchbar from './searchbar'

const UnderConstruction = () => {
  return (
    <div className='w-full h-full flex flex-col items-center  pt-32 '>
        <img className='object cover w-1/4 h-1/3' src={video} />
        <p> We are gearing up for our big release and are working to build this section.</p>
        <p>In case there is an urgent need, please get in touch at : <a className='underline' href={`mailto:connect@factacy.ai`} >connect@factacy.ai</a> </p>
        <div className='w-1/2 pt-9'>
        <Searchbar />
        </div>


    </div>
  )
}

export default UnderConstruction