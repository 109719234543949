import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_SECTOR_NEWS } from '../../store/slices/news_intelligence';
import { INDUSTRIAL_PORTFOLIO_NEWS } from '../../constant/api';

const useFetchSectorNews = (sectorName) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const isNewsExist = useSelector(store => store?.news_intelligence?.sectorsNews[sectorName] ? true : false)

    useEffect(() => {

        if (!isNewsExist && sectorName) {
            (
                async () => {

                    try {
                        setLoading(true);
                        const res = await fetch(INDUSTRIAL_PORTFOLIO_NEWS + sectorName);
                        const data = await res.json();
                        if (res.ok) {
                            setLoading(false);
                            dispatch(ADD_SECTOR_NEWS({ sectorName, newslist: data }));
                        }
                    } catch (error) {
                        setLoading(false);
                    }
                }
            )();
        }
    }, [sectorName]);

    return [loading, error];
}

export default useFetchSectorNews;