import React from "react";

const Confirmation_modal = ({ requestAiProfile, company, isOpen, onClose }) => {
  return (
    <>
      {/* Overlay */}
      {isOpen && <div className="fixed inset-0 bg-black opacity-50 z-50"></div>}

      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white w-[500px] h-max p-6 rounded-lg shadow-lg transition-all duration-300">
            <div className="flex justify-end">
              <button
                className="text-gray-600 hover:text-gray-800"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-col gap-1">
              <p className="font-semibold ">
                Are you sure you want to request Ai profiling for{" "}
                <span className="font-bold">
                  {company.Brand_Name ?? company.Company_Name}
                </span>
              </p>
              <div className="flex justify-around p-2">
                <button
                className="w-1/3 hover:bg-primaryBlue border-2 border-primaryBlue text-primaryBlue rounded-md hover:text-white font-semibold items-center flex flex-col py-2"
                  onClick={() =>
                    requestAiProfile(
                      company.Brand_Name ?? company.Company_Name,
                    )
                  }
                >
                  Yes
                </button>
                <button className="w-1/3 hover:bg-primaryBlue border-2 border-primaryBlue text-primaryBlue rounded-md hover:text-white font-semibold items-center flex flex-col py-2" onClick={onClose}>No</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Confirmation_modal;
