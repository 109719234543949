import React, {useState} from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DealCard from "./dealCard";
import IndividualDealCard from "./IndividualDealCard";

const DealCarouselSlider = ({ sortedData, dealIds }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      //   slidesToSlide: 2, // optional, default to 1.
      partialVisibilityGutter: 10,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      //   slidesToSlide: 2, // optional, default to 1.
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      //   slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: -10,
    },
  };


  return (
    <Carousel
      keyBoardControl={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      // showDots={true}
      responsive={responsive}
      swipeable={true}
      draggable={true}
      itemClass="carousel-item-padding-20-px w-max"
      partialVisible={true}
    >

      {dealIds &&
        dealIds
          .filter(
            (id) => sortedData[id]?.Status === "Confirmed" || sortedData[id]?.Status === "Updated"
          )
          .map((id, index) => (
            <div className="itemClass  lg:py-1 p-1 gap-0 flex w-full h-full" key={index}>
            <IndividualDealCard
              deal={sortedData[id]}
              setShowErrorMessage={setShowErrorMessage}
              showNews={true}
            />
            </div>
          ))}
    </Carousel>
  );
};

export default DealCarouselSlider;
