import { useAuth } from "./authContext";
import React, { useState } from "react";
import Loader from "../../components/tearSheet/loader";
import { useFormik } from "formik";
import { signUpSchema } from "../../schemas/index";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import visibility_off from "../../assets/visibility_off.svg";
import visibility_on from "../../assets/visibility_on.svg";

const PasswordComponent = ({ name, id, placeholder, value, change, blur }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inFocus, setInFocus] = useState(false);

  return (
    <div
      className={` w-full flex rounded-[0.04rem] outline-none px-2 py-1 outline-[#9ca3af] outline-[0.1rem] ${inFocus ? " outline-primaryBlue outline-[0.1rem]" : ""
        } `}
      onBlur={() => setInFocus(false)}
    >
      <input
        className=" flex-1 outline-none border-none"
        type={showPassword ? "text" : "password"}
        autoComplete="off"
        name={name}
        id={id}
        placeholder={placeholder}
        onFocus={() => setInFocus(true)}
        onBlur={blur}
        value={value}
        onChange={change}
      />
      <img
        className=" cursor-pointer h-5"
        src={showPassword ? visibility_on : visibility_off}
        alt="visibility_icon"
        onClick={() => setShowPassword((pre) => !pre)}
      />
    </div>
  );
};
const Register = () => {
  const { register, error } = useAuth();
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    userName: "",
    id: "",
    gstin: "",
  };

  const registerFunction = async (userData) => {
    try {
      setLoading(true);
      await register(userData);
      setLoading(false);
      // return "";
    } catch (error) { }
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      validationSchema: signUpSchema,
      initialValues: initialValues,
      onSubmit: (values, action) => {
        registerFunction(values)
      },
    });

  return (
    <div className=' h-screen w-screen flex justify-center items-center'>
      <div className=' overflow-y-auto rounded-xl shadow-xl items-center p-8 min-h-[420px] bg-[#fff] border-[1px] border-[#f1f1f1] w-[40rem]'>
        <div className="fadeIncrease">
          <h1 className=" text-3xl mb-4 font-bold">
          Skip the queue to join <span className=" text-primaryBlue"> 1200+ </span>early access users!
           
          </h1>
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <div className=" flex flex-col lg:flex-row gap-8">
              <div className=" flex-1 flex flex-col gap-2">
                <div className=" flex gap-2 items-end">
                  <p className=" text-md capitalize text-[#000000] font-medium">
                    First Name
                  </p>
                  <span className={`text-sm text-[#ff0000]`}>
                    {errors.firstName && touched.firstName ? errors.firstName : " "}
                  </span>
                </div>
                <input
                  className=" w-full rounded-[0.04rem] outline-none px-2 py-1 outline-[#9ca3af] outline-[0.1rem]  focus:outline-primaryBlue focus:outline-[0.1rem]"
                  type="firstName"
                  autoComplete="off"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className=" flex-1 flex flex-col gap-2">
                <div className=" flex gap-2 items-end">
                  <p className=" text-md capitalize text-[#000000] font-medium">
                    Last Name
                  </p>
                  <span className={`text-sm text-[#ff0000]`}>
                    {errors.lastName && touched.lastName ? errors.lastName : " "}
                  </span>
                </div>
                <input
                  className=" w-full rounded-[0.04rem] outline-none px-2 py-1 outline-[#9ca3af] outline-[0.1rem]  focus:outline-primaryBlue focus:outline-[0.1rem]"
                  type="lastName"
                  autoComplete="off"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className=" flex flex-col lg:flex-row gap-8">
              <div className=" flex-1 flex flex-col gap-2">
                <div className=" flex gap-2 items-end">
                  <p className=" text-md capitalize text-[#000000] font-medium">
                    E-mail
                  </p>
                  <span className={`text-sm text-[#ff0000]`}>
                    {errors.email && touched.email ? errors.email : " "}
                  </span>
                </div>
                <input
                  className=" w-full rounded-[0.04rem] outline-none px-2 py-1 outline-[#9ca3af] outline-[0.1rem]  focus:outline-primaryBlue focus:outline-[0.1rem]"
                  type="email"
                  autoComplete="off"
                  name="email"
                  id="email"
                  placeholder="E-mail"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className=" flex-1 flex flex-col gap-2">
                <div className=" flex gap-2 items-end">
                  <p className=" text-md capitalize text-[#000000] font-medium">
                    Phone number
                  </p>
                  <span className={`text-sm text-[#ff0000]`}>
                    {errors.phoneNumber && touched.phoneNumber
                      ? errors.phoneNumber
                      : " "}
                  </span>
                </div>
                <input
                  className=" w-full rounded-[0.04rem] outline-none px-2 py-1 outline-[#9ca3af] outline-[0.1rem]  focus:outline-primaryBlue focus:outline-[0.1rem]"
                  type="phone"
                  autoComplete="off"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="Phone number"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className=" flex flex-col lg:flex-row gap-8">
              <div className=" flex flex-col gap-2 flex-1">
                <div className=" flex gap-2 items-end">
                  <p className=" text-md capitalize text-[#000000] font-medium">
                    Company Name
                  </p>
                  <span className={`text-sm text-[#ff0000]`}>
                    {errors.companyName && touched.companyName
                      ? errors.companyName
                      : " "}
                  </span>
                </div>
                <input
                  className=" w-full rounded-[0.04rem] outline-none px-2 py-1 outline-[#9ca3af] outline-[0.1rem]  focus:outline-primaryBlue focus:outline-[0.1rem]"
                  type="companyName"
                  autoComplete="off"
                  name="companyName"
                  id="companyName"
                  placeholder="Company name"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              <div className="flex flex-col gap-2 flex-1">
                <div className=" flex gap-2 items-end ">
                  <p className=" text-md capitalize text-[#000000] font-medium">
                    GSTIN (Optional)
                  </p>
                  <span className={`text-sm text-[#ff0000]`}>
                    {errors.gstin && touched.gstin ? errors.gstin : " "}
                  </span>
                </div>
                <input
                  className=" w-full rounded-[0.04rem] outline-none px-2 py-1 outline-[#9ca3af] outline-[0.1rem]  focus:outline-primaryBlue focus:outline-[0.1rem]"
                  type="gstin"
                  autoComplete="off"
                  name="gstin"
                  id="gstin"
                  placeholder="Gstin"
                  value={values.gstin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className=" flex flex-col lg:flex-row gap-8">
              <div className=" flex-1 flex flex-col gap-2">
                <div className=" flex gap-2 items-end">
                  <p className=" text-md capitalize text-[#000000] font-medium">
                    Password
                  </p>
                  <span className={`text-sm text-[#ff0000]`}>
                    {errors.password && touched.password ? errors.password : " "}
                  </span>
                </div>
                <PasswordComponent
                  name={"password"}
                  id={"password"}
                  placeholder={"Password"}
                  value={values.password}
                  change={handleChange}
                  blur={handleBlur}
                />
              </div>
              <div className=" flex-1 flex flex-col gap-2">
                <div className=" flex gap-2 items-end">
                  <p className=" text-md capitalize text-[#000000] font-medium">
                    Confirm Password
                  </p>
                  <span className={`text-sm text-[#ff0000]`}>
                    {errors.confirmPassword && touched.confirmPassword
                      ? errors.confirmPassword
                      : " "}
                  </span>
                </div>
                <PasswordComponent
                  name={"confirmPassword"}
                  id={"confirmPassword"}
                  placeholder={"Confirm password"}
                  value={values.confirmPassword}
                  change={handleChange}
                  blur={handleBlur}
                />
              </div>
            </div>

            <div className=" flex justify-center ">
              {loading ? (
                <Loader />
              ) : (
                <button
                  type="submit"
                  className=" px-12 font-semibold py-2 border rounded-full bg-buttonCTA text-[#fff]"
                >
                  Submit
                </button>
              )}
            </div>
            <p className=" mt-4 text-md">
              Already have an account ?{" "}
              <span
                className=" text-buttonCTA font-semibold cursor-pointer"
                onClick={() => Navigate("/login")}
              >
                Login
              </span>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
