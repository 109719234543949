import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "./authContext";

const LoginButton = () => {
  const Navigate = useNavigate();
  const { setRedirect_url } = useAuth();
  const Params = useLocation();

  const loginNavigationHandler = () => {
    setRedirect_url(Params.pathname + Params.search);
    Navigate("/login");
  };

  return (
    <button
      className="flex justify-center gap-12 text-sm text-white py-3 px-6 font-semibold rounded-full h-max hover:underline-none"
      onClick={() => loginNavigationHandler()}
      style={{ backgroundColor: "rgba(62, 145, 238, 1)" }}
    >
      Login
    </button>
  );
};

export default LoginButton;
