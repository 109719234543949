import React from 'react'
import { useAuth } from './authContext'
const LogoutButton = () => {
    const {logout} = useAuth()
    const handleLogout = () => {
        logout()
        window.location.reload()
    }
  return (
    <button className='flex justify-center gap-12 text-sm text-white py-3 px-6 rounded-full h-max hover:underline-none font-semibold' 
        onClick={handleLogout} 
        style={{backgroundColor:"rgba(62, 145, 238, 1)"}}>
    Logout    
    </button>
  )
}

export default LogoutButton