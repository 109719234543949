import React from 'react'
import PatentSearch from './patentSearch';
import { Route, Routes } from "react-router-dom";
import SideBarLayoutPatent from './sideBarLayoutPatent';
import PatentDashboard from './patentDashboard';
import Sidebar from '../sideBarMain';
import { PATENT_SEARCH_BY_SECTOR, PATENT_SEARCH } from '../../constant/api';
import PatentByCompany from '../innovations/PatentByCompany';
import UnderConstruction from '../underConstruction';
import Errorpage from '../errorpage';
import MetaTagesAdder from '../MetaTagesAdder';

const PatentIntelligence = () => {
  return (
    <div className="w-full h-screen overflow-y-auto pt-20">
          <Routes>
            <Route className={`w-full section `} element={<MetaTagesAdder ><PatentSearch api={PATENT_SEARCH} heading={"Search for Patent"} placeholder={"Search for a patent, patentholder..."} /></MetaTagesAdder>} path="/patent-search" />
            <Route className={`w-full section `} element={<MetaTagesAdder ><PatentSearch api={PATENT_SEARCH_BY_SECTOR} heading={"Search for Patent from Industry name"} placeholder={"Search for a patent by industry name..."} /></MetaTagesAdder>} path="/patent-search-by-industry" />
            <Route className={`w-full section `} element={<MetaTagesAdder ><PatentByCompany /></MetaTagesAdder>} path="/patent-search-by-company" />
            {/* <Route className={`w-full section `} element={<PatentSearch api={""} heading={"Search for Patent from Investor name"} placeholder={"Search for a patent by investor name..."} />} path="/patent-search-by-investor" /> */}
            <Route element={<MetaTagesAdder ><PatentDashboard /></MetaTagesAdder>} path="/patent-dashboard" />
            <Route element={<MetaTagesAdder > <UnderConstruction /> </MetaTagesAdder>} path='/patent-search-by-investor' />
            <Route element={<Errorpage />} path='/*' />
          </Routes>
        </div>
  );
}

export default PatentIntelligence;