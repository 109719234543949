import { setIn } from 'formik';
import React, { useState } from 'react'
import { useEffect } from 'react';


const Slider = () => {
  const words = ['Vision to Venture', 'Insight to Impact', 'Start to Scale', 'Strategy to Success']
  const [part, setPart] = useState('')
  const [i, setI] = useState(0)
  const [offset, setOffset] = useState(0)
  const [forwards, setForwards] = useState(true)
  const [skipCount, setSkipCount] = useState(0)
  const [speed] = useState(70)
  const skipDelay = 20

  useEffect(() => {
    const interval = setInterval(() => {
      if (forwards) {
        if (offset >= words[i].length) {
          setSkipCount((prevCount) => prevCount + 1)
          if (skipCount === skipDelay) {
            setForwards(false)
            setSkipCount(0)
          }
        }
      } else {
        if (offset === 0) {
          setForwards(true)
          setI((prevI) => (prevI + 1 >= words.length ? 0 : prevI + 1))
          setOffset(0)
        }
      }
      const currentPart = words[i].substr(0, offset)
      if (skipCount === 0) {
        if (forwards) {
          setOffset((prevOffset) => prevOffset + 1)
        } else {
          setOffset((prevOffset) => prevOffset - 1)
        }
      }
      setPart(currentPart)
    }, speed)
    return () => clearInterval(interval)
  }, [forwards, i, offset, skipCount, speed, words])
  
  return (
    <div className='lg:text-5xl sm:text-3xl font-medium tracking-tight text-center lg:leading-none sm:leading-snug flex lg:h-16 sm:h-10'>
      <span>From {part}</span>
    </div>
  )
}

export default Slider
