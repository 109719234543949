import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const stageReducer = createSlice({
    name: "stageReducer",
    initialState: {
        loading: false,
        data: {},
        errorMsg: "",
        stage: '',
        stageData: []
    },

    reducers: {
        STAGE_SEARCH_LOADING: (state, action) => { return { ...state, loading: true, errorMsg: "" }; },
        STAGE_SEARCH_FAIL: (state, action) => { return { ...state, loading: false, errorMsg: "Unable to find stage" }; },
        STAGE_DATA_SUCCESS: (state, action) => {
            const {data, stageName} = action.payload;
             return { ...state, loading: false, errorMsg: "", stageData: { ...state.stageData, [stageName]: data, } }; },
    }
});

export const {STAGE_SEARCH_LOADING, STAGE_SEARCH_FAIL, STAGE_DATA_SUCCESS} = stageReducer.actions;
export default stageReducer.reducer;