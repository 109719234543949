import React, { useState } from 'react';
import { industries } from "../../../data/industries";
import closeLine1 from "../../../assets/close-line.svg";
import closeLine from "../../../assets/close-line - Copy.svg"

const SectorsSearch = ({ activeSector, setActiveSector }) => {
    const [selectedSectors, setSelectedSectors] = useState([]);
    const [text, setText] = useState('');
    const filteredSectors = text.length > 2 ? industries.filter(industry => industry.title.toLowerCase().includes(text.toLowerCase())) : [];

    const addSectorToList = (sectorName) => {
        setSelectedSectors(pre => pre.includes(sectorName) ? pre : [...pre, sectorName]);
        setText('');
    }
    const removeSectorFromList = (sectorName) => {
        setSelectedSectors(pre => pre.filter(item => item !== sectorName));
    }
    return (
        <>
            <div className='bg-white gap-8 flex flex-col p-4 w-full sm:z-100 rounded-2xl animate-wishlistWindow' style={{ boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)" }}>
                <div className='w-full h-max flex gap-3'>
                    <div className='w-full bg-white rounded-full flex justify-center h-14 px-4 gap-3 items-center' style={{ boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)" }}>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.4197 14.3912L20.6361 18.6076L19.2438 20L15.0274 15.7836C13.5114 16.9965 11.5887 17.7221 9.49757 17.7221C4.60648 17.7221 0.636902 13.7525 0.636902 8.8614C0.636902 3.97031 4.60648 0.000732422 9.49757 0.000732422C14.3887 0.000732422 18.3582 3.97031 18.3582 8.8614C18.3582 10.9525 17.6327 12.8752 16.4197 14.3912ZM14.4445 13.6607C15.6484 12.42 16.3892 10.7277 16.3892 8.8614C16.3892 5.05378 13.3052 1.96977 9.49757 1.96977C5.68995 1.96977 2.60594 5.05378 2.60594 8.8614C2.60594 12.669 5.68995 15.753 9.49757 15.753C11.3638 15.753 13.0562 15.0122 14.2969 13.8083L14.4445 13.6607Z" fill="#71717A" />
                        </svg>
                        <form className='w-full h-full'>
                            <input className='w-full h-full focus:outline-none' onChange={(e) => setText(e.target.value)} value={text} placeholder='Search for a sector...' />
                        </form>
                        <div className='cursor-pointer'>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.01764 11.4787C5.72948 11.4787 5.49588 11.7123 5.49588 12.0005C5.49588 12.2886 5.72948 12.5222 6.01764 12.5222L6.01764 11.4787ZM18.9087 12.3694C19.1124 12.1657 19.1124 11.8353 18.9087 11.6316L15.5883 8.31113C15.3845 8.10737 15.0541 8.10737 14.8504 8.31113C14.6466 8.51489 14.6466 8.84524 14.8504 9.049L17.8019 12.0005L14.8504 14.952C14.6466 15.1557 14.6466 15.4861 14.8504 15.6899C15.0541 15.8936 15.3845 15.8936 15.5883 15.6899L18.9087 12.3694ZM6.01764 12.5222L18.5397 12.5222L18.5397 11.4787L6.01764 11.4787L6.01764 12.5222Z" fill="#3E91EE" />
                                <rect x="0.802089" y="0.521754" width="22.9572" height="22.9572" rx="11.4786" stroke="#3E91EE" strokeWidth="1.04351" />
                            </svg>
                        </div>
                    </div>
                </div>
                {filteredSectors.length > 0 && (
                    <div className='bg-white w-full px-4 gap-2 h-full rounded-xl relative z-0 '>
                        <ul>
                            {filteredSectors.map((industry) => (
                                <li className='cursor-pointer p-2 hover:bg-slate-50 flex gap-1'
                                    onClick={() => addSectorToList(industry.title)}
                                    key={industry.id}>
                                    {industry.title}
                                </li>
                            ))}
                        </ul>
                    </div>)}


                <div className='flex flex-wrap gap-2'>
                    {selectedSectors.map((selected, index) => (
                        <div key={index} className='flex gap-1 items-center text-white cursor-pointer bg-primaryBlue w-max py-2 pl-3 pr-2 rounded-full'>
                            <p className='font-base'>{selected}</p>
                            <img className='w-4 h-4' src={closeLine1}
                                onClick={() => removeSectorFromList(selected)}
                            />
                        </div>
                    ))}
                </div>
                {/* <button onClick={()=>setShowSelectedSectors(true) & setSectorWindowOpen(false)} className='w-max p-3 text-white rounded-2xl bg-primaryBlue'>Apply Filters</button> */}
            </div>
            {/* SELECTED SECTORS ALREADY */}

            <div>
                <div className='flex flex-wrap gap-2 w-full'>
                    {selectedSectors.map((sector, index) => (
                        <div key={index}
                            className={`flex gap-1 items-center cursor-pointer  rounded-2xl w-max py-3 px-4 ${activeSector === sector ? "bg-white  shadow-sm" : "bg-gray-100"}`}
                        >
                            <button
                                // onClick={() => handleSelectedSector(sector)}
                                onClick={() => setActiveSector(sector)}
                            >
                                {sector}
                            </button>
                            <img className='w-4 h-4' src={closeLine} onClick={() => removeSectorFromList(sector)} />
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default SectorsSearch;