import React from "react";
import { useAuth } from "../auth/authContext";
import LoginComponent from "../loginComponent";

const InvestorLeaderboard = () => {
  const { user } = useAuth();

  return (
    <div className="lg:pt-0 px-4 sm:pt-10 sm:w-full lg:w-full flex flex-col">
      <div className="flex flex-col w-full bg-[#F4F5F7] rounded-xl lg:px-5 sm:px-4 lg:py-3 sm:py-3 justify-between gap-5">
        {user.isAuthenticated == true ? (
          <>
            <div className="flex comps-center justify-between">
              <p className="font-medium text-[25px] tracking-tight leading-9">
                Investor Dashboard
              </p>
            </div>
            <iframe
              style={{
                clipPath:
                  "polygon(0% 0%, 100% 0%, 100% calc(100% - 36px), 0% calc(100% - 36px))",
              }}
              title="Report Section"
              className="w-full h-[800px]"
              src="https://app.powerbi.com/view?r=eyJrIjoiMDZiZDg3MzktNjgyZC00ZTg3LTllODItZmE2MTlkOTVmYTNmIiwidCI6IjNmNGVmZDgwLTUzYjctNDc4MS04NDZhLTU2YTYyMzRiMTY0ZiJ9"
              frameborder="0"
              allowFullScreen="true"
            ></iframe>
          </>
        ) : (
          <LoginComponent />
        )}
      </div>
    </div>
  );
};

export default InvestorLeaderboard