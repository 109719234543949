import React from 'react'
import microsoft from "../../assets/microsoft-startups-india.png"
import { easeInOut, motion, useAnimation, } from "framer-motion"
import startups from "../../assets/startups.png"
import factacyIcon from "../../assets/Icons/Insights.svg"
import nasscom from "../../assets/nasscom_deeptech.jpg"

const Footer = () => {

  const currentYear = new Date().getFullYear();

  return (
    <footer className=" lg:px-8 sm:px-4 pb-6 pt-24" style={{ background: "linear-gradient(180deg, #E8F5FF 0%, #BAE0FF 100%)" }}>
      <div className="flex flex-col lg:gap-9 sm:gap-8 h-fit w-full lg:px-16 sm:px-6 py-6 bg-white xl:container" style={{ borderRadius: "20px" }}>
        <div className="flex lg:flex-row sm:flex-col  justify-between">
          <div>
            <p className="font-bold lg:text-4xl sm:text-3xl tracking-tight font-roboto top-4 relative z-20" >See how Factacy can <br /> support your goals?</p>
            <motion.svg className="relative top-2 z-10 lg:w-[322px] sm:w-64" height="10" viewBox="0 0 422 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <motion.path initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{
                  duration: 1,
                  ease: "easeInOut",
                  repeat: Infinity,
                  repeatType: "loop",
                  repeatDelay: 2
                }} d="M3.00374 6.88428C74.2189 2.34184 241.63 2.36895 418.182 6.58028" stroke="#3E91EE" strokeWidth="6" strokeLinecap="round" />
            </motion.svg>

          </div>

          <div className="flex flex-row justify-between lg:mt-8 sm:mt-5 gap-4">
            <button className="bg-buttonCTA py-4 lg:px-8 sm:px-5 rounded-full text-base leading-6 font-normal text-white w-fit h-14">
              Sign Up Now
            </button>
            <button className=" py-4 lg:px-8 sm:px-5 rounded-full text-base leading-5 font-normal border-2 border-black h-14 w-fit hover:bg-black hover:text-white" >
              Chat With Us
            </button>
          </div>
        </div>


        <hr style={{ border: "1px solid #D4D4D8" }} />


        <div className="flex lg:flex-row sm:flex-col justify-between sm:gap-8">
          <div className="flex lg:flex-col sm:flex-row justify-between">
            <div className="flex flex-col gap-8">
              <div className=' w-auto h-auto m-0 py-0'>
                <img src={factacyIcon} className='w-96' />
             
              </div>
              <div>
                <p className="font-normal font-roboto lg:text-base sm:text-sm relative bottom-6" style={{ color: "#52525B" }}>
                AI powered Financial Intelligence Platform for Unlisted Companies.
                </p>
              </div>
              <div className='flex flex-col gap-4'>
                <div className="flex gap-4 flex-wrap relative ">
                  <img src={microsoft} className="w-32 h-14 grayscale" alt="partners, clients and contributors at Factacy - Microsoft" ></img>
                  <img src={startups} className="w-36 h-14 grayscale" alt="partners, clients and contributors at Factacy - StartupsIndia"></img>
                  <img src={nasscom} className="w-36 h-14 grayscale" alt="partners, clients and contributors at Factacy - StartupsIndia"></img>

                </div>
                <p className="text-zinc-500 font-semibold lg:relative top-20">Location: <br /> 21B, Udyog Vihar, Sector 18, Gurugram <br /> Haryana, India 122008</p>
              </div>

            </div>

          </div>

          <div className="flex flex-col lg:gap-12 sm:gap-6 ">
            <div className="flex lg:flex-row sm:flex-col lg:gap-28 sm:gap-6 justify-between">
              <div className="flex flex-col lg:gap-y-3 sm:gap-y-2 sm:text-sm">
                <p>Products</p>
                <a className="text-zinc-500 hover:text-sky-600" href="">VIP</a>
                <a className="text-zinc-500 hover:text-sky-600" href="">Aicite</a>
              </div>
              <div className="flex flex-col lg:gap-y-3 sm:gap-y-2 relative sm:text-sm lg:right-8">
                <p>Resources</p>
                <a className="text-zinc-500 hover:text-sky-600" href="">About Us</a>
                <a className="text-zinc-500 hover:text-sky-600" href="">Blog</a>
                <a className="text-zinc-500 hover:text-sky-600" href="">Contact Us</a>
              </div>
            </div>


            <div className="flex lg:flex-row sm:flex-col lg:gap-28 sm:text-sm sm:gap-6">
              <div className="flex flex-col lg:gap-y-3 sm:gap-y-2">
                <p>Solutions</p>
                <a className="text-zinc-500 hover:text-sky-600" href="/industry">By Industry</a>
                <a className=" text-zinc-500 hover:text-sky-600" href="">By Technology</a>
              </div>
              <div className="flex flex-col gap-3">
                <p>Contact</p>
                <div className="flex flex-row gap-2 cursor-pointer">
                  <a href="https://www.linkedin.com/company/factacy/">
                    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">

                      <circle cx="15" cy="15.0586" r="15" fill="#3E91EE" />
                      <g clipPath="url(#clip0_311_3008)">
                        <path d="M12.3881 10.3626C12.3879 10.6841 12.2601 10.9923 12.0327 11.2196C11.8052 11.4468 11.4969 11.5743 11.1754 11.5741C10.8539 11.574 10.5457 11.4461 10.3185 11.2187C10.0913 10.9913 9.96371 10.6829 9.96387 10.3614C9.96403 10.0399 10.0919 9.7317 10.3193 9.5045C10.5467 9.27729 10.8551 9.14974 11.1766 9.1499C11.4981 9.15006 11.8063 9.27792 12.0335 9.50535C12.2607 9.73278 12.3883 10.0412 12.3881 10.3626ZM12.4245 12.4717H10.0002V20.0596H12.4245V12.4717ZM16.2548 12.4717H13.8427V20.0596H16.2305V16.0778C16.2305 13.8596 19.1214 13.6535 19.1214 16.0778V20.0596H21.5154V15.2535C21.5154 11.5141 17.2366 11.6535 16.2305 13.4899L16.2548 12.4717Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_311_3008">
                          <rect width="14.5455" height="14.5455" fill="white" transform="translate(8.18164 7.33154)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>

                  <a href="https://twitter.com/FactacyAI/">
                    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="15" cy="15.0586" r="15" fill="#3E91EE" />
                      <g clipPath="url(#clip0_311_3013)">
                        <path d="M21.6131 11.668C21.1504 11.8726 20.6597 12.0071 20.1573 12.0668C20.6869 11.7501 21.0832 11.2516 21.2725 10.6643C20.7755 10.9601 20.2307 11.1674 19.6628 11.2795C19.2813 10.8714 18.7757 10.6007 18.2246 10.5095C17.6734 10.4184 17.1076 10.5119 16.6151 10.7755C16.1225 11.0391 15.7309 11.4581 15.501 11.9672C15.2712 12.4764 15.216 13.0472 15.344 13.591C14.3362 13.5405 13.3503 13.2786 12.4502 12.8223C11.5502 12.366 10.7562 11.7256 10.1198 10.9425C9.89449 11.3295 9.7761 11.7693 9.77674 12.2171C9.77674 13.0959 10.224 13.8722 10.904 14.3268C10.5016 14.3141 10.108 14.2054 9.75613 14.0098V14.0413C9.75625 14.6266 9.95878 15.1938 10.3294 15.6468C10.7 16.0998 11.2158 16.4107 11.7895 16.5268C11.4159 16.628 11.0242 16.6429 10.644 16.5704C10.8057 17.0742 11.121 17.5148 11.5456 17.8305C11.9702 18.1462 12.4829 18.3212 13.0119 18.331C12.4861 18.7439 11.8841 19.0492 11.2403 19.2293C10.5965 19.4094 9.92348 19.4609 9.25977 19.3807C10.4184 20.1258 11.7671 20.5214 13.1446 20.5201C17.807 20.5201 20.3567 16.6577 20.3567 13.308C20.3567 13.1989 20.3537 13.0886 20.3489 12.9807C20.8451 12.622 21.2735 12.1777 21.6137 11.6686L21.6131 11.668Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_311_3013">
                          <rect width="14.5455" height="14.5455" fill="white" transform="translate(8.18164 8.24023)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>

                  <a href="https://www.facebook.com/factacydotAI/">
                    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="15" cy="15.0586" r="15" fill="#3E91EE" />
                      <g clipPath="url(#clip0_311_3018)">
                        <path d="M16.6672 16.4209H18.1824L18.7884 13.9966H16.6672V12.7845C16.6672 12.1603 16.6672 11.5724 17.8794 11.5724H18.7884V9.53602C18.5909 9.50996 17.8448 9.45117 17.0569 9.45117C15.4115 9.45117 14.243 10.4554 14.243 12.2997V13.9966H12.4248V16.4209H14.243V21.5724H16.6672V16.4209Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_311_3018">
                          <rect width="14.5455" height="14.5455" fill="white" transform="translate(8.18164 8.24023)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>

                </div>
              </div>
            </div>

            <div className="flex flex-col leading-5 lg:text-sm sm:text-xs" style={{ color: "#52525B" }}>
              <p>@ {currentYear} Factacy.ai</p>
              <div className="flex flex-row gap-1">
                <a href="">Terms and conditions </a> |
                <a href="/factacy/privacy_policy">Privacy Policy</a> |
                <a href="/factacy/cookie_policy"> Cookie Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer