import React from 'react'

const CTA = ({text}) => {
  return (
    <div>
    <button 
    className="bg-primaryBlue py-4 px-8 rounded-full text-base leading-6 font-normal text-white w-fit h-14">
    {text}
  </button>
  </div>
  )
}

export default CTA




