import React, { useRef, useState } from 'react';
import SearchBar from "../searchbar/index";
import closeLine from "../../assets/close-icon.svg"

const SearchMobileView = ({ setIsPopUpOn, isPopUpOn }) => {
    const [isFocousOn, setIsFocousOn] = useState(false);
   
    return isPopUpOn && (
        <div className="bg-gradient-to-b from-blue to-white lg:hidden fixed top-0 right-0 h-screen w-screen p-4">
            <div className='relative'>
                {!isFocousOn && (<img className=' absolute right-0 cursor-pointer' src={closeLine} alt={"closeIcon"} onClick={() => setIsPopUpOn(false)} />)}
            </div>
            <div
                className={isFocousOn ? "mt-[0rem]" : "mt-[12rem]"}
            >
                <SearchBar setIsFocousOn={setIsFocousOn} />
            </div>
        </div>
    )
}

export default SearchMobileView;