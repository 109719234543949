import React, { useState } from 'react'
import SideBarLayoutCompany from './sideBarLayoutCompany'

import TearSheet from '../tearSheet'
import { Routes, Route } from 'react-router-dom'
import CompanySearch from './companySearch'
import SideBar from "../../components/sideBarMain"
import CompetitorsWatch from './CompetitorsWatch'
import CompnayDash from './compnayDash'
import Errorpage from '../errorpage'
import MetaTagesAdder from '../MetaTagesAdder'


const CompanyIntelligence = () => {


  return (
    <div className="w-full h-screen overflow-y-auto pt-20">
      <Routes>
        <Route className='w-full' element={<TearSheet />} path='/company/*' />
        <Route className='w-full' element={<MetaTagesAdder><CompanySearch /></MetaTagesAdder>} path='/company-search' />
        <Route className='w-full' element={<MetaTagesAdder><CompanySearch /></MetaTagesAdder>} path='/company-watch' />
        <Route className='w-full' element={<MetaTagesAdder><CompetitorsWatch /></MetaTagesAdder>} path='/competitor-watch' />
        <Route className='w-full' element={<MetaTagesAdder><CompnayDash /></MetaTagesAdder>} path='/company-dashboard' />
        <Route path='*' element={<Errorpage />} />
      </Routes>
    </div>
  )
}

export default CompanyIntelligence