import React from 'react';
import AltPortfolio from "../altPortfolio";
import { useSelector } from 'react-redux';
import useAddMetaTagsInvestor from '../../../hooks/MetaTagsAdd/useAddMetaTagsInvestor';

const ALT_Portfolio = ({investorId}) => {

    const sortedData = useSelector(store => store?.investor?.investorDealsData?.[investorId]);
    const investorName = useSelector(store => store?.investor?.investorData?.[investorId]?.[0]?.Investor);

  useAddMetaTagsInvestor(investorName, "Alternatives to portfolio companies", `Alternate investments similar to ${investorName}'s portfolio are ${sortedData?.map(val => val?.Corrected_Investee)}`)

    return (
        <div
            className={`section competitors bg-white sm:w-full rounded-b-xl gap-12 flex flex-col lg:p-8 sm:p-4 `}
            style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
        >
            <p className="font-medium text-[25px] tracking-tight leading-9">
                ALT Portfolios of {investorName}
            </p>
            {sortedData && (<AltPortfolio
                data={sortedData}
                investor={investorName}
            />)}
            
        </div>
    )
}

export default ALT_Portfolio;