import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

Highcharts.setOptions({
  colors: Highcharts.map(
    [
      "#FFB3BA", // pastel red
      "#FFDFBA", // pastel orange
      "#FFFFBA", // pastel yellow
      "#BAFFC9", // pastel green
      "#BAE1FF", // pastel blue
      "#E6BAFF", // pastel purple
      "#FFD1DC", // pastel pink
      "#B3B3FF", // pastel indigo
      "#FFB3E1", // pastel magenta
      "#BFFCC6", // pastel mint
    ],
    function (color) {
      return {
        linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
        stops: [
          [0, Highcharts.color(color).brighten(-0.1).get("rgb")], // darken slightly
          [1, color],
        ],
      };
    }
  ),
});

const InvestorSectorPieChart = ({ sortedData, dealIds }) => {
  const uique = [];
  const feq = {};
  const sectors = dealIds?.map((item) => sortedData[item]?.Sector?.toLowerCase());
  sectors?.forEach((name) => {
    if (uique?.includes(name)) {
      feq[name] = feq[name] + 1;
    } else {
      feq[name] = 1;
      uique?.push(name);
    }
  });

  const list = [];
  uique.forEach((name) => list.push({ name: name, y: feq[name] }));

  // Define the chart options
  const chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      width: 220, // Custom width
      height: 250,
    },
    title: {
      text: "",
      align: "left",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Share",
        data: list,
      },
    ],
    credits: {
      enabled: false, // Disable the credits
    },
  };
  return (
    <div className="">

        <HighchartsReact highcharts={Highcharts} options={chartOptions} />

    </div>
  );
};

export default InvestorSectorPieChart;
