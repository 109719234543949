import React from "react";
import IndividualDealCard from "../../pages/IndividualDealCard";
import { useRef, useState, useEffect } from "react";
import Loader from "../tearSheet/loader";
import { DEALS_OFFSET } from "../../constant/api";



const LatestDeals = () => {
  const [dealsData, setDealsData] = useState([]);

  const [loading, setLoading] = useState(false);
  const triggerElement1 = useRef();


  useEffect(() => {
    const element = triggerElement1.current;
    let status = "";
    const observer = new IntersectionObserver(
      (items, obs) => {
        if (!status && items[0].isIntersecting) {
          setLoading("loading");
          status = "loading";

          (async () => {
            setLoading(true);
            const resDeals = await fetch( DEALS_OFFSET + `?offset=${dealsData.length}&limit=20`);

            if (resDeals.ok) {
              const dealData = await resDeals.json();
              setLoading(false);
              setDealsData((pre) => [...pre, ...dealData]);
            }
          })();
          obs.unobserve(items[0].target);
        }
      },
      {
        rootMargin: "250px",
      }
    );
    observer.observe(element);
  }, [dealsData]);

  return (
    <div className="lg:w-max sm:w-full flex flex-col gap-3 ">
      <div className="h-[100px] flex items-center bg-white rounded-lg">
      <p
        className="p-4 w-full  font-semibold text-lg"
      >
        Recent Deals
      </p>
      </div>

      <div className="flex flex-col px-1 gap-3 h-full overflow-y-auto">
        {dealsData &&
          dealsData
            .filter(
              (deal) => deal.Status === "Confirmed" || deal.Status === "Updated"
            )
            .map((deal, index) => (
              <div>
                <IndividualDealCard deal={deal} index={index} />
              </div>
            ))}

        <div
          className=" w-full flex items-center justify-center"
          ref={triggerElement1}
        >
          {loading && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default LatestDeals;
