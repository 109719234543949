import React, { useState } from "react";
import { useAuth } from "../auth/authContext";
import LoginButton from "../auth/loginButton";
import { industries } from "../../data/industries";
import close from "../../assets/close-line - Copy.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import DealCard from "../../pages/dealCard";
import { HISTORIAL_DEALS } from "../../constant/api";

const HistorialDeals = () => {
  const { user } = useAuth();
  const [input, setInput] = useState("");
  const [SectorInput, setSectorInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [investee, setInvestee] = useState([]);
  const [sector, setSector] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInvesteeChange = (input) => {
    setInput(input);
  };

  const handleSectorChange = (SectorInput) => {
    setSectorInput(SectorInput);
    const filteredResults = industries.filter((industry) =>
      industry.title.toLowerCase().includes(SectorInput.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  const submitInvestee = (e) => {
    e.preventDefault();
    setInvestee(input);
    setInput("");
  };

  const submitSector = (sectorr) => {
    setSector(sectorr);
    setSectorInput("");
  };

  const [deals, setDeals] = useState([]);
  const SearchDeals = async () => {
    try {
      setDeals([]);
      setLoading(true);
      const sdate = startDate != "" ? startDate.getTime() / 1000 : "";
      const edate = endDate != "" ? endDate.getTime() / 1000 : "";
      const response = await axios.get(
        `${HISTORIAL_DEALS}?investee=${investee}&edate=${edate}&sdate=${sdate}&sector=${sector}`
      );
      setDeals(response.data);
      setLoading(false);
    } catch { }
  };

  const removeInvestee = () => {
    setInvestee([])
  }

  const removeSector = () => {
    setSector([])
  }

  return (
    <div className="lg:pt-0 px-4 sm:pt-10 sm:w-full lg:w-full flex flex-col">
      {user.isAuthenticated == true ? (
        <div className="flex flex-col w-full bg-[#F4F5F7] rounded-xl lg:px-5 sm:px-4 lg:py-3 sm:py-3 justify-between gap-5">
          <div className="flex items-center justify-between">
            <p className="font-medium text-[25px] tracking-tight leading-9">
              Historical Deals
            </p>
          </div>

          <div>
            <div className="bg-white h-full w-full p-4 gap-2 flex flex-col [@media(min-width:1300px)]:flex-row justify-between rounded-xl border"
              style={{ boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)" }}
            >
              <div className="flex-1 flex flex-col [@media(min-width:580px)]:flex-row gap-2">
                <div className=" flex-1 gap-2 flex flex-col">
                  <p className="text-lg font-semibold">Investee</p>
                  <form onSubmit={(e) => submitInvestee(e)}>
                    <input
                      className="w-full rounded-lg border-[1px] p-2 border-gray-300"
                      placeholder="Search for a company and press enter"
                      value={input}
                      required
                      onChange={(e) => handleInvesteeChange(e.target.value)}
                    />
                  </form>

                  {investee.length > 0 && (
                    <div className="flex items-center gap-1 bg-gray-200 rounded-xl p-2 justify-between">
                      <p>{investee}</p>
                      <button onClick={() => removeInvestee()}>
                        <img src={close} className="w-4 h-4" />
                      </button>
                    </div>
                  )}
                </div>

                <div className=" flex-1 gap-2 flex flex-col">
                  <p className="text-lg font-semibold">Sector</p>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <input
                      className="w-full rounded-lg border-[1px] p-2 border-gray-300"
                      placeholder="Search for a sector"
                      value={SectorInput}
                      required
                      onChange={(e) => handleSectorChange(e.target.value)}
                    />
                  </form>
                  {sector.length > 0 && (
                    <div className="flex items-center gap-1 bg-gray-200 rounded-xl p-2 justify-between">
                      <p>{sector}</p>
                      <button onClick={() => removeSector()}>
                        <img src={close} className="w-4 h-4" />
                      </button>
                    </div>
                  )}

                  {handleSectorChange && SectorInput.length > 2 && (
                    <div className="bg-white w-full gap-2 h-full rounded-xl relative z-0 ">
                      <ul>
                        {filteredData.map((industry) => (
                          <li
                            className="cursor-pointer p-2 hover:bg-slate-50 flex gap-1"
                            onClick={() => submitSector(industry.title)}
                            key={industry.id}
                          >
                            {industry.title}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <div className=" flex-1 flex flex-col [@media(min-width:580px)]:flex-row gap-2">
                <div className="flex-1">
                  <p className="text-lg font-semibold">Start Date</p>
                  <DatePicker
                    showIcon
                    toggleCalendarOnIconClick
                    // placeholderText="Select a start date"
                    closeOnScroll={true}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>

                <div className="flex-1">
                  <p className="text-lg font-semibold">End Date</p>
                  <DatePicker
                    showIcon
                    toggleCalendarOnIconClick
                    // placeholderText="Select an end date"
                    closeOnScroll={true}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
              </div>

              <button
                className="py-2 px-3 min-w-fit self-center cursor-pointer text-white font-semibold bg-primaryBlue rounded-2xl"
                onClick={() => SearchDeals()}
              >
                Search Deals
              </button>

            </div>
          </div>

          <div>
            <DealCard sortedData={deals} loading={loading} />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center flex-col gap-3 h-screen">
          Please login first <LoginButton className="w-5" />
        </div>
      )}
    </div>
  );
};

export default HistorialDeals;
