import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useFetchInvestorActions from "../../hooks/useFetchInvestorActions";
import Loader from "./loader";
import useFetchCompany from "../../hooks/Tearsheet/useFetchCompany";
import useStoreNews from "../../hooks/Tearsheet/useStoreNews";
import { sector_classification_formatter } from "../../constant/function";
import { Sector_Pill } from "../../constant/Tiny_Components";
import RequestAiProfileButton from "./requestAiProfileButton";
import InvestorComponent from "../investorIntelligence/InvestorComponent";
import TearsheetComponent from "./tearsheetComponent";

const TearSheet = () => {
  const param = useParams();
  const oginput = param["*"];
  const input = oginput?.split("/")[1];
  const binput = oginput?.split("/")[3];
  const [investorId, setInvestorId] = useState("");
  const { getCompany } = useFetchCompany(input);
  const companyState = useSelector((state) => state.company);
  const investorState = useSelector((state) => state.investor);
  const investorDealsData = investorState.investorDealsData[investorId];
  const { getInvestor } = useFetchInvestorActions(
    investorId,
    investorDealsData
  );
  const [activeStatement, setActiveStatement] = useState("companyProfile");
  const companyData = useSelector((state) => state.company).companyData[input];
  const brandData = useSelector((state) => state.company).brandData[binput];
  const dataToMap = companyData?.Mca_Data
    ? { ...companyData?.Mca_Data, ...brandData }
    : companyData?.Lei_Data
    ? { ...companyData?.Lei_Data, ...brandData }
    : { ...brandData };

  useEffect(() => {
    getCompany(input, binput);
  }, [input]);
  useStoreNews(input);

  const handleInvestorClick = async (id) => {
    setInvestorId(id);
    getInvestor(id);
  };

  const showData = () => {
    if (dataToMap.Company_Name || dataToMap.Brand) {
      return (
        <div className="px-4 sm:w-screen lg:w-full">
          {[dataToMap].map((company, index) => (
            <div className="flex flex-col w-full gap-4" key={index}>
              <div
                className="flex w-full items-center rounded-xl bg-white lg:px-7 sm:px-4 lg:py-6 sm:py-3 justify-between"
                style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
              >
                <div className="flex flex-col">
                  {/* company logo and name  */}
                  <div className="flex items-center lg:gap-6 sm:gap-3">
                    {company?.Logo && (
                      <img
                        alt="logo"
                        className="lg:w-[90px] lg:h-[60px] sm:w-[72px] sm:h-[72px]"
                        src={company?.Logo}
                      />
                    )}
                    <div className="flex items-center gap-3">
                      <p className="font-roboto lg:text-3xl sm:text-sm font-bold leading-9 uppercase">
                        {company?.Brand ?? company?.Company_Name}
                      </p>
                    </div>
                  </div>
                  {/* empty space and sector  */}
                  <div className="lg:flex gap-6 items-center">
                    {company?.Logo && (
                      <div className="w-[90px] h-3 hidden lg:block"></div>
                    )}
                    <div className="font-normal lg:text-sm sm:text-xs text-neutral-700 font-roboto h-max flex flex-wrap gap-3 py-2">
                      {(() =>
                        sector_classification_formatter(
                          company?.Factacy_Industrial_Classification
                        )?.map((item, index) => (
                          <Sector_Pill key={index} sector={item} />
                        )))()}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-between items-end gap-3">
                  {/* ai profile button  */}

                  <RequestAiProfileButton
                    input={input}
                    company={company}
                    cin={companyData?.Cin ?? companyData?.Lei}
                  />

                  <div className="flex items-center gap-5">
                    {/* social links  */}
                    <div className="flex gap-2 flex-col lg:flex-row">
                      <a
                        className="cursor-pointer"
                        target="_blank"
                        rel="noreferrer"
                        href={company?.Website}
                      >
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22.9058C6.47715 22.9058 2 18.4286 2 12.9058C2 7.38291 6.47715 2.90576 12 2.90576C17.5228 2.90576 22 7.38291 22 12.9058C22 18.4286 17.5228 22.9058 12 22.9058ZM9.71002 20.5732C8.74743 18.5317 8.15732 16.28 8.02731 13.9058H4.06189C4.458 17.0823 6.71639 19.6805 9.71002 20.5732ZM10.0307 13.9058C10.1811 16.3446 10.8778 18.6355 12 20.6578C13.1222 18.6355 13.8189 16.3446 13.9693 13.9058H10.0307ZM19.9381 13.9058H15.9727C15.8427 16.28 15.2526 18.5317 14.29 20.5732C17.2836 19.6805 19.542 17.0823 19.9381 13.9058ZM4.06189 11.9058H8.02731C8.15732 9.53153 8.74743 7.27983 9.71002 5.23832C6.71639 6.13109 4.458 8.72926 4.06189 11.9058ZM10.0307 11.9058H13.9693C13.8189 9.46698 13.1222 7.17601 12 5.15375C10.8778 7.17601 10.1811 9.46698 10.0307 11.9058ZM14.29 5.23832C15.2526 7.27983 15.8427 9.53153 15.9727 11.9058H19.9381C19.542 8.72926 17.2836 6.13109 14.29 5.23832Z"
                            fill="black"
                          />
                        </svg>
                      </a>

                      <a
                        className="cursor-pointer"
                        target="_blank"
                        rel="noreferrer"
                        href={company?.Facebook}
                      >
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 2.90576C6.47715 2.90576 2 7.38291 2 12.9058C2 17.8971 5.65685 22.0341 10.4375 22.7843V15.7964H7.89843V12.9058H10.4375V10.7026C10.4375 8.19639 11.9304 6.81201 14.2146 6.81201C15.3087 6.81201 16.4531 7.00732 16.4531 7.00732V9.46826H15.1921C13.9499 9.46826 13.5625 10.2391 13.5625 11.03V12.9058H16.3359L15.8926 15.7964H13.5625V22.7843C18.3431 22.0341 22 17.8971 22 12.9058C22 7.38291 17.5228 2.90576 12 2.90576Z"
                            fill="#0076F9"
                          />
                        </svg>
                      </a>

                      <a
                        className="cursor-pointer"
                        target="_blank"
                        rel="noreferrer"
                        href={company?.Twitter}
                      >
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22.192 6.56181C21.4286 6.89951 20.619 7.12131 19.7901 7.21987C20.6634 6.69708 21.3169 5.87466 21.6288 4.90581C20.8082 5.39337 19.91 5.73653 18.9733 5.92037C18.1826 5.07682 17.0775 4.59879 15.9213 4.6001C13.6121 4.6001 11.7392 6.47237 11.7392 8.78259C11.7392 9.11034 11.7768 9.42818 11.8471 9.73485C8.36997 9.5598 5.28957 7.89581 3.22628 5.36517C2.85479 6.00343 2.65955 6.72894 2.66054 7.46743C2.66054 8.91835 3.3991 10.199 4.52099 10.9488C3.85687 10.9278 3.20738 10.7484 2.62668 10.4255C2.62604 10.4431 2.62604 10.4606 2.62604 10.4772C2.62604 12.5042 4.06769 14.195 5.98149 14.5789C5.6223 14.6761 5.25182 14.7252 4.87972 14.7249C4.60947 14.7249 4.34721 14.7 4.09229 14.6511C4.62481 16.3123 6.16836 17.5217 7.9991 17.5549C6.51763 18.7176 4.68819 19.3484 2.80493 19.3457C2.47162 19.346 2.13859 19.3263 1.80762 19.2869C3.71954 20.516 5.94502 21.1683 8.21792 21.1659C15.9111 21.1659 20.1175 14.7933 20.1175 9.26687C20.1175 9.08606 20.1131 8.90462 20.1051 8.72573C20.9238 8.13421 21.6305 7.40143 22.192 6.56181Z"
                            fill="#0091E9"
                          />
                        </svg>
                      </a>

                      <a
                        className="cursor-pointer"
                        target="_blank"
                        rel="noreferrer"
                        href={company?.Linkedin}
                      >
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.3352 19.2448H15.6697V15.068C15.6697 14.072 15.6495 12.7903 14.2807 12.7903C12.891 12.7903 12.6787 13.8741 12.6787 14.9945V19.2448H10.0132V10.6558H12.5737V11.8265H12.6082C12.966 11.1515 13.836 10.439 15.1357 10.439C17.8365 10.439 18.336 12.2166 18.336 14.5303L18.3352 19.2448ZM7.00275 9.48051C6.14475 9.48051 5.4555 8.78601 5.4555 7.93176C5.4555 7.07826 6.1455 6.38451 7.00275 6.38451C7.85775 6.38451 8.55075 7.07826 8.55075 7.93176C8.55075 8.78601 7.857 9.48051 7.00275 9.48051ZM8.33925 19.2448H5.66625V10.6558H8.33925V19.2448ZM19.6687 3.90576H4.32825C3.594 3.90576 3 4.48626 3 5.20251V20.6091C3 21.326 3.594 21.9058 4.32825 21.9058H19.6665C20.4 21.9058 21 21.326 21 20.6091V5.20251C21 4.48626 20.4 3.90576 19.6665 3.90576H19.6687Z"
                            fill="#005AB9"
                          />
                        </svg>
                      </a>
                    </div>

                    {/* TOGGLE TOGGLE */}

                    {company?.Investor_Id ? (
                      <div
                        className="bg-[#ffffff] rounded-full flex p-2 w-max mt-0"
                        style={{
                          boxShadow:
                            "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
                        }}
                      >
                        <button
                          className={`py-1 px-2 sm:text-[10px] lg:text-sm ${
                            activeStatement === "companyProfile"
                              ? "active text-white font-semibold rounded-full bg-primaryBlue"
                              : ""
                          }`}
                        >
                          <p
                            onClick={() => {
                              setActiveStatement("companyProfile");
                              // handleSectionClick("Summary", "Summary");
                            }}
                          >
                            Company Profile
                          </p>
                        </button>
                        <button
                          className={`py-1 px-2 sm:text-[10px] lg:text-sm ${
                            activeStatement === "investorProfile"
                              ? "active text-white font-semibold rounded-full bg-primaryBlue"
                              : ""
                          }`}
                        >
                          <p
                            onClick={() => {
                              setActiveStatement("investorProfile");
                              // handleSectionClick("Portfolio", "Portfolio");
                              handleInvestorClick(company?.Investor_Id);
                            }}
                          >
                            Investor Profile
                          </p>
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {activeStatement === "companyProfile" && (
                <TearsheetComponent
                  input={input}
                  dataToMap={dataToMap}
                  company={company}
                  binput={binput}
                  companyData={companyData}
                />
              )}

              {/* details2 */}
              {activeStatement === "investorProfile" && (
                <InvestorComponent investorId={investorId} />
              )}
            </div>
          ))}
        </div>
      );
    }

    if (companyState.loading) {
      return (
        <div className="flex flex-col items-center justify-center h-[90vh]">
          <Loader />
        </div>
      );
    }

    if (companyState.errorMsg) {
      return (
        <div className="min-h-full bg-white my-4 ml-4 rounded-tl-lg shadow-xl w-full flex items-center justify-center flex-col text-start">
          <p className="text-[35px] font-bold text-primaryBlue">
            Oops! The page did not load.
          </p>
          <p>We are looking at it. We appreciate your patience.</p>{" "}
          <p>
            {" "}
            If the issue persists, let us know at{" "}
            <a
              className="underline font-semibold"
              href={`mailto:connect@factacy.ai`}
            >
              connect@factacy.ai
            </a>
          </p>
        </div>
      );
    }
    return "";
  };
  return <>{showData()}</>;
};
export default TearSheet;

// {company?.Badges && (
//   <div className="flex gap-0 items-center">
//     {company?.Badges.includes("Unicorn") && (
//       <div className="flex items-center">
//         <img alt="logo"
//           src={unicorn}
//           className="w-7 h-6 lg:w-9 lg:h-8 cursor-pointer drop-shadow-lg"
//           title="Unicorn"
//         />
//       </div>
//     )}
//     {company?.Badges.includes("Inventor") && (
//       <div className="flex items-center">
//         <img alt="logo"
//           src={inventor}
//           className="w-7 h-6 lg:w-9 lg:h-8 cursor-pointer drop-shadow-lg"
//           title="Inventor"
//         />
//       </div>
//     )}
//     {company?.Badges.includes("Investor") && (
//       <div className="flex items-center">
//         <img alt="logo"
//           src={investor}
//           className="w-7 h-6 lg:w-9 lg:h-8 cursor-pointer drop-shadow-lg"
//           title="Investor"
//         />
//       </div>
//     )}
//     {company?.Badges.includes("Funded") && (
//       <div className="flex items-center">
//         <img alt="logo"
//           src={funded}
//           className="w-7 h-6 lg:w-9 lg:h-8 cursor-pointer drop-shadow-lg"
//           title="Funded Company"
//         />
//       </div>
//     )}
//   </div>
// )}
