import React, { useState } from 'react';

const useFetch = (url) => {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(undefined);

    const ft = async () => {
        try {
            setLoading(true);
            const res = await fetch(url);
            if (res.ok) {
                const data = await res.json();
                setData(data);
                setLoading(false);
            }
            else{
                throw new Error("Somtning went wrong at backend");
            }
        }
        catch(err){
            setLoading(false);
            setError(err);
        }
        return "";
    }

    return [loading, error, data, ft];
}

export default useFetch;