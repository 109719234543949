import React, { useState } from "react";
import factacy from "../../assets/factacy_logo.svg";
import arrow from "../../assets/arrow-down-double-line.png";
import axios from "axios";
import Loader from "../tearSheet/loader";
import { handleSectorClick } from "../../constant/function";
import Competitor from "../tearSheet/competitor";
import { goToCompanyPage, getCurrentMonth } from "../../constant/function"
import { COMPANY_SEARCH_BY_CIN, DEAL90, DEALS_BY_ART_IDS } from "../../constant/api";

const AltPortfolio = ({ data, investor }) => {
  const [deals, setDeals] = useState([]);
  const [clickedDealId, setClickedDealId] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [competitors, setcompetitors] = useState([]);

  const [isSourceOpen, setSourceOpen] = useState(false);
  const handleSourceClick = (dealId) => {
    setSourceOpen((prevState) => ({
      ...prevState,
      [dealId]: !prevState[dealId],
    }));
  };


  const handleAltClick = async (alt, cin) => {
    setDeals([]);
    setcompetitors([]);
    setLoading(true);
    const dealIds = alt && Array.isArray(alt) && alt.map((id) => id[0]);
    const formattedIds = dealIds && dealIds?.slice(0, 50)?.join(",");
    try {
      const responses = await axios.get(
        `${DEALS_BY_ART_IDS + formattedIds}`
      );
      setDeals(responses.data);
      setLoading(false);
    } catch { }
    setLoading(false);
  };




  return (
    <div className="flex flex-col gap-4 w-full">
      {data?.filter((deal) => deal.Status === "Confirmed" || deal.Status === "Updated")
        .map((item, index) => (
          <div key={index}>
            {item.ALT_Investee && (
              <div
                className="bg-white w-full p-4 shadow-lg flex flex-col h-max gap-5"
              >
                <div className={`w-full flex  lg:flex-row sm:flex-col lg:gap-0 sm:gap-6 justify-between ${clickedDealId === item.id ? "bg-gray-100 p-2" : ""}`}>
                  <div className="flex flex-col lg:w-2/6 sm:w-full">
                    <p className="text-xs">Portfolio Company</p>
                    <div className="flex items-center gap-3">
                      {item.Logo_Url ? (
                        <img
                          src={item.Logo_Url}
                          className="w-12 h-10 object-contain py-2"
                        />
                      ) : (
                        <img src={factacy} className="w-12 h-12" />
                      )}

                      <p className="font-bold text-xl">
                        {" "}
                        {item.Corrected_Investee}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col lg:w-2/4 sm:w-full">
                    <p className="text-xs">Sector</p>
                    <p>
                      {item.Sector_Classification ? (
                        item.Sector_Classification.map((ar1, indexo) => (
                          <span
                            className={`cursor-pointer hover:underline flex flex-col hover:text-primaryBlue font-bold text-sm`}
                            key={indexo}
                          // onClick={() => handleSectorClick(ar1[0])}
                          >
                            {indexo < 2 && ar1 != null && ar1[1]}
                          </span>
                        ))
                      ) : (
                        <span className="font-bold sm:text-base lg:text-base">
                          {item.Sector ? item.Sector.toUpperCase() : "-"}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="flex flex-col lg:w-1/4 sm:w-full">
                    <p className="text-xs">Round</p>
                    <p className="font-bold">
                      {" "}
                      {item.Corrected_Series.toUpperCase()}
                    </p>
                  </div>
                  <div className="flex flex-col lg:w-1/4 sm:w-full">
                    <p className="text-xs">Published Date</p>
                    <p className="font-bold">
                      {item.Unique_date_time &&
                        getCurrentMonth(
                          item.Unique_date_time[item.Unique_date_time.length - 1]
                        )
                        ? `${getCurrentMonth(
                          item.Unique_date_time[
                          item.Unique_date_time.length - 1
                          ]
                        ).month
                        } ${getCurrentMonth(
                          item.Unique_date_time[
                          item.Unique_date_time.length - 1
                          ]
                        ).year
                        }`
                        : "Date Not Available"}
                    </p>
                  </div>

                  <div
                    className="flex lg:w-1/4 sm:w-full items-center justify-center gapy-1 px-2 hover:text-primaryBlue hover:underline cursor-pointer"
                    onClick={() => {
                      if (clickedDealId !== item.id) {
                        {
                          handleAltClick(item.ALT_Investee, item.Output_CIN);
                          setClickedDealId(item.id);
                          handleSourceClick(item.id);

                          // fetchCompetitors(item.Output_CIN)
                        }
                      } else {
                        setClickedDealId(null);
                      }
                    }}
                  >
                    <p className="text-base font-bold"> Alternate Companies</p>
                    <img src={arrow} className="w-4 h-4" />
                  </div>
                </div>

                {clickedDealId === item.id && (
                  <>
                    <p className="font-medium text-[25px] tracking-tight leading-9">
                      Alternate Funded Companies
                    </p>
                    <div className="w-full rounded-lg border border-slate-400 ">
                      <table className="table-auto w-full">
                        <thead>
                          <tr className="text-left">
                            <th className="border-b p-2 bg-gray-100 rounded-tl-lg">
                              Alternate Companies
                            </th>
                            <th className="border-b p-2 bg-gray-100">Sector</th>
                            <th className="border-b p-2 bg-gray-100">Round</th>
                            <th className="border-b p-2 bg-gray-100 rounded-tr-lg">Published Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {deals.map((deal, ind) => (
                            <tr key={ind}>
                              <td className="flex gap-2 py-1 px-2">
                                {deal.Logo_Url ? (
                                  <img
                                    src={deal.Logo_Url}
                                    className="w-8 h-8 object-contain"
                                  />
                                ) : (
                                  <img src={factacy} className="w-8 h-8" />
                                )}

                                <p
                                  className="text-sm hover:underline hover:text-primaryBlue cursor-pointer"

                                  onClick={() => goToCompanyPage(deal.Reference_Id, deal.Legal_Name)}


                                >
                                  {deal.Corrected_Investee}
                                </p>
                              </td>

                              <td className="py-1 px-2">
                                {deal.Sector_Classification ? (
                                  deal.Sector_Classification.map(
                                    (ar1, indexo) => (
                                      <span
                                        className={`cursor-pointer hover:underline hover:text-primaryBlue text-sm`}
                                        key={indexo}
                                        onClick={() =>
                                          handleSectorClick(ar1[0])
                                        }
                                      >
                                        {indexo < 2 &&
                                          ar1 != null &&
                                          ar1[1] + ", "}
                                      </span>
                                    )
                                  )
                                ) : (
                                  <p className="sm:text-base lg:text-sm">
                                    {deal.Sector
                                      ? deal.Sector.toUpperCase()
                                      : "-"}
                                  </p>
                                )}
                              </td>

                              <td className="py-1 px-2">
                                {deal && deal.Corrected_Series && deal.Corrected_Series.toUpperCase()}
                              </td>

                              <td className="py-1 px-2">
                                {" "}
                                {deal.Unique_date_time &&
                                  getCurrentMonth(
                                    deal.Unique_date_time[
                                    deal.Unique_date_time.length - 1
                                    ]
                                  )
                                  ? `${getCurrentMonth(
                                    deal.Unique_date_time[
                                    deal.Unique_date_time.length - 1
                                    ]
                                  ).month
                                  } ${getCurrentMonth(
                                    deal.Unique_date_time[
                                    deal.Unique_date_time.length - 1
                                    ]
                                  ).year
                                  }`
                                  : "Date Not Available"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <p className="font-medium text-[25px] tracking-tight leading-9">
                        Other Competitors
                      </p>
                      {item?.Brand_Id && (<Competitor brandId={item.Brand_Id} />)}
                      {/* {competitors && competitors.length > 0 && (
                        <Competitor company={competitors} />
                      )} */}
                    </div>
                    {isLoading && <Loader />}
                  </>
                )}
              </div>
            )}
          </div>
        ))}

      {showErrorMessage && (
        <div className="absolute bottom-0 right-0 lg:m-10 sm:m-4 lg:p-3 sm:p-2 lg:text-base sm:text-sm border-blue border-2 bg-slate-100 rounded-lg font-semibold font-roboto">
          We are expanding our coverage daily. Please check back again later.
        </div>
      )}
    </div>
  );
};

export default AltPortfolio;

