import { createSlice } from "@reduxjs/toolkit";

const persionReducer = createSlice({
    name: "persionReducer",
    initialState: {
        loading: false,
        data: {},
        errorMsg: "",
        sector: '',
        sectorData: [],
        sectorDealsData: [],
    },
    reducers: {
        SECTOR_SEARCH_LOADING: (state, action) => { return { ...state, loading: true, errorMsg: "" }; },
        SECTOR_SEARCH_FAIL: (state, action) => { return { ...state, loading: false, errorMsg: "Unable to find sector" }; },
        SECTOR_DATA_SUCCESS: (state, action) => {
            const { data, sectorName } = action.payload;
            return { ...state, loading: false, errorMsg: "", sectorData: { ...state.sectorData, [sectorName]: data, } };
        },
    }
});

export const { SECTOR_SEARCH_LOADING, SECTOR_SEARCH_FAIL, SECTOR_DATA_SUCCESS } = persionReducer.actions;
export default persionReducer.reducer;