import React from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  COMPANY_SEARCH_LOADING,
  COMPANY_SEARCH_FAIL,
  COMPANY_SEARCH_SUCCESS,
  FINANCIALS_DATA_SUCCESS,
  // PATENTS_SEARCH_LOADING,
  // PATENTS_SEARCH_FAIL,
  PATENTS_DATA_SUCCESS,
  INPUT_UPDATION,
  INVESTOR_SEARCH_SUCCESS
} from "../store/slices/companyReducer";
import { PATENT_SEARCH_BY_CIN } from "../constant/api";

const useFetchCompanyActions = () => {
  const dispatch = useDispatch();

  const getCompany = async (input) => {
    const options1 = {
      url: `https://factacyinsights.in`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/txt;charset=UTF-8",
      },
      params: { input },
      data: {},
    };

    const options2 = {
      url: `https://factacyinsights.in/financials`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/txt;charset=UTF-8",
      },
      params: { input },
      data: {},
    };

    try {
      dispatch(COMPANY_SEARCH_LOADING());

      const [res, resFinancials] = await Promise.all([
        axios(options1),
        axios(options2),
      ]);
      dispatch(COMPANY_SEARCH_SUCCESS({ data: res.data, companyName: input }));
      dispatch(
        FINANCIALS_DATA_SUCCESS({
          data: resFinancials.data,
          companyName: input,
        })
      );
    } catch (e) {
      dispatch(COMPANY_SEARCH_FAIL());
    }
  };

  //
  const getPatentsData = async (input) => {
    const options3 = {
      
      url: PATENT_SEARCH_BY_CIN + input,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/txt;charset=UTF-8",
      },
      // params: { input },
      data: {},
    };
    try {
      // dispatch(PATENTS_SEARCH_LOADING());

      const patentResponse = await axios(options3);
      dispatch(
        PATENTS_DATA_SUCCESS({
          data: patentResponse.data,
          companyName: input,
        })
      );
    } catch (e) {
      // dispatch(PATENTS_SEARCH_FAIL());
    }
  };

  //
//   const UpdateUserInput = (input) => {
//     return () => dispatch(INPUT_UPDATION({ payload: input }));
//   };

  return { getCompany, getPatentsData };
};

export default useFetchCompanyActions;
