import { useEffect, useState } from "react";
import useDebounce from "../useDebounce";
import { SEARCH_API_WITH_REFRENCE_ID } from "../../constant/api";

const useFetchSearchSuggestionsWithRefrenceId = (input, type) => {

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [companySuggestions, setCompanySuggestions] = useState([]);
    const [investorSuggestions, setInvestorSuggestions] = useState([]);
    const [personSuggestions, setPersonSuggestions] = useState([]);
    const [sectorSuggestions, setSectorSuggestions] = useState([]);

    const fetchFunction = async (input) => {

        try {
            setLoading(true);
            const res = await fetch(SEARCH_API_WITH_REFRENCE_ID + input);
            const data = await res.json();

            if (res.ok) {
                setSuggestions(data);
                setCompanySuggestions(data?.filter((item) => item?.PROPERTY?.includes('Company')));
                setInvestorSuggestions(data?.filter((item) => item?.PROPERTY?.includes('Investor')));
                setPersonSuggestions(data?.filter((item) => item?.PROPERTY?.includes('Person')));
                setSectorSuggestions(data?.filter((item) => item?.PROPERTY?.includes('Sector')));
                setLoading(false);
            }
        }
        catch (error) {
            setSuggestions([]);
            setLoading(false);
        }
    }
    const optimizedFunction = useDebounce(fetchFunction, 300);

    useEffect(() => {
        if (input) {
            optimizedFunction(input);
        }
    }, [input]);

    return [error, loading, { suggestions, companySuggestions, investorSuggestions, personSuggestions, sectorSuggestions }];
}

export default useFetchSearchSuggestionsWithRefrenceId;