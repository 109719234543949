import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Loader from "./loader";

const EsgScore = ({ input, getEsgData, loading }) => {
  useEffect(() => {
    getEsgData(input);
  }, [input]);

  // useAddMetaTags(company?.Brand ?? company?.Company_Name, "ESG Rankings, Ratings, News", company?.Keywords, company?.ESG_Description)
  const esgData = useSelector((state) => state.company).esgCsrData[input];


  return esgData?.map((data, index) => (
    <div
      className="flex flex-col lg:gap-16 sm:gap-8 bg-white sm:w-full rounded-b-xl lg:p-8 sm:p-4 gap-6"
      key={index}
    >
      {loading ? (
        <div className=" w-full h-full min-h-[22rem] flex items-center justify-center bg-white">
          <Loader />
        </div>
      ) : (
        <>
          <div className="flex lg:flex-row sm:flex-col sm:gap-4 justify-between">
            <div className="flex flex-col gap-6">
              <p className="font-roboto">Compound Score</p>
              <p className="font-bold text-primaryBlue text-5xl">
                {(data?.Esg_Data?.Compound_Score)?.toFixed(3)}
              </p>
            </div>
            <div className="flex flex-col justify-end">
              <p className="text-[#A1A1AA] text-sm">ESG classification</p>
              <p className="font-roboto">
                {data?.Esg_Data?.ESG_Classification[1]}
              </p>
            </div>
          </div>

          <div className="flex lg:flex-row sm:flex-col gap-4">
            <div className="bg-[#E8F5FF] rounded-xl p-6 lg:w-1/3 sm:w-full flex flex-col gap-6">
              <p className="font-roboto">E Score</p>
              <p className="font-medium text-primaryBlue text-4xl">
                {data?.Esg_Data?.E_Score}
              </p>
            </div>
            <div className="bg-[#E8F5FF] rounded-xl p-6 lg:w-1/3 sm:w-full flex flex-col gap-6">
              <p className="font-roboto">S Score</p>
              <p className="font-medium text-primaryBlue text-4xl">
                {data?.Esg_Data?.S_Score}
              </p>
            </div>
            <div className="bg-[#E8F5FF] rounded-xl p-6 lg:w-1/3 sm:w-full flex flex-col gap-6">
              <p className="font-roboto">G Score</p>
              <p className="font-medium text-primaryBlue text-4xl">
                {data?.Esg_Data?.G_Score}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  ));
};

export default EsgScore;
