import { falseValues } from "./constant";

export const howOldTimeUpdater = (time) => {
  if (!time) return "";
  const timeDifference = Math.floor(Date.now() / 1000) - time;
  const minutesAgo = Math.floor(timeDifference / 60);
  const hoursAgo = Math.floor(timeDifference / 3600);
  let timeAgo = "";
  if (timeDifference >= 86400) {
    const publishedDate = new Date(time * 1000).toLocaleString();
    // const dt = new Date(time * 1000);

    const list = publishedDate.split("/");
    let day = list[1];
    list[1] = list[0];
    list[0] = day;

    timeAgo = list.join("/");
  } else if (minutesAgo < 60) {
    timeAgo = `${minutesAgo} minute${minutesAgo === 1 ? "" : "s"} ago`;
  } else {
    timeAgo = `${hoursAgo} hour${hoursAgo === 1 ? "" : "s"} ago`;
  }

  return timeAgo;
};


export const goToCompanyPage = async (
  companyId,
  companyName,
  brandId,
  BrandName
) => {
  if (companyId || brandId) {
  window.open(
    `/company-intelligence/company/${companyName?.[0]?.split(" ")?.join("-") ?? "null"}/${companyId ?? "null"}/${BrandName?.[0]?.split(" ")?.join("-") ?? "null"}/${brandId ?? "null"}?info=summary`
  );
  }
};

export const goToInvestorPage = async (investorName, investorId) => {
  if (!falseValues.includes(investorName) && !falseValues.includes(investorId)) {
    window.open(`/investor-intelligence/investor/${investorName?.split(" ")?.join("-")}/${investorId}`);
  }

};

export const goToSingleNewsPage = (id, headline) => {
  const useable_headline = Array.isArray(headline) ? headline?.[0] : headline;
  const useable_id = Array.isArray(id) ? id?.[0] : id;
  let pattern = /[^a-zA-Z0-9\s]/g;
  window.open(`/news-intelligence/news/${useable_id}/${useable_headline.replace(pattern, ' ')?.split(" ")?.filter(item => item)?.map(item => item?.toLowerCase()).join("-")}`);
};

export const handleSectorClick = async (sector) => {
  if ( !falseValues.includes(sector)) {
    window.location.href = `/sector-intelligence/sector/${sector}`;
  }
};

export const getCurrentMonth = (date) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (!date) {
    return null;
  }
  const milliseconds = date * 1000;
  const currentDate = new Date();
  const lastDate = new Date(milliseconds);
  if (isNaN(lastDate.getTime())) {
    return null; // Return for an invalid date
  }
  if (lastDate > currentDate) {
    return "Future Date"; // Or handle future dates as needed
  } else {
    return {
      month: months[lastDate.getMonth()],
      year: lastDate.getFullYear(),
    };
  }
};

export const sector_classification_formatter = (classification) =>
  Array.isArray(classification)
    ? classification?.every((item) => Array.isArray(item))
      ? classification
      : [classification]
    : [["", classification]];

export const Factacy_Industrial_Classification_formatter = (classification) =>
  Array.isArray(classification)
    ? classification?.every((item) => Array.isArray(item))
      ? classification
      : [classification]
    : [["", classification]];

// {Factacy_Industrial_Classification &&
//   Array.isArray(
//     Factacy_Industrial_Classification
//   ) ? (
//   Factacy_Industrial_Classification.every(
//     Array.isArray
//   ) ? (
//     Factacy_Industrial_Classification.map(
//       (classification, index) => (
//         <span
//           key={index}
//           className="hover:underline hover:text-primaryBlue cursor-pointer"
//         // onClick={() =>
//         //   // handleSectorClick(classification[0])
//         // }
//         >
//           {classification[1]}
//           {index !== Factacy_Industrial_Classification.length - 1 && ", "}
//         </span>
//       )
//     )
//   ) : (
//     <span className="hover:underline hover:text-primaryBlue cursor-pointer">
//       {Factacy_Industrial_Classification[1]}
//     </span>
//   )
// ) : (
//   <span>
//     {Factacy_Industrial_Classification}
//   </span>
// )}

export const Alphanumeric_Date = (epoch) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dt = new Date(epoch);
  return `${dt.getDate()}-${months[dt.getMonth()]}-${dt.getFullYear()}`;
};