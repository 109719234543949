import React, { useState } from "react";
import DealCarouselSlider from "../pages/DealCarouselSlider";
import InvestorSectorPieChart from "../components/charts/InvestorSectorPieChart";
import { getCurrentMonth } from "../constant/function";
import { Sector_Pill } from "../constant/Tiny_Components";

const InvestorCard = ({ data, sortedData, index, dealIds }) => {
  const [showFullContent, setShowFullContent] = useState(false);
  const toggleShowContent = (newsId) => {
    setShowFullContent((prevState) => ({
      ...prevState,
      [newsId]: !prevState[newsId],
    }));
  };

  return (
    <div
      className="bg-white rounded-lg border-[1px] shadow-md px-4 pt-3 pb-4 flex flex-col gap-2 "
      key={index}
    >
      <div className="flex w-full gap-2 items-center">
        <div className="lg:w-2/5 w-full flex lg:flex-row flex-col gap-2 items-start lg:items-center">
          <p className="lg:text-2xl lg:w-fit text-xl font-bold">{data?.Investor}</p>
          <p className="text-sm font-semibold w-full text-primaryBlue">
            ({data?.Investor_Deal_Ids?.length} Deals)
          </p>
        </div>
        <p className="w-3/5 font-bold text-lg px-2 hidden lg:block">Portfolio Companies</p>
      </div>
      <div className="flex flex-col">
        <div className=" flex lg:flex-row flex-col gap-4 w-full">
          <div className="lg:w-2/5 flex flex-col items-center">
            <div className="flex flex-col lg:flex-row items-center">
              <InvestorSectorPieChart
                sortedData={sortedData}
                dealIds={dealIds}
              />

              <div className="flex flex-col gap-4">
                <div className="flex gap-2">
                  <p className="text-xs" title="Most participated rounds">
                    Rounds
                  </p>
                  <div className="flex flex-wrap gap-2">
                    {dealIds &&
                      dealIds?.slice(0, 3)?.map((data, i) => (
                        <span
                          className="text-[10px] font-semibold uppercase py-1 px-3 rounded-xl w-max bg-gray-100 border-[1px] border-gray-200"
                          key={i}
                        >
                          {sortedData[data]?.Corrected_Series}{" "}
                        </span>
                      ))}
                  </div>
                </div>

                <div className="flex gap-2">
                  <p className="text-xs" title="Most participated sectors">
                    Sectors
                  </p>
                  <div className="flex  flex-wrap gap-2 text-[10px] font-semibold">
                    {dealIds &&
                      dealIds?.slice(0, 3)?.map((data, i) => (
                        <React.Fragment key={i}>
                          {sortedData[data]?.Sector_Classification &&
                            sortedData[data]?.Sector_Classification.length >
                              0 &&
                            sortedData[data]?.Sector_Classification.map(
                              (ar1, indexo) => (
                                ar1 &&
                                ar1[1] && ar1 != null && (
                                  <span
                                  key={indexo}
                                    className={`cursor-pointer hover:underline hover:text-primaryBlue text-[10px] font-semibold py-1 px-3 rounded-xl w-max bg-gray-100 border-[1px] border-gray-200`}
                                    onClick={() =>
                                      ar1[0] && window.open(`/sector-intelligence/sector/${ar1[0]}`)
                                    }
                                  >

                                    {ar1 != null && ar1[1]?.toUpperCase()}
                                    <br />
                                  </span>
                                )
                              )
                            )}
                        </React.Fragment>
                      ))}
                  </div>
                </div>

                <div className="flex gap-2">
                  <p className="text-xs">Last Deal</p>
                  {dealIds &&
                    dealIds?.slice(0, 1)?.map((data, i) => (
                      <span
                        key={i}
                        className="text-[10px] font-semibold py-1 px-3 rounded-xl w-max bg-gray-100 border-[1px] border-gray-200 uppercase"
                      >
                        {sortedData[data]?.Unique_date_time &&
                        getCurrentMonth(
                          sortedData[data]?.Unique_date_time[
                            sortedData[data]?.Unique_date_time.length - 1
                          ]
                        )
                          ? `${
                              getCurrentMonth(
                                sortedData[data]?.Unique_date_time[
                                  sortedData[data]?.Unique_date_time.length - 1
                                ]
                              ).month
                            } ${
                              getCurrentMonth(
                                sortedData[data]?.Unique_date_time[
                                  sortedData[data]?.Unique_date_time.length - 1
                                ]
                              ).year
                            }`
                          : "Date Not Available"}
                      </span>
                    ))}
                </div>
              </div>
            </div>

            {data?.Investor_Bio && (
              <div className="lg:text-sm sm:text-xs text-justify sm:mt-4">
                {showFullContent[data.id] ? (
                  <>
                    {data?.Investor_Bio}
                    <p
                      className="text-sm text-primaryBlue inline cursor-pointer"
                      onClick={() => toggleShowContent(data?.id)}
                    >
                      {" "}
                      Read Less{" "}
                    </p>
                  </>
                ) : (
                  <>
                    {data.Investor_Bio?.slice(0, 400)}
                    <p
                      className="text-sm text-primaryBlue inline cursor-pointer "
                      onClick={() => toggleShowContent(data?.id)}
                    >
                      {" "}
                      ...Read more{" "}
                    </p>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="lg:w-3/5">
            {/* {sortedData && sortedsortedData[Data]?.length > 0 && sortedData[index] !== undefined &&  ( */}
            <DealCarouselSlider sortedData={sortedData} dealIds={dealIds} />
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorCard;
