import React, { useEffect, useState } from 'react'
import axios from 'axios';
import SimilarInvestors from "../similarInvestors";
import { useDispatch, useSelector } from 'react-redux';
import { INVESTOR_SIMILAR_INVESTORS } from '../../../store/slices/investorReducer';
import { GET_ALL_DEALS, GET_INVESTORS } from '../../../constant/api';
import useAddMetaTagsInvestor from '../../../hooks/MetaTagsAdd/useAddMetaTagsInvestor';
const Similar_Investors = ({ investorId }) => {

    const [dealIdRecord, setDealIdRecord] = useState([]);
    const [loading, setIsLoading] = useState(false);
    const [invDetails, setInvDetails] = useState([]);
    const [sortedSimilarInvestorData, setSortedSimilarInvestorData] = useState([]);

    const dispatch = useDispatch();

    const investorName = useSelector(store => store?.investor?.investorData?.[investorId]?.[0]?.Investor);
    const AltInvestors = useSelector(store => store?.investor?.investorData?.[investorId]?.[0]?.ALT_Investors);
    const similar = useSelector(store => store?.investor?.similarInvestors?.[investorId]);
    useEffect(() => {

        (async () => {
            if (AltInvestors && !similar ) {
                const states = {
                    invDetails: [],
                    dealIdRecord: "",
                    sortedSimilarInvestorData: ""
                };

                // .filter(Boolean);
                setInvDetails([]);
                setIsLoading(true);
                const formattedInv = AltInvestors?.slice(0, 10).map((item) => item.join(","));
                try {
                    const response = await axios.get(`${GET_INVESTORS + formattedInv}`);

                    setInvDetails(response.data);
                    states.invDetails = response.data;

                    if (response.data) {
                        const dealIds = response.data?.map((item) =>
                            item.Investor_Deal_Ids.slice(0, 10)
                        );
                        setDealIdRecord(dealIds);
                        states.dealIdRecord = dealIds;

                        const formattedIds = dealIds && dealIds.flat(1).join(",");

                        try {
                            const responses = await axios.get(`${GET_ALL_DEALS + formattedIds}`);

                            const sortedData = responses.data?.slice().sort((a, b) => {
                                const lastDateA =
                                    a.Unique_date_time && a.Unique_date_time.length > 0
                                        ? a.Unique_date_time[a.Unique_date_time.length - 1]
                                        : null;
                                const lastDateB =
                                    b.Unique_date_time && b.Unique_date_time.length > 0
                                        ? b.Unique_date_time[b.Unique_date_time.length - 1]
                                        : null;

                                if (lastDateA !== null && lastDateB !== null) {
                                    return lastDateB - lastDateA; // Sort in descending order based on epoch time
                                } else if (lastDateA === null && lastDateB !== null) {
                                    return 1; // Put empty or non-existent dates at the end
                                } else if (lastDateA !== null && lastDateB === null) {
                                    return -1; // Put empty or non-existent dates at the end
                                }
                                return 0;
                            });

                            const store = {};
                            sortedData?.forEach(item => store[item.id] = item);

                            setSortedSimilarInvestorData(store);
                            states.sortedSimilarInvestorData = store;

                            const summaryICArrays = sortedData.map((item) => item?.Sector);
                            if (
                                summaryICArrays &&
                                Array.isArray(summaryICArrays) &&
                                summaryICArrays.length > 0
                            ) {
                                const allSummaryICValues = summaryICArrays.flat();
                                const uniqueSectors = {};
                                allSummaryICValues.forEach((summaryIC) => {
                                    if (!uniqueSectors[summaryIC]) {
                                        uniqueSectors[summaryIC] = 1; // Initialize count for new sector
                                    } else {
                                        uniqueSectors[summaryIC] += 1; // Increment count for existing sector
                                    }
                                });
                                const valueArrayData = Object.keys(uniqueSectors).map((sector) => ({
                                    sector,
                                    count: uniqueSectors[sector],
                                }));

                                // setValueArray1(valueArrayData);
                            } else {
                                // setValueArray1([]);
                            }
                        } catch (err) {
                            console.log(err);
                        }
                    }
                } catch {
                    setInvDetails([]);
                }
                setIsLoading(false);
                dispatch(INVESTOR_SIMILAR_INVESTORS({ investorId, data: { ...states } }));
            }

        })();
    }, [AltInvestors]);

  useAddMetaTagsInvestor(investorName, `Top Investors like ${investorName}`, `Top investors similar to ${investorName}'s are ${similar?.invDetails?.map(inv => inv?.Investor)}`)  

    return (
        <div
            className={`section bg-white sm:w-full rounded-b-xl gap-12 flex flex-col lg:p-8 sm:p-4 `}
            style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
        >
            <p className="font-medium text-[25px] tracking-tight leading-9">
                Similar Investors like {investorName}
            </p>
            <SimilarInvestors
                invDetails={similar?.invDetails}
                sortedSimilarInvestorData={similar?.sortedSimilarInvestorData}
                isLoading={loading}
                dealIdsRecord={similar?.dealIdRecord}
            />

        </div>
    )
}

export default Similar_Investors;