import React from 'react'
import { escapeRegExp } from "lodash";


const PatentCard = ({search, input}) => {
    const highlightKeyword = (text, keyword) => {
        if (!keyword || !text) return text;
    
        const escapedKeyword = escapeRegExp(keyword);
        const regex = new RegExp(`(${escapedKeyword})`, "gi");
        return (
          text &&
          text[0]
            ?.split(regex)
            .map((part, index) =>
              regex.test(part) ? <mark key={index}>{part}</mark> : part
            )
        );
      };
  return (
    <div
    className="cursor-pointer w-full p-4 flex flex-col gap-4 "

  >
    <div className="flex">
      <div className="w-1/2">
        <p className="text-xs text-primaryBlue">
          Title of Invention
        </p>
        <p className="text-sm font-bold">
          {highlightKeyword(
            search.Title_of_Invention,
            input
          )}
        </p>
      </div>
      <div className="w-1/2">
        <p className="text-xs text-primaryBlue">
          Application Number
        </p>
        <p className="text-sm font-semibold">
          {highlightKeyword(
            search.Application_Number,
            input
          )}
        </p>
      </div>
    </div>

    {search.Abstract != "Null" && search.Abstract !== undefined && (
      <div>
        <p className="text-xs text-primaryBlue">
          Abstract
        </p>
        <p className="text-sm font-semibold">
          {highlightKeyword(search.Abstract, input)}
        </p>
      </div>
    )}
    <div className="flex justify-between">
      <div className="w-1/2">
        <p className="text-xs text-primaryBlue">
          Applicant Name
        </p>
        <p className="text-sm font-bold">
          {highlightKeyword(
            search.Applicant_Name,
            input
          )}
        </p>
      </div>

      <div className="w-1/2">
        <p className="text-xs text-primaryBlue">
          Inventor Name
        </p>
        <p className="text-sm font-bold">
          {highlightKeyword(
            search.Inventor_Name,
            input
          )}
        </p>
      </div>
    </div>
  </div>
  )
}

export default PatentCard