import React, { useEffect, useState } from "react";
import IndividualOpenDeal from "../components/dealsIntelligence/IndividualOpenDeal";
import Loader from "../components/tearSheet/loader";
import { useAuth } from "../components/auth/authContext";
import LoginButton from "../components/auth/loginButton";
import { DEAL90 } from "../constant/api";

const OpenDeals = () => {
  const { user } = useAuth();
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await fetch(DEAL90);
        if (res.ok) {
          const data = await res.json();
          setLoading(false);
          setDeals(
            data[0]
              ?.filter((item) => item.Status === "Open Deal")
              .sort((a, b) => {
                const lastDateA =
                  a.Unique_date_time && a.Unique_date_time.length > 0
                    ? a.Unique_date_time[a.Unique_date_time.length - 1]
                    : null;
                const lastDateB =
                  b.Unique_date_time && b.Unique_date_time.length > 0
                    ? b.Unique_date_time[b.Unique_date_time.length - 1]
                    : null;

                if (lastDateA !== null && lastDateB !== null) {
                  return lastDateB - lastDateA; // Sort in descending order based on epoch time
                } else if (lastDateA === null && lastDateB !== null) {
                  return 1; // Put empty or non-existent dates at the end
                } else if (lastDateA !== null && lastDateB === null) {
                  return -1; // Put empty or non-existent dates at the end
                }
                return 0;
              })
          );
          // setDeals(data[0]);
        }
      } catch (err) {
        setLoading(false);
      }
    })();
  }, []);

  return user.isAuthenticated ? (
    loading ? (
      <div className=" w-full h-screen flex items-center justify-center">
        <Loader />
      </div>
    ) : (
      <div className="flex gap-5 px-4 flex-wrap w-full deal ">
        {deals?.map((item, index) => (
          <IndividualOpenDeal key={index} deal={item} />
        ))}
      </div>
    )
  ) : (
    <div className=" w-full h-screen flex items-center justify-center">
      <LoginButton />
    </div>
  );
};

export default OpenDeals;



// import React, { useEffect, useState } from "react";
// import IndividualOpenDeal from "../components/dealsIntelligence/IndividualOpenDeal";
// import Loader from "../components/tearSheet/loader";
// import { useAuth } from "../components/auth/authContext";
// import LoginButton from "../components/auth/loginButton";
// import metaTags from "../constant/metaTags";

// const OpenDeals = () => {
//   const { user } = useAuth();
//   const [deals, setDeals] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [company, setCompany] = useState([]);

//   useEffect(() => {
//     (async () => {
//       try {
//         setLoading(true);
//         if (res.ok) {
//           const data = await res.json();
//           setLoading(false);
//           setDeals(
//             data[0]
//               ?.filter((item) => item.Status === "Open Deal")
//               .sort((a, b) => {
//                 const lastDateA =
//                   a.Unique_date_time && a.Unique_date_time.length > 0
//                     ? a.Unique_date_time[a.Unique_date_time.length - 1]
//                     : null;
//                 const lastDateB =
//                   b.Unique_date_time && b.Unique_date_time.length > 0
//                     ? b.Unique_date_time[b.Unique_date_time.length - 1]
//                     : null;

//                 if (lastDateA !== null && lastDateB !== null) {
//                   return lastDateB - lastDateA; // Sort in descending order based on epoch time
//                 } else if (lastDateA === null && lastDateB !== null) {
//                   return 1; // Put empty or non-existent dates at the end
//                 } else if (lastDateA !== null && lastDateB === null) {
//                   return -1; // Put empty or non-existent dates at the end
//                 }
//                 return 0;
//               })
//           );
//           // setDeals(data[0]);
//         }
//       } catch (err) {
//         setLoading(false);
//       }
//     })();
//   }, []);


//   const [existingMetaTags] = useState({
//     description: document
//       .querySelector('meta[name="description"]')
//       .getAttribute("content"),
//     keywords: document
//       .querySelector('meta[name="keywords"]')
//       .getAttribute("content"),
//     title: document.getElementsByTagName("title")[0].innerText,
//   });

//   if (metaTags[window.location.pathname]) {
//     document
//       .querySelector('meta[name="description"]')
//       .setAttribute("content", metaTags[window.location.pathname].description);
//     document
//       .querySelector('meta[name="keywords"]')
//       .setAttribute("content", metaTags[window.location.pathname].keywords);
//     document.getElementsByTagName("title")[0].innerText = metaTags[window.location.pathname].title;
//   }


//   useEffect(
//     () => () => {
//       document
//         .querySelector('meta[name="description"]')
//         .setAttribute("content", existingMetaTags.description);

//       document
//         .querySelector('meta[name="keywords"]')
//         .setAttribute("content", existingMetaTags.keywords);

//       document.getElementsByTagName("title")[0].innerText =
//         existingMetaTags.title;
//     }, []);

//   return user.isAuthenticated ? (
//     loading ? (
//       <div className=" w-full h-screen flex items-center justify-center">
//         <Loader />
//       </div>
//     ) : (
//       <div className="flex mt-3 gap-5 px-4 flex-wrap w-full deal ">
//         {deals?.map((item, index) => (
//           <IndividualOpenDeal key={index} deal={item} />
//         ))}
//       </div>
//     )
//   ) : (
//     <div className=" w-full h-screen flex items-center justify-center">
//       <LoginButton />
//     </div>
//   );
// };

// export default OpenDeals;
