import React from "react";
import IndividualNews from "../IndividualNews";
import { useSelector } from "react-redux";
import { goToCompanyPage } from "../../constant/function";

const NewsInsights = ({
  newsArticles,
  companyName,
  input = { input },
  newsData,
}) => {

  return (
    <div className="flex flex-col gap-20 ">
      <div className="flex flex-col w-full gap-4">
        <div className="flex flex-col gap-2 bg-white lg:p-8 sm:p-4 rounded-b-xl">
          <p className="text-xl font-semibold">
            Real-time News Insights for {companyName}
          </p>
          <ul className="list-outside list-disc pl-5">
            {newsData?.Company_SOS ? (
              String(newsData?.Company_SOS)
                ?.split(". ")
                ?.map((sos, index) => (
                  <li
                    key={index}
                    className="lg:text-base sm:text-sm py-1 px-3 rounded-sm m-3"
                  >
                    {sos}
                  </li>
                ))
            ) : (
              <p></p>
            )}
          </ul>
          {/* <div className="flex gap-1 ">
          </div> */}
        </div>
        <div className="bg-white lg:p-8 sm:p-4 rounded-xl">
          <p className="font-semibold text-2xl">News Summaries</p>
          {newsArticles
            ? newsArticles.map((news, index) => {
                if (news) {
                  return <IndividualNews news={news} key={index} />;
                }
              })
            : "No results found."}
        </div>
      </div>
    </div>
  );
};

export default NewsInsights;
