import React from "react";
import Highcharts from "highcharts";
import {
  HighchartsProvider,
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  ColumnSeries,
  Tooltip,
} from "react-jsx-highcharts";
import { useState, useEffect } from "react";

const InvestorSectorChart = ({ data }) => {
  const [seriesData, setSeriesData] = useState([]);
  const [seriesName, setSeriesName] = useState([]);

  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      const seriesAmount = data.map((item, i) => item?.count);
      const seriesName = data.map((item, i) => item?.sector);

      setSeriesData(seriesAmount);
      setSeriesName(seriesName);
    } else {
      setSeriesData([]);
      setSeriesName([]);
    }
  }, [data]);

  return (
    <div className="app">
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsChart>
          <Chart backgroundColor={"#F4F5F7"} />
          <Tooltip />
          <YAxis tickInterval={1}>
            <ColumnSeries name="Numbers" data={seriesData} />
          </YAxis>
          <XAxis categories={seriesName} className="text-xs">
            <XAxis.Title>Sector wise deals</XAxis.Title>
          </XAxis>
        </HighchartsChart>
      </HighchartsProvider>
    </div>
  );
};

export default InvestorSectorChart;
