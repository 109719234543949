import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_COMPANY_NEWS,
  ADD_MULTIPLE_NEWS,
} from "../../store/slices/news_intelligence";
import { GETARTS, GETIDS } from "../../constant/api";

const useFetchCompanyNews = (companyName) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const isNewsExist = useSelector((store) =>
    store?.news_intelligence?.companyNews[companyName] ? true : false
  );
  useEffect(() => {
    if (!isNewsExist && companyName) {
      (async () => {
        try {
          setLoading(true);
          const res = await fetch(GETIDS + companyName);
          const data = await res.json();          

          if (res.ok) {
            setLoading(false);
            const newslist = data[0]?.Art_Id?.length > 0 ? data[0]?.Art_Id : [];

            const articlesRes = await fetch(
              GETARTS + newslist?.slice(0, 350)?.join(",")
            );
            const articles = await articlesRes.json();

            if (articlesRes.ok) {
              dispatch(ADD_COMPANY_NEWS({ company: companyName, newslist }));
              dispatch(ADD_MULTIPLE_NEWS({ newslist: articles }));
            }
          }
        } catch (error) {
          setLoading(false);
        }
      })();
    }
  }, [companyName]);

  return [loading, error];
};

export default useFetchCompanyNews;
