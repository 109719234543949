import React, { useState } from 'react';


const LatestSide = () => {
    const dayslist = ['30', '60', '90'];
    const dealsType = ['Confirmed', 'Signals'];

    const [selectedDays, setSelectedDays] = useState(dayslist[0]);
    const [selectedDealsType, setSelectedDealsType] = useState(dealsType[0]);



    return (
        <div>
            <h1 className=' text-2xl font-semibold mb-4'>Latest Deals</h1>
            <div className=' flex flex-col items-start gap-2'>
                <div className="flex gap-2 bg-white p-2 w-fit rounded-full shadow-lg">
                    {dealsType?.map((item, index) => (<p
                        className={`rounded-full px-3 py-2 cursor-pointer ${selectedDealsType === item ? ' bg-primaryBlue text-white' : ' bg-transparent text-black'}`}
                        onClick={() => setSelectedDealsType(item)}
                    >
                        {item}
                    </p>))}
                </div>
                <div className="flex gap-2 bg-white p-2 rounded-full shadow-lg">
                    {dayslist?.map((item, index) => (<div
                        className={`flex gap-1 w-fit px-3 py-2 rounded-full ${selectedDays === item ? ' bg-primaryBlue text-white' : ' bg-transparent text-black'}`}
                        onClick={() => setSelectedDays(item)}
                    >
                        <p>{`${item}`}</p><p> days</p>
                    </div>))}
                </div>
                <button className=' rounded-full px-3 py-2 cursor-pointer bg-primaryBlue text-white'>Search</button>
            </div>

        </div>
    )


};

const HistorySide = () => {

    return (
        <div>
            <h1 className=' text-2xl font-semibold mb-4'>History Deals</h1>
        </div>
    )
}

const AllDeals = () => {
    const [loading, setLoading] = useState(false);
    const [deals, setDeals] = useState([]);
    const [error, setError] = useState('');


    return (
        <div className=' flex gap-2'>
            <div className=' w-1/3 px-4 py-0'>
                <LatestSide />
                <HistorySide />
            </div>
            <div className=' w-2/3'></div>
        </div>
    )
}

export default AllDeals;