import { gql } from "graphql-tag";

export const GET_COMPANY = gql`
  query Company($id: ID!, $bid: ID!) {
    company(id: $id) {
      id
      Cin
      Mca_Data {
        Brand_Name
        Brand_Id
        Company_Name
        Date_Of_Registration
        Month_Name
        State
        Roc
        Status
        Category
        Class
        Company_Type
        Authorized_Capital
        Paidup_Capital
        Activity_Code
        Activity_Description
        Registered_Office_Address
        Email
        Latest_Year_Annual_Return
        Latest_Year_Financial_Statement
        Obligation_Of_Contribution
        Number_Of_Partners
        Number_Of_Designated_Partners
        District
        Long_Description
        Code_Description
        Factacy_Industrial_Classification
        Summary
        Keywords
        Turnover_Above
        Gst_Number
        Series
        Amount
        Investors
        Lei
        Investor_Id
        Website
        Linkedin
        EMail
        Facebook
        Instagram
        Youtube
        Badges
        Logo
      }
    }
    leidata(id: $id) {
      id
      Lei
      Lei_Data {
        Brand_Name
        Company_Name
        Cin
        Date_Of_Registration
        Status
        Registration_Authority_Id
        Registration_Status
        Category
        Sub_Category
        Next_Renewal_Date
        Registered_Office_Address
        City
        Region_Code
        Country
        Postal_Code
        Legal_Form_Code
        Company_Type
        Alt_Company_Name
        Investor_Id
        Logo
        Amount
        Series
      }
    }

    brands(id: $bid) {
      id
      Reference_Id
      Container_Name
      Cin
      Brand
      Website
      Facebook
      Instagram
      Linkedin
      Twitter
      Medium
      Youtube
      Reddit
      Summary
      Logo
      Keywords
      Inv_Description
      ESG_Description
      KP_Description
      Comp_Description
      Patent_Description
    }
    competitorBrandData(Brand_Id: $bid) {
      id
      Reference_Id
      Reference_Container
      Competitors_Data {
        Cin
        Brand_Name
        Company_Name
        Recommended_Company
      }
    }
    businessnews_brand(Brand_Id: $bid) {
      Reference_Id
      Art_Id
      ORG
      published_date
      Output_CIN
      Brand_Name
      Legal_Name
      Entity_Type
      Company_SOS
      id
      Reference_Container
      Brand_Id
    }
  }
`;

export const GET_PROPOSED_INVESTORS = gql`
  query Company($bid: ID!) {
    proposed_Brand_investor(Brand_Id: $bid) {
      id
      Reference_Id
      Reference_Container
      Investor_Data {
        Registration_Number
        Brand_Name
        Proposed_Investors
      }
    }
  }
`;

export const GET_PATENTS = gql`
  query Company($id: ID!) {
    getPatent(Reference_Id: $id) {
      Reference_Id
      id
      Reference_Container
      Application_Number
      Patent_Data {
        Cin
        Company_Name
        Title_of_Invention
        Applicant_Name
        Inventor_Name
        Date_of_Filing
        Publication_Date
        Abstract
        Factacy_Patent_IC
        Industrial_Category
      }
    }
  }
`;

export const GET_KEY_PEOPLE = gql`
  query Company($id: ID!) {
    persondata(Reference_Id: $id) {
      Reference_Id
      id
      Reference_Container
      Person_Data {
        Registration_Number
        Company_Name
        Name
        Designation
        Deal_Id
        Art_Id
        Linkedin
        Relation
      }
    }
  }
`;

export const GET_ESG_CSR = gql`
  query Company($id: ID!) {
    esgcompany(Reference_Id: $id) {
      id
      Reference_Id
      Reference_Container
      Esg_Data {
        Registration_Number
        Brand_Name
        ESG_Classification
        E_Score
        S_Score
        G_Score
        Compound_Score
        Art_Id
      }
    }
  }
`;

export const GET_PERSON_COMPANY = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      Cin
      Mca_Data {
        Brand_Name
        Company_Name
        Date_Of_Registration
        State
        Company_Type
        Factacy_Industrial_Classification
        Summary
        Logo
      }
    }
    leidata(id: $id) {
      id
      Lei
      Lei_Data {
        Brand_Name
        Company_Name
        Cin
        Date_Of_Registration
        Status
        Registration_Authority_Id
        Registration_Status
        Category
        Sub_Category
        Next_Renewal_Date
        Registered_Office_Address
        City
        Region_Code
        Country
        Postal_Code
        Legal_Form_Code
        Company_Type
        Alt_Company_Name
        Investor_Id
        Logo
      }
    }
  }
`;

// businessnews(Brand_Id: $bid) {
//   Reference_Id
//   Art_Id
//   ORG
//   published_date
//   Output_CIN
//   Brand_Name
//   Legal_Name
//   Entity_Type
//   Company_SOS
//   id
//   Reference_Container
//   Brand_Id
// }

// competitorData(Reference_Id: $id) {
//   id
//   Reference_Id
//   Reference_Container
//   Competitor_Data {
//     Cin
//     Brand_Name
//     Company_Name
//     Recommended_Company
//   }
// }

// proposed_investor(Reference_Id: $id) {
//   Investor_Data {
//     Registration_Number
//     Brand_Name
//     Proposed_Investors
//   }
//   id
//   Reference_Id
//   Reference_Container
// }