import React from 'react';
import axios from "axios";
import { useDispatch } from 'react-redux';
import { STAGE_SEARCH_LOADING, STAGE_DATA_SUCCESS, STAGE_SEARCH_FAIL} from '../store/slices/stageReducer';
import { SEED_INFO_DETAIL } from '../constant/api';

const useFetchStageActions = () => {

    const dispatch = useDispatch();

    const getStage = async (stage) => {
        const options4 = {
            url:  SEED_INFO_DETAIL + stage + `"&number=20`,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/txt;charset=UTF-8'
            },
            data: {}
        };
        try {

            dispatch(STAGE_SEARCH_LOADING());
            const stageResponse = await axios(options4)
            dispatch(STAGE_DATA_SUCCESS({ data: stageResponse.data, stageName: stage }));
        }
        catch (e) {
            dispatch(STAGE_SEARCH_FAIL());
        }
    }
    return getStage;
}

export default useFetchStageActions;