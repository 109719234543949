import React from "react";
import Loader from "../components/tearSheet/loader";

const StepIndicator = ({ data, statusLoading }) => {
  const flagNames = {
    AI_Profile: {
      name: "AI Profiling Initiated",
      description: "Triggered AI engines to profile this record",
    },
    Aicite_Flag: {
      name: "News Intelligence created",
      description:
        "Fetched and summarised news for this record",
    },
    Aka_Flag: {
      name: "Company Profiling Initiated",
      description:
        "Scanned the internet to know more about the company",
    },
    Bio_Flag: {
      name: "Company Profile Processed",
      description: "Ran AI models to profile the model",
    },
    Coinuse_Flag: {
      name: "Fetched Funding Information",
      description:
        "Scanned news for deals for the company",
    },
    Designation_Flag: {
      name: "Located Key people",
      description: "Gathered and profiled key people related to this company",
    },
    Locater_Flag: {
      name: "Collecting Other profile information",
      description:
        "Scanning for more information about the company",
    },
    // Recomendation_Flag: {
    //   name: "Scanned for related companies",
    //   description: "Scanned and tagged related companies information",
    // },
    AI_Profile2: {
      name: "Record Profiled successfully",
      description: "Ai profiling done, submitted for randomised manual review",
    },
  };

  return (
    <div className="flex items-center h-full">
      <div className="flex flex-col items-start gap-3">
        <p className="font-bold text-2xl -mt-7 pb-2 z-0"></p>

        {/* Iterate over each flag and display its status */}

        {Object.entries(data).map(([flag, status]) => (
          <div
            key={flag}
            className="flex items-start gap-2 text-base font-semibold"
          >
            {/* {statusLoading ? (
              <div className="flex items-start">
                <div className="w-7 h-7 relative bottom-7">
                <Loader />
                </div>
              </div>
            ) : <> */}
           
            {status ? (
              <div className="w-7 h-7">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                      fill="#1fe045"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
            ) : (
              <div className="w-7 h-7">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M12.0001 4.64844C7.22008 4.64844 3.33008 8.53844 3.33008 13.3184C3.33008 18.0984 7.22008 21.9984 12.0001 21.9984C16.7801 21.9984 20.6701 18.1084 20.6701 13.3284C20.6701 8.54844 16.7801 4.64844 12.0001 4.64844ZM12.7501 12.9984C12.7501 13.4084 12.4101 13.7484 12.0001 13.7484C11.5901 13.7484 11.2501 13.4084 11.2501 12.9984V7.99844C11.2501 7.58844 11.5901 7.24844 12.0001 7.24844C12.4101 7.24844 12.7501 7.58844 12.7501 7.99844V12.9984Z"
                      fill="#292D32"
                    ></path>{" "}
                    <path
                      d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z"
                      fill="#292D32"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
            )}
             {/* </>} */}
            <div>
              <p>{flagNames[flag].name}</p>
              <p className="text-sm text-gray-500">
                {flagNames[flag].description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepIndicator;
