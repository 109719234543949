import { createSlice } from "@reduxjs/toolkit";


const persionReducer = createSlice({
    name: "persionReducer",
    initialState: {
        loading: false,
        data: {},
        errorMsg: "",
        person: '',
        personData: [],
        personDealsData: [],
    },
    reducers: {
        PERSON_SEARCH_LOADING: (state, action) => {
            return { ...state, loading: true, errorMsg: "" };
        },
        PERSON_SEARCH_FAIL: (state, action) => { return { ...state, loading: false, errorMsg: "Unable to find person" }; },
        PERSON_DATA_SUCCESS: (state, action) => {
            const { data, personName } = action.payload;
            return { ...state, loading: false, errorMsg: "", personData: { ...state.personData, [personName]: data, } }
        },
    }
});

export const { PERSON_SEARCH_LOADING, PERSON_SEARCH_FAIL, PERSON_DATA_SUCCESS } = persionReducer.actions;
export default persionReducer.reducer;