import React, { createContext, useState, useContext } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isChatWithGrootRenderd, setIsChatWithGrootRenderd] = useState(false);
  const [showChatWithGroot, setShowChatWithGroot] = useState(false);

  // const openChat = () => setIsChatOpen(true);
  const openChat = () => {
    if (!sessionStorage.getItem('email')) {
      alert('Please login first');
    }
    else {
      if (!isChatWithGrootRenderd) {
        setIsChatWithGrootRenderd(true);
      }
      setShowChatWithGroot(true);
    }

  }
  // const closeChat = () => setIsChatOpen(false);
  const closeChat = () => setShowChatWithGroot(false);

  return (
    <ChatContext.Provider value={{ isChatOpen, openChat, closeChat, isChatWithGrootRenderd, setIsChatWithGrootRenderd, showChatWithGroot, setShowChatWithGroot }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);

