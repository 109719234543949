import React, { useRef, useEffect } from "react";
import ForceGraph2D from "react-force-graph-2d";
import { useNavigate } from "react-router";



const KnowledgeGraph = ({data, type}) => { 
  const Navigate = useNavigate(); 
  data && data.nodes && data.nodes?.forEach((node) => {
    node.val = (node.size * 600) | (node.degrees / 10);
    if (node.isClusterNode) {
      node.clusterId = null;
    } else {
      const link = data.links.find((link) => link.target === node.id);
      node.clusterId = link && link.source;
    }
  });


  // const { width = 390, height = 520 } = props;
  const forceRef = useRef(null);

  useEffect(() => {
    forceRef.current.d3Force("charge").strength(-2200);
  }, []);

  const Graph = () => {
    return (
      <div className="relative border-[1px]">
      <ForceGraph2D
        graphData={data}
        onNodeClick={(e)=> window.open( type === "person" ? `/people-intelligence/person/${e.target_id}` : `/company-intelligence/company/${e.name}/${e.target_id}`)}
        width={1120}
        height={600}
        backgroundColor="white"
        nodeLabel="id"
        ref={forceRef}
        onEngineTick={() => {
          // Centering the graph on every tick of the physics engine
          const { x, y } = forceRef.current.centerAt(500 / 7, 520 / 12, 0.5);
          forceRef.current.zoom(1, 1000); // Optionally, adjust zoom level
        }}
        nodeCanvasObjectMode={() => "after"}
        nodeCanvasObject={(node, ctx, globalScale) => {
          const nodeLabel = node.name;
          const fontSize = 12 / globalScale;
          ctx.font = `${fontSize}px Sans-Serif`;
          ctx.textAlign = "center";
          ctx.textBaseline = "center";
          ctx.fillStyle = "black";
          ctx.fillText(nodeLabel, node.x, node.y);
        }}
      />
      </div>
    )
  }
  return (
    <div className="">
    {Graph()}</div>
  );
};


export default KnowledgeGraph;

  // const data = {
  //   nodes: [
  //     {
  //       id: "investor",
  //       name: "INFLECTION POINT VENTURES",
  //       color: "#3E91EE"
  //     },
  //     {
  //       id: "KRISHNA VEER",
  //       name: "KRISHNA VEER",
  //       color: "#3E91EE"
  //     },
  //     {
  //       id: "DHARMIN VORA",
  //       name: "DHARMIN VORA",
  //       color: "#3E91EE"
  //     },
  //     {
  //       id: "RAVI MEHRA",
  //       name: "RAVI MEHRA",
  //       color: "#3E91EE"
  //     },
  //     {
  //       id: "MIKDAD SALEEM",
  //       name: "MIKDAD SALEEM",
  //       color: "#3E91EE"
  //     },
  //     {
  //       id: "NEHA INDORIA",
  //       name: "NEHA INDORIA",
  //       color: "#3E91EE"
  //     },
  //     {
  //       id: "MIRA JHALA",
  //       name: "MIRA JHALA",
  //       color: "#3E91EE"
  //     },
  //     {
  //       id: "CHANDRESH SHARMA",
  //       name: "CHANDRESH SHARMA",
  //       color: "#3E91EE"
  //     },
  //     {
  //       id: "ROHAN SINGH",
  //       name: "ROHAN SINGH",
  //       color: "#3E91EE"
  //     },
  //     {
  //       id: "RISHABH SOOD",
  //       name: "RISHABH SOOD",
  //       color: "#3E91EE"
  //     },
  //     {
  //       id: "ANMOL SINGH JAGGI",
  //       name: "ANMOL SINGH JAGGI",
  //       color: "#3E91EE"
  //     },
  //   ],
  
  //   links: [
  //     {
  //       id: "INFLECTION POINT VENTURES",
  //       source: "investor",
  //       target: "KRISHNA VEER",
  //       name: "DIRECTOR (LISSUN)"
  //     },
  //     {
  //       id: "INFLECTION POINT VENTURES",
  //       source: "investor",
  //       target: "DHARMIN VORA",
  //       name: "FOUNDER (PEAR)"
  //     },
  //     {
  //       id: "INFLECTION POINT VENTURES",
  //       source: "investor",
  //       target: "RAVI MEHRA",
  //       name: "CHIEF EXECUTIVE OFFICER (SHEERDRIVE)"
  //     },
  //     {
  //       id: "INFLECTION POINT VENTURES",
  //       source: "investor",
  //       target: "MIKDAD SALEEM",
  //       name: "FOUNDER (BIZPAY)"
  //     },
  //     {
  //       id: "INFLECTION POINT VENTURES",
  //       source: "investor",
  //       target: "NEHA INDORIA",
  //       name: "CHIEF EXECUTIVE OFFICER (BOINGG)"
  //     },
  //     {
  //       id: "INFLECTION POINT VENTURES",
  //       source: "investor",
  //       target: "MIRA JHALA",
  //       name: "FOUNDER (FROGO)"
  //     },
  //     {
  //       id: "INFLECTION POINT VENTURES",
  //       source: "investor",
  //       target: "CHANDRESH SHARMA",
  //       name: "CHIEF EXECUTIVE OFFICER (NIMBLES2P)"
  //     },
  //     {
  //       id: "INFLECTION POINT VENTURES",
  //       source: "investor",
  //       target: "ROHAN SINGH",
  //       name: "CHIEF OPERATING OFFICER (GLAMPLUS)"
  //     },
  //     {
  //       id: "INFLECTION POINT VENTURES",
  //       source: "investor",
  //       target: "RISHABH SOOD",
  //       name: "CTO (BLUSMART)"
  //     },
  //     {
  //       id: "INFLECTION POINT VENTURES",
  //       source: "investor",
  //       target: "ANMOL SINGH JAGGI",
  //       name: "CHIEF EXECUTIVE OFFICER (BLUSMART)"
  //     },
  
  //   ]
  // };