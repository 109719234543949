import React, { useState, useEffect } from 'react';
import metaTags from "../constant/metaTags";

const MetaTagesAdder = ({children}) => {

    const [existingMetaTags] = useState({
        description: document
            .querySelector('meta[name="description"]')
            .getAttribute("content"),
        keywords: document
            .querySelector('meta[name="keywords"]')
            .getAttribute("content"),
        title: document.getElementsByTagName("title")[0].innerText,
    });

    if (metaTags[window.location.pathname]?.keywords) {
        document
            .querySelector('meta[name="description"]')
            .setAttribute("content", metaTags[window.location.pathname].description);
        document
            .querySelector('meta[name="keywords"]')
            .setAttribute("content", metaTags[window.location.pathname].keywords);
        document.getElementsByTagName("title")[0].innerText = metaTags[window.location.pathname].title;
    }

    useEffect(
        () => () => {
            document
                .querySelector('meta[name="description"]')
                .setAttribute("content", existingMetaTags.description);

            document
                .querySelector('meta[name="keywords"]')
                .setAttribute("content", existingMetaTags.keywords);

            document.getElementsByTagName("title")[0].innerText =
                existingMetaTags.title;
        }, []);

    return children;
}

export default MetaTagesAdder;