import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import factacy from "../../assets/Factacy-grayLogomark (1).png";
import redirect from "../../assets/redirect.png";
import useMediaQuery from "../../hooks/useMediaQuery";
import Loader from "../tearSheet/loader";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useAuth } from "../auth/authContext";
import LoginButton from "../auth/loginButton";
import { howOldTimeUpdater } from "../../constant/function";
import { GETARTS } from "../../constant/api";

const SingleNews = () => {
  const id = useParams();
  const Navigate = useNavigate()
  const [newsData, setNewsData] = useState([]);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSingleNews = async () => {
      setLoading(true);
      const response = await axios.get(GETARTS + id.id);
      setNewsData(response.data);
      setLoading(false);
    };
    fetchSingleNews();
  }, [id.id]);

  useDocumentTitle(`Aicite | ${id.headline?.split("-")?.join(" ")}`);

  return (
    <div className="h-max flex flex-col gap-4 items-center justify-center w-full px-4 lg:py-20 sm:py-28">
      {!isLoading &&
        newsData &&
        newsData.map((news, index) => {
          return (
            <div
              className="sm:relative lg:static lg:w-2/3 sm:w-full lg:mx-0 sm:mx-3 flex flex-col sm:h-full lg:h-full gap-12 bg-white lg:p-5 rounded-lg items-start lg:justify-start sm:justify-end sm:bg-gradient-to-t from-white to-transparent z-0 news-container"
              key={index}
              style={{
                boxShadow:
                  "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)",
              }}
            >
              <div className="flex lg:flex-row sm:flex-col lg:h-1/2 sm:h-full sm:gap-2 lg:gap-6">
                <div className="lg:w-1/2 lg:h-60 sm:w-fit sm:h-full sm:p-0 lg:mb-0">
                  <img
                    alt="img"
                    onError={(e) => {
                      e.target.src = factacy;
                      e.target.alt = "Alternative Image";
                    }}
                    src={news.image}
                    className="lg:w-full lg:h-full rounded-lg object-cover "
                  />
                </div>
                <div className="w-full flex flex-col gap-6 justify-between sm:rounded-2xl sm:px-4 sm:pb-4 sm:pt-2 lg:pb-0 sm:w-fit lg:px-0 lg:pt-0 lg:bg-none lg:w-full sm:bg-gradient-to-t from-white from-73% via-white via-70% to-transparent to-100%">
                  <div className="flex flex-col gap-3">
                    <h1
                      className="lg:text-3xl sm:text-lg font-bold"
                      style={{
                        filter: `${
                          !isDesktop
                            ? "drop-shadow(0 10px 8px rgb(255 255 255))"
                            : ""
                        }`,
                      }}
                    >
                      {news.headline}
                    </h1>
                    <div className="text-gray-600">
                      <div className="text-lg">
                        {/* {showFullContent[news.id] ? 
                            <> */}
                        {news.summary_of_article}
                        {/* <p className='text-lg text-primaryBlue inline cursor-pointer' onClick={() => toggleShowContent(news.id)}> Read Less </p>
                          </>
                        : 
                        (
                          <>
                          {news.summary_of_article?.slice(0, 200)}
                        <p className='text-lg text-primaryBlue inline cursor-pointer ' onClick={() => toggleShowContent(news.id)}>  ...Read more </p>
                        </>
                        )}               */}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-sm  text-gray-500">
                        {howOldTimeUpdater(news?.Unique_date_time)}
                      </p>
                    </div>
                    {news.summary_IC ? (
                      <p className="text-sm py-1 px-3 rounded-full bg-purple w-max">
                        {" "}
                        {news.summary_IC === "nan" ? "Others" : news.summary_IC}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <hr className="w-full" />

              <div className="flex flex-col w-full lg:h-1/2 sm:h-full gap-7 lg:p-0 sm:p-2">
                <div className="flex items-center justify-between">
                  <p className="font-semibold text-lg underline">
                    {news.title}
                  </p>
                  <a href={news.url} rel="noreferrer" target="_blank">
                    <img alt="redirect" className="w-6 h-6" src={redirect} />
                  </a>
                </div>
                <div className="w-full h-80">
                  <iframe title="" className="w-full h-full" src={news.url} />
                </div>
              </div>
            </div>
          );
        })}

      {isLoading && (
        <div className="h-screen">
          <Loader />
        </div>
      )}

      <p
        onClick={() => Navigate("/news-intelligence/home-news")}
        className=" px-3 py-2 bg-primaryBlue rounded-full text-white"
      >
        Continue Reading
      </p>
    </div>
  );
};

export default SingleNews;
