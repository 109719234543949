import React from "react";
import Table1 from "./Table1";

const Cashflow = ({ financialsData }) => {
  return (
    <div className={`w-full gap-4 flex flex-col`}>
      <p className="text-2xl font-bold">Cashflow Statement</p>
      <Table1 Data={financialsData?.[0].CASH_FLOW} chart="bar" />
    </div>
  );
};

export default Cashflow;

//       className="h-max border-[1px] top-20 sticky pt-5 lg:w-1/3 sm:w-full sm:order-1 lg:order-2"
//       style={{
//         boxShadow:
//           "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)",
//       }}
//     >
//       {financialsData &&
//         financialsData?.map((entry, index) => {
//           <React.Fragment key={index}>
//             {Object.keys(entry).map((key) => {
//               if (key.startsWith("CF_")) {
//                 const yearData = entry[key];
//                 const name = key.replace(/CF_/g, "20").replace(/_/g, "-");
//                 let Value;

//                 if (
//                   activeRowCS ===
//                   "Cash flows from used in operating activities"
//                 ) {
//                   Value =
//                     yearData !== "-"
//                       ? +yearData[0][
//                           "Cash flows from used in operating activities"
//                         ]?.value[0] / 10000000
//                       : "-";
//                 } else if (
//                   activeRowCS ===
//                   "Cash flows from used in investing activities"
//                 ) {
//                   Value =
//                     yearData !== "-"
//                       ? +yearData[0][
//                           "Cash flows from used in investing activities"
//                         ]?.value[0] / 10000000
//                       : "-";
//                 } else if (
//                   activeRowCS ===
//                   "Cash flows from used in financing activities"
//                 ) {
//                   Value =
//                     yearData !== "-"
//                       ? +yearData[0][
//                           "Cash flows from used in financing activities"
//                         ]?.value[0] / 10000000
//                       : "-";
//                 } else if (
//                   activeRowCS ===
//                   "Increase (decrease) in cash and cash equivalents"
//                 ) {
//                   Value =
//                     yearData !== "-"
//                       ? +yearData[0][
//                           "Increase (decrease) in cash and cash equivalents"
//                         ]?.value[0] / 10000000
//                       : "-";
//                 } else if (
//                   activeRowCS ===
//                   "Cash and cash equivalents cash flow statement"
//                 ) {
//                   Value =
//                     yearData !== "-"
//                       ? +yearData[0][
//                           "Cash and cash equivalents cash flow statement"
//                         ]?.value[0] / 10000000
//                       : "-";
//                 }
//                 ValueArray1.push({ name, Value });
//                 return { ValueArray1 };
//               }
//             })}
//           </React.Fragment>;
//           return <CashflowChart key={index} data={ValueArray1} />;
//         })}
//     </div>
//   </div>
// )}
