import React from 'react'

const FinancialsNews = ({newsArticles, company}) => {
  return (
    <div className='bg-white w-full lg:p-8 '>
        <p>{newsArticles?.filter(news => news.hasOwnProperty('Deals') && news.visibility === "YES")?.[0]?.summary_of_article ?? `Factacy Insights has detailed financials for ${company?.Brand ?? company.Company_Name}. This can be accessed on demand. To request ${company?.Brand ?? company.Company_Name}'s revenue, EBIDTA, PAT, Balance sheet and Shareholder information, Please login and submit the request.`}</p>
    </div>
  )
}

export default FinancialsNews