import React, { useEffect } from 'react'
import calender from "../../assets/calender.svg";
import Loader from "./loader";
import { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { useAuth } from "../auth/authContext";
import LoginComponent from "../loginComponent";
import { useSelector } from 'react-redux';

const PatentsGroot = ({  loading, input, getPatentsData }) => {
    const [isFlipped, setFlipped] = useState(true);
    const { user } = useAuth();

    useEffect(() => {
      getPatentsData(input)
    },[])
  const patentData = useSelector((state) => state.company).patentsData[input];
  // useAddMetaTags(company?.Brand ?? company?.Company_Name, "Patents, Intellectual Property", company?.Keywords, company?.Patent_Description)

    const handleClick = (e) => {
      e.preventDefault();
      setFlipped((prevIsFlipped) => !prevIsFlipped);
    };
  return (
    <>
      {user.isAuthenticated == true ? (
        <div className="flex flex-col sm:gap-10 lg:gap-8 bg-white sm:w-full rounded-b-xl  lg:p-8 sm:p-4 gap-6">
          <div className="font-medium text-[25px] tracking-tight flex justify-between leading-9">
            <p>Patents</p>
            <button
              className={`px-3 py-2 lg:text-sm sm:text-[10px] ${
                isFlipped
                  ? "active bg-white rounded-full text-primaryBlue border-[1px] hover:text-white hover:bg-primaryBlue border-primaryBlue"
                  : "bg-primaryBlue border-primaryBlue text-white rounded-full border-[1px]"
              }`}
              onClick={handleClick}
            >
              Simple Abstract
            </button>
          </div>

          {loading && <div className=" w-full h-full min-h-[22rem] flex items-center justify-center"> <Loader /></div>}

          {patentData && patentData.map((patent, index) => (
            // item.Patent_Data.map((patent, index) => (
              <div
                className="flex lg:flex-row sm:flex-col sm:gap-8 p-7 rounded-lg"
                style={{
                  boxShadow:
                    "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
                }}
                key={index}
              >
                <div className="lg:w-1/3 sm:w-full flex flex-col lg:gap-12 sm:gap-6 lg:pr-16 sm:pr-0">
                  <div className="flex items-end gap-2">
                    <img
                      className="lg:w-6 lg:h-6 sm:w-5 sm:h-5"
                      src={calender}
                    />
                    <p className="sm:text-lg lg:text-xl font-bold">
                      {" "}
                      {patent.Patent_Data.Publication_Date ??
                        patent["Publication Date"]}{" "}
                    </p>
                  </div>

                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col">
                      <p className="text-gray-500 lg:text-base sm:text-sm">
                        Applied by
                      </p>
                      <p className="font-semibold sm:text-base lg:text-lg">
                        {patent.Patent_Data.Applicant_Name}
                      </p>
                    </div>

                    <div className="flex flex-col">
                      <p className="text-gray-500 lg:text-base sm:text-sm">
                        Innovators
                      </p>
                      <p className="font-semibold sm:text-base lg:text-lg">
                        {patent.Patent_Data.Inventor_Name.join(", ")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-2/3 sm:w-full flex flex-col sm:gap-3 lg:gap-8 sm:pr-0 lg:pr-12">
                  <div className="font-bold text-lg font-roboto">
                    {patent.Patent_Data.Title_of_Invention}
                  </div>

                  <ReactCardFlip
                    isFlipped={isFlipped}
                    flipDirection="horizontal"
                  >
                    <div className="text-gray-800 sm:text-base lg:text-lg">
                      {patent.Patent_Data.Simple_Abstract &&
                        patent.Patent_Data.Simple_Abstract.map((abs, i) => (
                          <li key={i}>{abs}</li>
                        ))}
                    </div>
                    <div className="text-gray-500 sm:text-base lg:text-lg">
                      {patent.Patent_Data.Abstract}
                    </div>
                  </ReactCardFlip>

                  <div className="flex lg:flex-row sm:flex-col sm:flex-wrap lg:flex-nowrap w-full lg:gap-8 sm:gap-4 mt-2">
                    <div className="flex flex-col gap-2 lg:w-1/3 sm:w-full group">
                      <p className="text-sm text-gray-500 pl-3">
                        Factacy Classification
                      </p>
                      <p
                        className="bg-yellow px-4 py-2 rounded-full text-sm max-w-fit font-semibold cursor-pointer"
                        title={patent.Patent_Data.Factacy_Patent_IC}
                      >
                        {patent.Patent_Data.Factacy_Patent_IC?.slice(0, 20) + "..."}
                      </p>
                    </div>
                    <div className="flex flex-col gap-2 lg:w-1/3 sm:w-full group">
                      <p className="text-sm text-gray-500 pl-3">Category</p>
                      <p
                        className="bg-blue px-4 py-2 rounded-full text-sm max-w-fit font-semibold cursor-pointer"
                        title={patent.Patent_Data.Industrial_Category}
                      >
                        {patent.Patent_Data.Industrial_Category?.slice(0, 20) + "..."}
                      </p>
                    </div>
                    <div className="flex flex-col gap-2 lg:w-1/3 sm:w-full group">
                      <p className="text-sm text-gray-500 pl-3">Sub Category</p>
                      <p
                        className="bg-purple px-4 py-2 rounded-full text-sm max-w-fit font-semibold cursor-pointer"
                        title={patent.Patent_Data.Sub_Category}
                      >
                        {patent.Patent_Data.Sub_Category?.slice(0, 20) + "..."}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
        //   ))
        ))}


        </div>
      ) : (
        <LoginComponent />
      )}
    </>
  )
}

export default PatentsGroot