import React from "react";
import Table2 from "./Table2";

const Share_Capital = ({ financialsData }) => {
  return (
    <div className="w-full">
      <Table2 Data={financialsData?.[0].SHARE_CAPITAL} text="Share Capital" />
    </div>
  );
};

export default Share_Capital;

