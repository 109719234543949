import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STORE_NEWS_INSIGHTS } from "../../store/slices/companyReducer";
import { useParams } from "react-router";
import { GETARTS } from "../../constant/api";

const useStoreNews = (input) => {
  const dispatch = useDispatch();
  const Art_Id = useSelector((store) =>
    store?.company?.newsData[input]?.Art_Id.slice(0, 100)?.join(",")
  );

  useEffect(() => {
    (async () => {
      try {
        if (Art_Id) {
          const res = await fetch(GETARTS + Art_Id);

          const data = await res.json();

          dispatch(STORE_NEWS_INSIGHTS({ news: data }));
        }
      } catch (error) {}
    })();
  }, [Art_Id, dispatch]);
};

export default useStoreNews;
