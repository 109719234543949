import React from 'react'
import Unicorn from './unicorn';
import SideBarLayoutLeaderboard from './sideBarLayoutLeaderboard';
import { Route, Routes } from 'react-router';
import SharkTank from './sharkTank';
import SideBar from "../sideBarMain/index"
import LeadingInvestors from './leadingInvestors';
import Leading_Sectors from './Leading_Sectors';
import Soonicorn from './Soonicorn';
import Errorpage from '../errorpage';
import MetaTagesAdder from '../MetaTagesAdder';


const Leaderboards = () => {
  return (
    <div className="w-full h-screen overflow-y-auto pt-20">
      <Routes>
        <Route className={`w-full section `} element={<MetaTagesAdder><Unicorn /></MetaTagesAdder>} path="/unicorns" />
        <Route element={<MetaTagesAdder><SharkTank /></MetaTagesAdder>} path='/shark-tank-companies' />
        <Route element={<MetaTagesAdder><Leading_Sectors /></MetaTagesAdder>} path='/leading-sectors' />
        <Route element={<MetaTagesAdder><LeadingInvestors /></MetaTagesAdder>} path='/leading-investors' />
        <Route element={<MetaTagesAdder><Soonicorn /></MetaTagesAdder>} path='/soonicorns' />
        <Route element={<Errorpage />} path='/*' />
      </Routes>
    </div>
  );
}

export default Leaderboards