import React, { useState, useEffect } from "react";
import { useAuth } from "../auth/authContext";
import Loder from "../tearSheet/loader";
import LoginButton from "../auth/loginButton";
import useFetchSearchSuggestions from "../../hooks/Search/useFetchSearchSuggestions";
import useFetchCompany from "../../hooks/Tearsheet/useFetchCompany";
import { useSelector } from "react-redux";
import PotentialInvestors from "../tearSheet/potentialInvestors";
import { falseValues } from "../../constant/constant";

const PotentialInvestorsPage = () => {
  const { user } = useAuth();
  const [binput, setInput] = useState("");
  const [refrenceId, setRefrenceId] = useState("");
  const [loading, setLoading] = useState("");
  const [companyName, setCompanyName] = useState({});
  const [x, y, { companySuggestions }] = useFetchSearchSuggestions(binput);

  const { getProposedInvestors } = useFetchCompany(x, binput);

  useEffect(() => {
    if (refrenceId) {
      setLoading("loading");
      getProposedInvestors(refrenceId)
        .then(res => setLoading("done"))
    }
  }, [refrenceId]);

  const state = useSelector((store) => store.company.loading);

  return user.isAuthenticated ? (
    <div className="w-full p-4 pt-16 lg:pt-0 h-full rounded-xl">
      <div className="flex sm:flex-col-reverse lg:flex-row  justify-end gap-4 ">
        <div>
          <div className=" relative ">
            <div className="flex justify-between gap-2 items-center rounded-xl border border-gray-400 bg-white px-4 py-1 h-fit">
              <svg
                className=" cursor-pointer"
                width="23"
                height="21"
                viewBox="0 0 27 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.5214 18.7084L26.0029 24.1899L24.1928 26L18.7113 20.5184C16.7404 22.0953 14.2408 23.0386 11.5222 23.0386C5.16358 23.0386 0.00292969 17.878 0.00292969 11.5193C0.00292969 5.16065 5.16358 0 11.5222 0C17.8809 0 23.0415 5.16065 23.0415 11.5193C23.0415 14.2379 22.0982 16.7374 20.5214 18.7084ZM17.9535 17.7587C19.5186 16.1457 20.4817 13.9455 20.4817 11.5193C20.4817 6.56921 16.4723 2.55985 11.5222 2.55985C6.57213 2.55985 2.56278 6.56921 2.56278 11.5193C2.56278 16.4694 6.57213 20.4788 11.5222 20.4788C13.9485 20.4788 16.1486 19.5156 17.7616 17.9505L17.9535 17.7587Z"
                  fill="#71717A"
                />
              </svg>
              <input
                placeholder="Enter company name here..."
                className="p-2 rounded-md w-[22rem] appearance-none focus:outline-none "
                type="text"
                value={binput}
                onChange={(e) => setInput(e.target.value)}
              />
            </div>

            {
              <div className=" absolute top-full right-0 w-full">
                {(() => {
                  const list = companySuggestions?.slice(0, 10);
                  return list?.length > 0 && binput.length > 0 ? (
                    <ul className=" p-4 bg-white mt-2 rounded-md">
                      {list?.map((item, key) => (
                        <li
                          className=" p-2 hover:bg-gray-100 rounded-md cursor-pointer my-1"
                          key={key}
                          onClick={(e) => {
                     
                              if(item.ID){
                                setRefrenceId(item.BRAND_ID[0]);
                                setCompanyName(item?.NAME?.[0])
                              }                            
                            setInput("");
                          }}
                        >
                          {item.ALIAS?.[0] && !falseValues.includes(item.ALIAS?.[0]) && (
                            <>
                              <span className="uppercase text-primaryBlue">
                                {item.ALIAS?.[0]}
                              </span>{" "}
                              <span> - </span>
                            </>
                          )}
                          {item.NAME[0] && !falseValues.includes(item.ALIAS?.[0]) && (
                            <>
                              <span className="uppercase">{item.NAME[0]}</span>
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <></>
                  );
                })()}
              </div>
            }
          </div>
        </div>
      </div>
      {loading === "loading" ? (
        <div className="w-full p-4 pt-0 h-full flex items-center justify-center">
          <Loder />
        </div>
      ) : loading === "done" && (<PotentialInvestors companyState={state} companyName={companyName}/>)}
    </div>
  ) : (
    <div className="w-full p-4 pt-0 h-full  flex items-center justify-center">
      <LoginButton />
    </div>
  );
};
export default PotentialInvestorsPage;

{
  /* // company?.investors?.length > 0 && (
        //   <div className="flex flex-col items-center gap-6 flex-wrap mt-4 rounded-xl p-4">
        //     <div className=" flex flex-col gap-4">
        //       {company?.name && (
        //         <h1 className=" text-2xl font-semibold">{company?.name}</h1>
        //       )}
        //       {company?.summary && <p className="">{company?.summary}</p>}
        //     </div>

        //     <div className=" flex flex-wrap gap-4">
        //       {company?.investors?.map((item, index) => (
        //         <span
        //           key={index}
        //           className=" bg-white px-4 py-2 cursor-pointer rounded-full hover:text-primaryBlue hover:underline hover:scale-105"
        //           onClick={() =>
        //             window.open(
        //               `/investor-intelligence/investor/${item[0]?.trim()}`
        //             )
        //           }
        //         >
        //           {item[1]}
        //         </span>
        //       ))}
        //     </div>
        //     <a
        //       className="flex justify-center gap-12 text-sm text-white py-3 px-6 font-semibold rounded-full h-max hover:underline-none"
        //       href="https://www.startupinvestors.ai/"
        //       target="_blank"
        //       style={{ backgroundColor: "rgba(62, 145, 238, 1)" }}
        //     >
        //       For more info..
        //     </a>
        //   </div>
        // ) */
}
