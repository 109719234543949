import React, { useState } from 'react';
import { CHAT_WITH_GROOT_QUERY_API } from '../../constant/api';
import { chatWithGrootHistoryList } from '../../utils/keyConstants';

const useQuery = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fn = async (question) => {
        if (!loading && question) {
            try {
                setLoading(true);
                const res = await fetch(CHAT_WITH_GROOT_QUERY_API,

                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'Application/json'
                        },
                        body: JSON.stringify({
                            // email: sessionStorage.getItem('email'),
                            email: 'arshad@factacy.ai',
                            question,
                        })
                    }
                );
                if (res.status >= 200 && res.status <= 299) {
                    const answer = await res.json();
                    // dummay------
                    const ans = [{
                        question, answer
                    }]

                    const historyList = JSON.parse(localStorage.getItem(chatWithGrootHistoryList));
                    const list = Array.isArray(historyList) ? [...historyList, ...ans] : [...ans];
                    window.localStorage.setItem(chatWithGrootHistoryList, JSON.stringify(list));

                }
                else {
                    const er = await res.json();
                    throw new Error(er?.error);
                }
            }
            catch (error) {
            }
            finally {
                setLoading(false);
            }
        }
    }
    return [loading, error, fn];
}

export default useQuery;