import React, { useState } from 'react'
import { CHAT_WITH_GROOT_HISTORY_API } from '../../constant/api';
import { chatWithGrootHistoryToken, chatWithGrootHistoryList, chatWithGrootHistoryCompleted} from '../../utils/keyConstants';

const useHistoryFetch = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const firstTimeHistoryGetter = async () => {
        try {
            setLoading(true);
            const history = JSON.parse(window.localStorage.getItem(chatWithGrootHistoryList));
            if (!Array.isArray(history)) {
                const res = await fetch(CHAT_WITH_GROOT_HISTORY_API, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'Application/json'
                    },
                    body: JSON.stringify({
                        email: sessionStorage.getItem('email'),
                    })
                });
                if (res.status >= 200 && res.status <= 299) {
                    const ans = await res.json();
                    const historyList = JSON.parse(localStorage.getItem(chatWithGrootHistoryList));
                    const list = Array.isArray(historyList) ? [...ans.list, ...historyList] : [...ans.list];
                    window.localStorage.setItem(chatWithGrootHistoryList, JSON.stringify(list));

                    if (ans?.nextToken) {
                        window.localStorage.setItem(chatWithGrootHistoryToken, JSON.stringify(ans.nextToken));
                    }

                }
                else {
                    const er = await res.json();
                    throw new Error(er?.error);
                }
            }

        }
        catch (err) {

        }
        finally {
            setLoading(false);
        }

    }

    const historyGetter = async () => {
        try {
            const nextToken = localStorage.getItem(chatWithGrootHistoryToken);
            if (nextToken) {
                setLoading(true);
                const res = await fetch(CHAT_WITH_GROOT_HISTORY_API, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'Application/json'
                    },
                    body: JSON.stringify({
                        email: sessionStorage.getItem('email'),
                        nextToken: localStorage.getItem(chatWithGrootHistoryToken),
                    })
                });

                if (res.status >= 200 && res.status <= 299) {
                    const ans = await res.json();
                    const historyList = JSON.parse(localStorage.getItem(chatWithGrootHistoryList));
                    const list = Array.isArray(historyList) ? [...ans, ...historyList] : [...ans];
                    window.localStorage.setItem(chatWithGrootHistoryList, JSON.stringify(list));

                    if (ans?.nextToken) {
                        window.localStorage.setItem(chatWithGrootHistoryToken, JSON.stringify(ans.nextToken));
                    }
                    else {
                        window.localStorage.removeItem(chatWithGrootHistoryToken);
                    }
                }
                else {
                    const er = await res.json();
                    throw new Error(er?.error);
                }
            }

        }
        catch (error) {
            console.log(error.error);
            if (error.error === 'No more history exists') {
                window.localStorage.setItem(chatWithGrootHistoryCompleted, JSON.stringify(true));
            }
        }
        finally {
            setLoading(false);
        }
    };
    return [loading, error, firstTimeHistoryGetter, historyGetter];
}

export default useHistoryFetch;