export const industries = [
    {
        id: 1,
        title: "Agriculture and Farming",
    },
    {
        id: 2,
        title: "Automotive",
    },
    {
        id: 3,
        title: "Aviation and Aerospace",
    },
    {
        id: 4,
        title: "Banking and Finance",
    },
    {
        id: 5,
        title: "BioTechnology",
    },
    {
        id: 6,
        title: "Business Services",
    },
    {
        id: 7,
        title: "Chemical Sector",
    },
    {
        id: 8,
        title: "Construction and Infrastructure",
    },
    {
        id: 9,
        title: "Consumer Electronics",
    },
    {
        id: 10,
        title: "Data and Analytics",
    },
    {
        id: 11,
        title: "DeepTech",
    },
    {
        id: 12,
        title: "Defense and Military",
    },
    {
        id: 13,
        title: "Education and Training",
    },
    {
        id: 14,
        title: "Electronic Manufacturing",
    },
    {
        id: 15,
        title: "Energy Sector",
    },
    {
        id: 16,
        title: "Environment tech",
    },
    {
        id: 17,
        title: "Food & Beverage",
    },
    {
        id: 18,
        title: "Food Tech",
    },
    {
        id: 19,
        title: "Gig Economy",
    },
    {
        id: 20,
        title: "HealthCare & pharmaceuticals",
    },
    {
        id: 21,
        title: "HealthTech",
    },
    {
        id: 22,
        title: "Hospitality and Tourism",
    },
    {
        id: 23,
        title: "Import & Export Sector",
    },
    {
        id: 24,
        title: "Industrial Manufacturing",
    },
    {
        id: 25,
        title: "Information Technology",
    },
        {
        id: 26,
        title: "Insurance",
    },
    {
        id: 27,
        title: "Internet and E-commerce",
    },
    {
        id: 28,
        title: "Journalism and Publishing",
    },
    {
        id: 29,
        title: "Legal and Law Enforcement",
    },
    {
        id: 30,
        title: "Life Science",
    },
    {
        id: 31,
        title: "Management and Consulting",
    },
    {
        id: 32,
        title: "Manufacturing",
    },
    {
        id: 33,
        title: "Media and Entertainment",
    },
    {
        id: 34,
        title: "Medical Devices",
    },
    {
        id: 35,
        title: "Mining and Metals",
    },
    {
        id: 36,
        title: "Nonprofit and Philanthropy",
    },
    {
        id: 37,
        title: "Oil Refining Sector",
    },
    {
        id: 38,
        title: "Online dating and matchmaking Tech",
    },
    {
        id: 39,
        title: "personal care products and fashion tech",
    },
    {
        id: 40,
        title: "ride hailing services",
    },
    {
        id: 41,
        title: "Robotics and Automation",
    },
    {
        id: 42,
        title: "Saas Sector",
    },
    {
        id: 43,
        title: "SeaFood Sector",
    },
    {
        id: 44,
        title: "Service Industry",
    },
    {
        id: 45,
        title: "Sports and Recreation",
    },
    {
        id: 46,
        title: "TechWear",
    },
    {
        id: 47,
        title: "Telecommunication",
    },
    {
        id: 48,
        title: "Textiles and Apparel",
    },
    {
        id: 49,
        title: "Transportation and Logistics Tech",
    },
    {
        id: 50,
        title: "Veterinary activities",
    },
    {
        id: 51,
        title: "Waste Management and Recycling",
    },
    {
        id: 52,
        title: "Water and Sanitation",
    },
    {
        id: 53,
        title: "Wholesale Trade & retail",
    },
]