import React from 'react'
import Highcharts from 'highcharts';
import {
  HighchartsProvider, HighchartsChart, Chart, XAxis, YAxis, LineSeries, Legend
} from 'react-jsx-highcharts';



const LineGraphForPLStatement = ({data, year}) => {
  const plotOptions = {
    series: {
      pointStart: parseInt(year?.replace(/FY /g, "").split("-")[0])
    }
  };

  
    return (
        <div className="app">
          <HighchartsProvider Highcharts={Highcharts}>
            <HighchartsChart plotOptions={plotOptions}>
              <Chart />
              <Legend layout="horizontal" align="left" verticalAlign="bottom" />

              <XAxis>
                <XAxis.Title>Financial Year</XAxis.Title>
              </XAxis>
              <YAxis>
                <YAxis.Title>Ratio</YAxis.Title>
                <LineSeries name="Profit before prior period items Exceptional items extraordinary items and tax" dataLabels={{ enabled: true, style: { fontSize: '10px', color: '#000000' }}} data={data[0]?.flat()} />
                <LineSeries name="Total Revenue"dataLabels={{ enabled: true, style: { fontSize: '10px', color: '#000000' }}} data={data[1]?.flat()} />
                <LineSeries name="Profit before tax"dataLabels={{ enabled: true, style: { fontSize: '10px', color: '#000000' }}} data={data[2]?.flat()} />

              </YAxis>
            </HighchartsChart>
          </HighchartsProvider>
        </div>
      );
}

export default LineGraphForPLStatement