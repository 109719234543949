import React, { useEffect, useState } from "react";
import {
  LEADING_SECTORS,
  FUNDING_NEWS,
  DEALS_BY_ART_IDS,
} from "../../constant/api.js";
// import IndividualLeadingSector from "../components/sectorIntelligence/IndividualLeadingSector.jsx"
import IndividualLeadingSector from "../../pages/IndividualLeadingSector.jsx";
import Loader from "../../components/tearSheet";

const Leading_Sectors = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [news, setNews] = useState([]);
  const [deals, setDeals] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [dealIdRecord, setDealIdRecord] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await fetch(LEADING_SECTORS);
        if (res.ok) {
          const response = await res.json();

          setData(response.filter(Boolean));
          const dealIds = response.filter(Boolean).map(item => item.Deal_Id.slice(0,5).join(","));

          if (dealIds) {
            setDealIdRecord(dealIds);
            (async () => {
              try {
                setLoading(true);
                const res = await fetch(DEALS_BY_ART_IDS + dealIds);
                if (res.ok) {
                  const data = await res.json();

                  const sortedDeals = data
                    ?.sort((a, b) => b?.Deal_Date - a?.Deal_Date)
                    

                  const store = {};
                  sortedDeals?.forEach((item) => (store[item.id] = item));

                  setDeals(store);

                  const investors = data
                    ?.map((item) => item?.Corrected_Investors)
                    .flat(1)
                    .filter((item) => item[0] !== "NULL")
                    .slice(0, 3);
                  setInvestors(investors);
                  setLoading(false);
                } else {
                  setLoading(false);
                }
              } catch (error) {
                setLoading(false);
              }
            })();
          }

          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        // setLoading(true);
        const res = await fetch(FUNDING_NEWS);
        if (res.ok) {
          const news = await res.json();
          setNews(news);
          // setLoading(false);
        } else {
          // setLoading(false)
        }
      } catch (error) {
        // setLoading(false);
      }
    })();
  }, []);

  return (
    <div className="lg:pt-0 px-4 sm:pt-20 sm:w-screen lg:w-full flex flex-col">
      {loading ? (
        <div className=" w-full h-full flex items-center justify-center">
          {" "}
          <Loader />{" "}
        </div>
      ) : (
        <div className="flex flex-col w-full lg:px-5 sm:px-4  justify-between gap-5">
          {data?.slice(0, 10)?.map((item, index) => (
            <IndividualLeadingSector
              investors={investors}
              dealsNews={news}
              data={item}
              key={index}
              sortedData={deals}
              dealIds={dealIdRecord[index]}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Leading_Sectors;
