import ChatBox from "./ChatBox";
import { useChat } from "./ChatContext";
import arrowIcon from "../../assets/sidebar assets/arrow.svg"


const ChatWithGroot = () => {
  const { isChatWithGrootRenderd, showChatWithGroot, closeChat, openChat } = useChat();
  return (
    <div
      className={` fixed z-[49] w-[28rem] top-[4.8rem] right-0 h-[calc(100vh-4.8rem)] ${showChatWithGroot ? 'translate-x-0' : 'translate-x-full'} transition-all `}
    >
      <div className=" w-full h-full relative">
        {isChatWithGrootRenderd && (<div
          onClick={() => showChatWithGroot ? closeChat() : openChat()}
          className=" absolute top-[100px] right-full h-12 w-12 cursor-pointer bg-white rounded-full translate-x-1/3"
        >
          <img
            className={` ${showChatWithGroot ? " -rotate-90 " : " rotate-90"}`}
            src={arrowIcon}
            alt=""
          />
        </div>)}
        <ChatBox />
      </div>
    </div>
  );
};

export default ChatWithGroot;