import React, { useState, useCallback } from "react";
import factacy from "../../assets/factacy_logo.svg";
import source1 from "../../assets/arrow-down-double-line.png";
import { useEffect } from "react";
import axios from "axios";
import Loader from "../tearSheet/loader";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../auth/authContext";
import LoginButton from "../auth/loginButton";
import DealCard from "../../pages/dealCard";
import CryptoJS from "crypto-js";
import useFetchInvestoirAction from "../../hooks/useFetchInvestorActions";
import { DEAL30, DEAL60, DEAL90, DEAL_30_60_90, GETARTS } from "../../constant/api";
import { getCurrentMonth, goToInvestorPage } from "../../constant/function";
const Deals = () => {
  const { getInvestor } = useFetchInvestoirAction();
  const [dealsData, setDealsData] = useState([]);
  const [articlesData, setArticlesData] = useState([]);
  const [clickedDealId, setClickedDealId] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const dispatch = useDispatch();
  const { user } = useAuth();

  const date = new Date();
  const currentMonthIndex = date.getMonth();
  const currentYear = date.getFullYear();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonth = months[currentMonthIndex];

  const [days, setDays] = useState("30");
  const handleDealClick = useCallback(
    (day) => {
      setDays(day);
    },
    [days]
  );

  const fetchDealsData = async (days) => {

    const options = {
      url: `${DEAL_30_60_90}${days}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/txt;charset=UTF-8",
      },
      data: {},
    };
    try {
      setPageLoading(true);
      const response = await axios(options);
      const dealsData = response.data[0];

      const sortedData = dealsData?.slice().sort((a, b) => {
        const lastDateA = a.Deal_Date ? a.Deal_Date : null;
        const lastDateB = b.Deal_Date ? b.Deal_Date : null;

        if (lastDateA !== null && lastDateB !== null) {
          return lastDateB - lastDateA; // Sort in descending order based on epoch time
        } else if (lastDateA === null && lastDateB !== null) {
          return 1; // Put empty or non-existent dates at the end
        } else if (lastDateA !== null && lastDateB === null) {
          return -1; // Put empty or non-existent dates at the end
        }
        return 0;
      });
      setSortedData(sortedData);
      setPageLoading(false);
    } catch {}
  };

  //FETCHING SOURCES
  const fetchDealNews = async (artIds) => {
    try {
      setArticlesData(null);
      setLoading(true);
      const responses = [];
      for (const artId of artIds) {
        const getArtsOptions = {
          url: `${GETARTS}=${artId}`,
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/txt;charset=UTF-8",
          },
          // params: { input: artId },
          data: {},
        };
        const response = await axios(getArtsOptions);
        responses.push(response.data);
      }
      setArticlesData(responses);
      setLoading(false);
    } catch {
      setLoading(false);
      return null;
    }
  };

  useEffect(() => {
    // only 30, 60, 90
    if (days === "30") {
      fetchDealsData(30);
    } else if (days === "60") {
      fetchDealsData(60);
    } else if (days === "90") {
      fetchDealsData(90);
    }
  }, [days]);

  const handleSectorClick = async (sector) => {
    // actions.getInvestor(investor);
    if (sector !== "NULL" && sector !== "NUll") {
      window.location.href = `/sector-intelligence/sector/${sector}`;
    } else {
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);
    }
  };

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const handleInvestorClick = async (investor) => {
    getInvestor(investor);
    // actions.getInvestor(investor);
    if (investor !== "NULL" && investor !== "NUll") {
      // window.location.href = `/investor-intelligence/investor/${investor}`;
      goToInvestorPage();
    } else {
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);
    }
  };

  const [confirmed, setConfirmed] = useState("Confirmed");
  // HANDLE SECTION CLICK
  const handleSectionClick = useCallback(
    (deal) => {
      setConfirmed(deal);
    },
    [confirmed]
  );

  return (
    <div className="lg:pt-0 px-4 sm:pt-10 sm:w-full lg:w-full flex flex-col">
      {user.isAuthenticated == true ? (
        <div className="flex flex-col w-full justify-between gap-5">
          <div className="flex items-center justify-between">
            <p className="font-medium text-[25px] tracking-tight leading-9">
              Deals in News
            </p>
          </div>

          {/* Confirmed and unconfirmed */}
          <div className="flex lg:flex-row sm:flex-col gap-5">
            <div className="flex gap-2 bg-white p-2 w-fit rounded-full shadow-lg">
              <p
                className={`rounded-full px-3 py-2 cursor-pointer ${
                  confirmed === "Confirmed" ? "bg-primaryBlue text-white" : ""
                }`}
                onClick={() => handleSectionClick("Confirmed")}
              >
                Confirmed
              </p>
              <p
                className={`rounded-full px-3 py-2 cursor-pointer ${
                  confirmed === "Unconfirmed" ? "bg-primaryBlue text-white" : ""
                }`}
                onClick={() => handleSectionClick("Unconfirmed")}
              >
                Signals
              </p>
            </div>

            <div className="flex gap-2 bg-white p-2 w-fit rounded-full shadow-lg">
              <p
                className={`rounded-full px-3 py-2 cursor-pointer ${
                  days === "30" ? "bg-primaryBlue text-white" : ""
                }`}
                onClick={() => handleDealClick("30")}
              >
                30 days
              </p>
              <p
                className={`rounded-full px-3 py-2 cursor-pointer ${
                  days === "60" ? "bg-primaryBlue text-white" : ""
                }`}
                onClick={() => handleDealClick("60")}
              >
                60 days
              </p>
              <p
                className={`rounded-full px-3 py-2 cursor-pointer ${
                  days === "90" ? "bg-primaryBlue text-white" : ""
                }`}
                onClick={() => handleDealClick("90")}
              >
                90 days
              </p>
            </div>
          </div>
          {/* CONFIRMED CARDS */}

          {isPageLoading && (
            <div className="w-full flex h-screen justify-center">
              <div className="fixed h-full">
                <Loader />
              </div>
            </div>
          )}
          <div
            className={`flex gap-5 flex-wrap w-full transition-all duration-300 delay-150 ease-linear deal ${
              confirmed === "Confirmed" ? "active" : "hidden"
            }`}
          >
            <DealCard sortedData={sortedData} />
          </div>

          {/* SIGNAL DEALS */}
          <div
            className={`flex gap-5 flex-wrap w-full transition-all duration-300 delay-150 ease-linear deal ${
              confirmed === "Unconfirmed" ? "active" : "hidden"
            }`}
          >
            {sortedData &&
              sortedData
                .filter((deal) => {
                  const currentOrPreviousMonth = getCurrentMonth(
                    deal.Deal_Date
                  );
                  return (
                    deal.Status === "Signal" &&
                    (currentOrPreviousMonth === "Future Date" ||
                      (currentOrPreviousMonth &&
                        currentMonth === currentOrPreviousMonth.month &&
                        [currentYear].includes(currentOrPreviousMonth.year)))
                  );
                })
                .map((deal, index) => (
                  <div
                    key={index}
                    className="bg-white flex lg:flex-row sm:flex-col rounded-lg transition-all duration-300 delay-150 ease-linear"
                    style={{
                      boxShadow:
                        "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
                    }}
                  >
                    <div className="lg:w-[400px] sm:w-80 sm:h-96 lg:h-[400px] gap-2 flex flex-col justify-between px-2 pt-2 transition-all duration-300 delay-150 ease-linear">
                      <div>
                        <div className="flex p-2 items-center gap-2">
                          <img className="w-6 h-6" src={factacy} />
                          <a
                            rel="noreferrer"
                            target="_blank"
                            className="font-semibold lg:text-xl sm:text-lg  text-primaryBlue"
                          >
                            {deal.Corrected_Investee}
                          </a>
                        </div>
                        <div className="flex justify-between">
                          <div className="flex flex-col ">
                            <div className="p-2">
                              <p className="text-sm text-gray-700">Round</p>
                              <p className="font-bold sm:text-base lg:text-xl">
                                {deal.Corrected_Series.toUpperCase()}
                              </p>
                            </div>
                            <div className="p-2">
                              <p className="text-sm text-gray-700">Raised</p>
                              <p className="font-bold sm:text-base lg:text-xl">
                                {deal.Corrected_Amount}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col">
                            {/* <div className='p-2'>
                                    <p className='text-sm text-gray-700'>Valuation</p>
                                    <p className='font-bold text-2xl'>-</p>
                                </div> */}
                            <div className="p-2">
                              <p className="text-sm text-gray-700">Sector</p>
                              {deal.Sector_Classification ? (
                                deal.Sector_Classification.map(
                                  (ar1, indexo) => (
                                    <span
                                      className={`cursor-pointer hover:underline hover:text-primaryBlue ${
                                        indexo < 2
                                          ? "font-bold text-base"
                                          : "font-semibold text-sm"
                                      }`}
                                      key={indexo}
                                      onClick={() => handleSectorClick(ar1[0])}
                                      // style={{ paddingLeft: `${index * 8}px` }}
                                    >
                                      {ar1 != null && ar1[1].toUpperCase()}
                                      {indexo === 1 &&
                                      deal.Sector_Classification.length > 2 ? (
                                        <p
                                          style={{ textDecoration: "none" }}
                                          className="hover:no-underline text-gray-700 font-normal text-sm pt-2"
                                        >
                                          Segments
                                        </p>
                                      ) : indexo !==
                                        deal.Sector_Classification?.length -
                                          1 ? (
                                        indexo < 1 ? (
                                          <br />
                                        ) : (
                                          <>, </>
                                        )
                                      ) : null}
                                    </span>
                                  )
                                )
                              ) : (
                                <p className="font-bold sm:text-base lg:text-base">
                                  {deal.Sector
                                    ? deal.Sector.toUpperCase()
                                    : "-"}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="p-2">
                          <p className="text-sm text-gray-700">Investors</p>
                          <div className="font-semibold text-base flex flex-col h-32 overflow-y-auto gap-5">
                            <p>
                              {deal.Corrected_Investors !== null &&
                              typeof deal.Corrected_Investors !== String &&
                              deal.Corrected_Investors != [] &&
                              deal.Corrected_Investors.length > 0
                                ? deal.Corrected_Investors.map((ar1, i) => (
                                    <span
                                      className={`cursor-pointer hover:underline sm:text-sm lg:text-base sm:font-semibold hover:text-primaryBlue`}
                                      key={i}
                                      onClick={() =>
                                        handleInvestorClick(ar1[0])
                                      }
                                    >
                                      {ar1[1]}
                                      {i !==
                                      deal.Corrected_Investors.length - 1 ? (
                                        <br />
                                      ) : null}
                                    </span>
                                  ))
                                : deal.Corrected_Investors}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="px-2 pt-2 flex items-end justify-between border-t-2">
                        <p className="text-xs text-gray-600 font-semibold">
                          {deal.Deal_Date &&
                          getCurrentMonth(deal.Deal_Date)
                            ? `${getCurrentMonth(deal.Deal_Date).month} ${
                                getCurrentMonth(deal.Deal_Date).year
                              }`
                            : "Date Not Available"}
                        </p>

                        <img
                          onClick={() => {
                            if (clickedDealId !== deal.Art_Id) {
                              {
                                fetchDealNews(deal.Art_Id);
                                setClickedDealId(deal.Art_Id);
                              }
                            } else {
                              setClickedDealId(null);
                            }
                          }}
                          src={source1}
                          className="w-5 h-5 -rotate-90 cursor-pointer"
                        />
                      </div>
                    </div>

                    <div
                      className="transition-all duration-300 lg:h-[400px] sm:h-max delay-150 mb-5 ease-linear"
                      id="scroller"
                    >
                      {clickedDealId === deal.Art_Id && (
                        <div className="lg:pl-3 sm:pl-0 mt-5  mx-4 lg:h-[380px] sm:h-80 sm:w-72 lg:w-[390px] px-4 lg:mb-4 sm:mb-1 flex flex-col overflow-y-auto">
                          <div className="my-3">
                            <p className="text-primaryBlue">Vision</p>
                            <p className="text-sm italic">
                              "...
                              {deal.Corrected_Vision && deal.Corrected_Vision}
                              ..."
                            </p>
                          </div>

                          <p className="text-primaryBlue">Sources</p>
                          {isLoading && clickedDealId === deal.Art_Id && (
                            <div className="flex flex-col items-center justify-center h-full">
                              <Loader />
                            </div>
                          )}
                          {articlesData?.map((article, indexi) => (
                            <ul key={indexi}>
                              {article.map((art, i) => (
                                <li className="py-2" key={i}>
                                  <div className="flex flex-col gap-1">
                                    <div className="flex items-start gap-3">
                                      <img
                                        onError={(e) => {
                                          e.target.src = factacy;
                                          e.target.alt = "Alternative Image";
                                        }}
                                        src={art.image ? art.image : factacy}
                                        className="w-16 h-12"
                                      />
                                      <a
                                        className="text-primaryBlue text-sm underline font-semibold"
                                        href={art.url}
                                        target="_blank"
                                      >
                                        {art.headline && art.headline}
                                      </a>
                                    </div>
                                    <p className="text-sm">
                                      {art.summary_of_article &&
                                        art.summary_of_article}
                                    </p>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center flex-col gap-3 h-screen">
          Please login first <LoginButton className="w-5" />
        </div>
      )}
    </div>
  );
};
export default Deals;
