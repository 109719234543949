import React from "react";
import { useParams } from "react-router";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useState, useEffect } from "react";
import axios from "axios";
import { useDocumentTitle } from "@uidotdev/usehooks";
import factacy from "../../assets/factacy_logo.svg";
import redirect from "../../assets/redirect.png";
import Loader from "../tearSheet/loader";
import { useDispatch } from "react-redux";
import source1 from "../../assets/arrow-down-double-line.png";
import { useAuth } from "../auth/authContext";
import useFetchInvestorActions from "../../hooks/useFetchInvestorActions";
import { howOldTimeUpdater, getCurrentMonth, handleSectorClick, goToCompanyPage, goToInvestorPage } from "../../constant/function";
import { Sector_Pill } from "../../constant/Tiny_Components";
import { GETARTS, SINGLE_DEAL_BY_ID } from "../../constant/api";

const SingleDealPage = () => {
  const {getInvestor} = useFetchInvestorActions();
  const id = useParams();
  const [dealData, setDealData] = useState([]);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [isLoading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [articlesData, setArticlesData] = useState([]);
  const [clickedDealId, setClickedDealId] = useState(null);
  const [isSourceOpen, setSourceOpen] = useState(false);
  useDocumentTitle(
    `Factacy Insights | ${id.investee} raises ${id.round} with ${id.investor}`
  );

  useEffect(() => {
    const fetchSingleDeal = async () => {
      setLoading(true);
      const response = await axios.get(`${SINGLE_DEAL_BY_ID}${id.id}`);
      const deal = response.data;
      setDealData(deal);
      setLoading(false);
    };
    fetchSingleDeal();
  }, [id]);


  //FETCHING SOURCES
  const fetchDealNews = async (artIds) => {
    if (!isSourceOpen) {
      try {
        setArticlesData(null);
        setLoading(true);
        const responses = [];
        for (const artId of artIds) {
          const getArtsOptions = {
            url: `${GETARTS + artId}`,
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/txt;charset=UTF-8",
            },
            // params: { input: artId },
            data: {},
          };
          const response = await axios(getArtsOptions);
          responses.push(response.data);
        }
        setArticlesData(responses);
        setLoading(false);
      } catch {
        setLoading(false);
        return null;
      }
    }
  };


  const handleInvestorClick = async (investorId, investorName) => {
    // actions.getInvestor(investor);
    getInvestor(investorId);
    if (investorId !== "NULL" && investorId !== "NUll") {
      goToInvestorPage(investorName, investorId)
    } else {
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);
    }
  };

  // CAPITALIZE INITIAL CHARACTER
  function capitalizeFirstLetter(str) {
    return str
      ?.toLowerCase()
      ?.split(" ")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  }

  const handleSourceClick = () => {
    setSourceOpen(!isSourceOpen);
  };



  return (
    <div className="h-max flex flex-col gap-4 items-center justify-center w-full px-4 lg:py-20 sm:py-28">
      <div
        className="sm:relative lg:static lg:w-2/3 sm:w-full lg:mx-0 sm:p-3 flex flex-col sm:h-full lg:h-full gap-12 bg-white lg:p-5 rounded-lg items-start lg:justify-start sm:justify-end sm:bg-gradient-to-t from-white to-transparent z-0 news-container"
        style={{
          boxShadow:
            "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)",
        }}
      >
        {dealData &&
          dealData
            .filter(
              (deal) => ["Confirmed", "Updated" ,"Open Deal"].includes(deal.Status)
            )
            .map((deal, index) => (
              <div
                key={index}
                className="w-full bg-white flex lg:flex-col sm:flex-col"
              >
                <div
                  className="lg:w-full sm:w-full sm:h-max lg:h-max gap-2 flex flex-col rounded-lg justify-between px-2 py-2"
                  // style={{
                  //   boxShadow:
                  //     "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
                  // }}
                >
                  <div className="flex flex-col gap-4">
                    <div className="flex p-2 items-center gap-4">
                      <img className="w-10 h-10 object-contain" src={deal.Logo_Url ? deal.Logo_Url : factacy} />
                      <a
                        rel="noreferrer"
                        target="_blank"
                        className="font-semibold lg:text-xl sm:text-lg cursor-pointer hover:underline text-primaryBlue"
                        onClick={() => goToCompanyPage(deal.Reference_Id, [deal.Legal_Name], deal.Brand_Id, [deal.Corrected_Investee])}
                      >
                        {deal.Corrected_Investee}
                      </a>
                    </div>
                    {deal.Corrected_Vision != "-" && deal.Corrected_Vision != [] &&
                      deal.Corrected_Vision[0].length > 0 ? (
                      <div className="p-2">
                        <p className="text-sm text-gray-700">Vision</p>
                        <p className="text-base italic">
                          "...{deal.Corrected_Vision && deal.Corrected_Vision}
                          ..."
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="flex lg:flex-row sm:flex-col lg:items-start w-full gap-6">
                      <div className=" flex gap-6 flex-wrap ">
                        <div className="w-[8rem] lg:w-[10rem] p-6 bg-lightGreen rounded-xl">
                          <p className="text-sm text-center text-gray-700">Round</p>
                          <p className="font-bold text-center sm:text-base lg:text-base">
                            {deal.Corrected_Series.toUpperCase()}
                          </p>
                        </div>
                        <div className="w-[8rem] lg:w-[10rem] p-6 bg-lightPurple rounded-xl">
                          <p className="text-sm text-center text-gray-700">Raised</p>
                          <p className="font-bold text-center sm:text-base lg:text-base">
                            {deal.Corrected_Amount}
                          </p>
                        </div>
                        <div className="w-[8rem] lg:w-[10rem] p-6 bg-lightBlue rounded-xl">
                          <p className="text-sm text-center text-gray-700">Date</p>
                          <p className="font-bold text-center sm:text-base lg:text-base uppercase">
                            {deal.Unique_date_time &&
                              deal.Unique_date_time.length > 0 &&
                              getCurrentMonth(deal.Unique_date_time[deal.Unique_date_time.length - 1])
                              ? `${getCurrentMonth(deal.Unique_date_time[deal.Unique_date_time.length - 1])
                                .month
                              } ${getCurrentMonth(deal.Unique_date_time[deal.Unique_date_time.length - 1])
                                .year
                              }`
                              : "Date Not Available"}
                          </p>
                        </div>
                      </div>


                      <div className="flex-1 p-2">
                        <p className="text-sm text-gray-700">Investors</p>
                        <div className="font-semibold text-base flex flex-col gap-5">
                          <p>
                            {deal.Corrected_Investors !== null &&
                              typeof deal.Corrected_Investors !== String &&
                              deal.Corrected_Investors != [] &&
                              deal.Corrected_Investors?.length > 0
                              ? deal.Corrected_Investors.map((ar1, index1) => (
                                <span
                                  className={`cursor-pointer hover:underline sm:text-xs lg:text-base sm:font-bold hover:text-primaryBlue`}
                                  key={index1}
                                  onClick={() => handleInvestorClick(ar1[0], ar1[1])}
                                >
                                  {ar1[1]}
                                  {index1 !== deal.Corrected_Investors?.length - 1
                                    ? // <br />
                                    ", "
                                    : null}
                                </span>
                              ))
                              : deal.Corrected_Investors}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="w-2/3 flex self-center" />
                    <div className="flex gap-2 items-center overflow-x-auto">
                      <p className="text-sm text-gray-700">Sector</p>
                      <p className="flex gap-3 ">
                        {deal.Sector_Classification ? (
                          deal.Sector_Classification &&
                          deal.Sector_Classification.length > 0 &&
                          deal.Sector_Classification.slice(0, 2).map(
                            (ar1, indexo) => (
                              // <span
                              //   className={`cursor-pointer hover:underline hover:text-primaryBlue text-md font-semibold py-1 px-3 rounded-xl w-max bg-gray-100 border-[1px] border-gray-200`}
                              //   key={indexo}
                              //   onClick={() => handleSectorClick(ar1[0])}
                              
                              // >
                              //   {ar1 != null && ar1[1].toUpperCase()}
                              //   <br />
                              // </span>
                              <Sector_Pill sector={ar1} key={indexo} />
                            )
                          )
                        ) : (
                          <p className="font-bold sm:text-xs lg:text-xs">
                            {deal.Sector ? deal.Sector.toUpperCase() : "-"}
                          </p>
                        )}
                      </p>
                  </div>
                  {deal.Segment && (
                    <div className="flex items-center gap-2 overflow-x-auto">
                      <p className="text-sm text-gray-700">Segments</p>
                      {deal.Segment && (
                        <p className="cursor-pointer hover:underline hover:text-primaryBlue text-xs font-semibold py-1 px-3 rounded-xl w-max bg-gray-100 border-[1px] border-gray-200">
                          {deal.Segment && deal.Segment[0] !== "No" && (
                            <span className="w-full">{deal.Segment[0].toUpperCase()}</span>
                          )}
                        </p>
                      )}
                    </div>
                  )}
                </div>

                <div
                  className="transition-all duration-300 lg:h-full sm:h-max delay-150 ease-linear"
                  id="scroller"
                >
                  <div className="mt-5 lg:h-max sm:h-max w-full sm:gap-5 lg:gap-0 sm:mb-1 flex flex-col">
                    <div className="flex gap-2 items-center justify-between w-full">
                      <p className="text-primaryBlue">Sources</p>
                      <img
                        onClick={() => {
                          if (clickedDealId !== deal.Art_Id) {
                            {
                              fetchDealNews(deal.Art_Id);
                              setClickedDealId(deal.Art_Id);
                              handleSourceClick();
                            }
                          } else {
                            setClickedDealId(null);
                          }
                        }}
                        src={source1}
                        className={`w-7 h-7 cursor-pointer duration-300 transition-all ${isSourceOpen ? "rotate-0" : "-rotate-90"
                          } `}
                      />
                    </div>
                    {isSourceOpen &&
                      articlesData?.map((article, indexi) => (
                        <ul key={indexi}>
                          {article.map((art, i) => {

                            return (
                              <div
                                key={i}
                                className="sm:relative lg:static w-full lg:flex-row flex sm:flex-col sm:h-[600px] lg:h-max gap-6 bg-white lg:p-5 rounded-sm items-start  lg:justify-start sm:justify-end sm:bg-gradient-to-t from-white to-transparent z-10"
                              >
                                <div className="sm:absolute lg:static lg:w-44 lg:h-36 sm:w-fit sm:h-[400px] sm:p-0 lg:mb-0 sm:mb-[203px]">
                                  <img
                                    onError={(e) => {
                                      e.target.src = factacy;
                                      e.target.alt = "Alternative Image";
                                    }}
                                    src={art.image}
                                    className="lg:w-40 lg:h-36 sm:h-[500px] rounded-2xl object-cover "
                                  />
                                </div>
                                <div className="sm:absolute lg:static w-full flex flex-col gap-6 justify-between sm:rounded-2xl sm:px-4 sm:pb-4 sm:pt-20 lg:pb-0 sm:w-fit lg:px-0 lg:pt-0 lg:bg-none lg:w-full sm:bg-gradient-to-t from-white from-73% via-white via-70% to-transparent to-100%">
                                  <div className="flex flex-col gap-2">
                                    <div className="flex justify-between items-center">
                                      <h1
                                        className="lg:text-xl sm:text-lg font-bold"
                                        style={{
                                          filter: `${!isDesktop
                                            ? "drop-shadow(0 10px 8px rgb(255 255 255))"
                                            : ""
                                            }`,
                                        }}
                                      >
                                        {art.headline}
                                      </h1>
                                    </div>
                                    <div className="text-gray-600">
                                      <div className="lg:text-base sm:text-sm">
                                        {art.summary_of_article}
                                      </div>
                                    </div>
                                    <div className="flex items-center gap-1">
                                      <a
                                        className="text-primaryBlue text-sm"
                                        href={art.url}
                                        target="_blank"
                                      >
                                        Source
                                      </a>
                                      <img src={redirect} className="w-3 h-3" />
                                    </div>
                                  </div>
                                  <div className="flex justify-between items-center">
                                    <div>
                                      <p className="text-xs text-gray-500">
                                        {howOldTimeUpdater(art?.Unique_date_time)}
                                      </p>
                                    </div>
                                    {art.summary_IC ? (
                                      <p className="text-xs py-1 px-3 rounded-full bg-purple w-max">
                                        {" "}
                                        {art.summary_IC === "nan"
                                          ? "Others"
                                          : art.summary_IC}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </ul>
                      ))}
                  </div>
                  {isLoading && clickedDealId === deal.Art_Id && (
                    <div className="flex flex-col items-center justify-center h-full">
                      <Loader />
                    </div>
                  )}
                  {showErrorMessage && (
                    <div className="absolute bottom-0 right-0 lg:m-10 sm:m-4 lg:p-3 sm:p-2 lg:text-base sm:text-sm border-gray-200 border-2 bg-slate-100 shadow-lg rounded-lg font-semibold font-roboto">
                      We are expanding our coverage daily. Please check back
                      again later.
                    </div>
                  )}
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

// export default connect(null, { getInvestor })(SingleDealPage);
export default SingleDealPage;