import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/authContext';
import Loder from '../tearSheet/loader';
import LoginButton from '../auth/loginButton';
import useFetchSearchSuggestions from '../../hooks/Search/useFetchSearchSuggestions';
import Competitors from '../tearSheet/competitor';
import { falseValues } from '../../constant/constant';
import Indipendent_Competitor from '../tearSheet/Indipendent_Competitor';

const CompetitorsWatch = () => {

  const { user } = useAuth();
  const [input, setInput] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState([]);
  const [x, y, { companySuggestions }] = useFetchSearchSuggestions(input);
  // useEffect(() => {

  //   if (CIN) {
  //     const url = "https://factacyinsights.in/?input=" + CIN;
  //     (async () => {
  //       try {
  //         setLoading(true);
  //         const res = await fetch(url);
  //         if (res.ok) {
  //           const data = await res.json();
  //           setCompany(data);
  //           setLoading(false);
  //         }
  //       }
  //       catch (err) {
  //         setLoading(false);
  //       }
  //     })();
  //   }

  // }, [CIN]);

  return user.isAuthenticated ?
    (
      <div className='w-full px-4 pt-4 h-full '>
        <div className="flex sm:flex-col-reverse lg:flex-row gap-4 justify-between ">
          <div className='flex gap-2 flex-wrap items-center'>
            {/* {defaultDesignations?.slice(0, 3)?.map((item, index) => (<p key={index} className={` h-max px-4 py-2 rounded-full cursor-pointer border-2 ${item === filter ? " bg-primaryBlue text-white border-none" : " border-white text-gray-700"}`} onClick={() => setFilter(item)}>{item}</p>))} */}
          </div>
          <div >
            <div className=' relative'>
              <div className="flex justify-between gap-2 items-center rounded-xl bg-white px-4 py-1 h-fit">
                <svg
                  className=" cursor-pointer"
                  width="23"
                  height="21"
                  viewBox="0 0 27 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.5214 18.7084L26.0029 24.1899L24.1928 26L18.7113 20.5184C16.7404 22.0953 14.2408 23.0386 11.5222 23.0386C5.16358 23.0386 0.00292969 17.878 0.00292969 11.5193C0.00292969 5.16065 5.16358 0 11.5222 0C17.8809 0 23.0415 5.16065 23.0415 11.5193C23.0415 14.2379 22.0982 16.7374 20.5214 18.7084ZM17.9535 17.7587C19.5186 16.1457 20.4817 13.9455 20.4817 11.5193C20.4817 6.56921 16.4723 2.55985 11.5222 2.55985C6.57213 2.55985 2.56278 6.56921 2.56278 11.5193C2.56278 16.4694 6.57213 20.4788 11.5222 20.4788C13.9485 20.4788 16.1486 19.5156 17.7616 17.9505L17.9535 17.7587Z"
                    fill="#71717A"
                  />
                </svg>
                <input
                  placeholder="Enter company name here..."
                  className="p-2 rounded-md w-[22rem] appearance-none focus:outline-none "
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                />
              </div>

              {(<div className=' absolute top-full right-0 w-full'>
                {(() => {

                  const list = companySuggestions?.slice(0, 10);
                  return list?.length > 0 && input.length > 0 ?
                    (<ul className=' p-4 bg-white mt-2 rounded-md'>
                      {list?.map((item, key) => (
                        <li className=' p-2 hover:bg-gray-100 rounded-md cursor-pointer my-1'
                          key={key}
                          onClick={(e) => { { item.ID && setReferenceId(item.ID) } setInput('') }}
                        >
                          {item.ALIAS?.[0] && !falseValues.includes(item.ALIAS?.[0]) && (<><span className=' text-primaryBlue'>{item.ALIAS?.[0]?.toUpperCase()}</span> <span> - </span></>)}
                          {item.NAME[0] && !falseValues.includes(item.ALIAS?.[0]) && (<><span className=''>{item.NAME[0]?.toUpperCase()}</span></>)}
                        </li>))}
                    </ul>)
                    :
                    (<></>)
                })()}

              </div>)}

            </div>
          </div>
        </div>
        {
          // loading ?
          //   (<div className='w-full p-4 pt-0 h-full flex items-center justify-center'>
          //     <Loder />
          //   </div>)
          // :
          // company?.length > 0 && (<div className='flex gap-6 flex-wrap mt-6 bg-white rounded-xl p-4'><Competitors company={company} /></div>)
          referenceId && (<div className='flex gap-6 flex-wrap mt-6 p-4'>
            <div className=' rounded-xl overflow-hidden w-full'><Indipendent_Competitor referenceId={referenceId} /></div>
          </div>)
        }
      </div>
    )
    :
    (<div className='w-full p-4 pt-0 h-full  flex items-center justify-center'>
      <LoginButton />
    </div>)
}

export default CompetitorsWatch;