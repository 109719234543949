import React, { useState } from "react";
import Table1 from "./Table1";

const Balance_Sheet = ({ financialsData }) => {
  return (
    <div className={`w-full gap-4 flex flex-col }`}>
      <p className="text-2xl font-bold">Balance Sheet</p>
      <Table1 Data={financialsData?.[0].BALANCE_SHEET} chart="bar" />
    </div>
  );
};

export default Balance_Sheet;
