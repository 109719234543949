import React, { useEffect } from "react";
import Sidebar from "../sideBarMain";
import { useRef, useState } from "react";
import NewsRow from "./NewsRow";
import useMediaQuery from "../../hooks/useMediaQuery";
import LatestNews from "./latestNews";
import LatestDeals from "./latestDeals";
import LoginComponent from "../loginComponent";
import { useAuth } from "../auth/authContext";

const MyFeed = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const { user } = useAuth();

  const Tabs = {
    News: user?.isAuthenticated ? <LatestNews /> : <LoginComponent />,
    Deals: user?.isAuthenticated ? <LatestDeals /> : <LoginComponent />,
  };
  const [activeTab, setActiveTab] = useState(Object.keys(Tabs)[0]);

  return (
    <div className="lg:w-full flex flex-col">
      <div className="bg-gradient-to-b w-full flex h-screen">
        {/* <Sidebar /> */}
        <div className="w-full ">
          {isDesktop ? (
            user.isAuthenticated ? (
              <div className="flex  w-full px-3 gap-3 h-full pt-20">
                <LatestNews />
                <LatestDeals />
              </div>
            ) : (
              <div className="mt-24 mx-4 rounded-t-xl">
              <LoginComponent />
              </div>
              
            )
          ) : (
            <div>
              <div className="flex pt-20">
                {Object.keys(Tabs).map((item, index) => (
                  <div
                    key={index}
                    className={`bg-[#FAFAFA] flex w-full item px-4 py-4 ${
                      activeTab === item
                        ? "bg-white text-primaryBlue font-bold"
                        : "text-[#A1A1AA]"
                    } `}
                    onClick={() => setActiveTab(item)}
                  >
                    <div className="cursor-pointer flex">{item}</div>
                  </div>
                ))}
              </div>
              <div className=" w-full px-3 py-2 gap-3 h-full pb-20">
                {Tabs?.[activeTab]}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyFeed;
