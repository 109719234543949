import React, { useEffect, useRef, useState } from "react";
import Indi from "./Indi";
import Loader from "../tearSheet/loader";
import { REPORT_OFFSET } from "../../constant/api";

const Reports_Infinite = () => {
  const [needToLoadMore, setNeedToLoadMore] = useState(true);
  const trigerElement = useRef();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [existingMetaTags, setExistingMetaTags] = useState({
    description: document
      ?.querySelector('meta[name="description"]')
      ?.getAttribute("content"),
    keywords: document
      ?.querySelector('meta[name="keywords"]')
      ?.getAttribute("content"),
    title: document.getElementsByTagName("title")[0].innerText,
  });

  useEffect(() => {
    return () => {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", existingMetaTags.description);

      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", existingMetaTags.keywords);

      document.getElementsByTagName("title")[0].innerText =
        existingMetaTags.title;
    };
  }, []);
  useEffect(() => {
    const element = trigerElement.current;
    let status = "";
    const observer = new IntersectionObserver(
      (items, obs) => {
        if (!status && items[0].isIntersecting && reports.length < 10) {
          status = "loading";
          (async () => {
            try {
              setLoading(true);
              const res = await fetch( REPORT_OFFSET + `?OFFSET=${reports.length}&LIMIT=1`);
              if (res.ok) {
                const data = await res.json();
                if (data?.length > 0) {
                  setReports((pre) => [...pre, ...data]);
                  setLoading(false);
                } else {
                  setLoading(false);
                  setNeedToLoadMore(false);
                }
              } else {
                throw new Error("Somtning went wrong at backend");
              }
            } catch (err) {
              setLoading(false);
              setError(err);
            }
          })();
          obs.unobserve(items[0].target);
        }
      },
      {
        rootMargin: "250px",
      }
    );
    observer.observe(element);
  }, [reports]);
  return (
    <>
    <div className=" p-2 pt-12  flex flex-col gap-8 lg:gap-12 lg:px-6 lg:py-0">
      {reports.map((item, index) => (
        <Indi report={item} key={index} />
      ))}
    </div>
      
      {loading && (
        <div className=" w-full flex justify-center items-center">
          <Loader />
        </div>
      )}
      <div ref={trigerElement} className=" h-6"></div>
    </>
  );
};

export default Reports_Infinite;
// useEffect(() => {
//     // if (reportFrontendId) {
//     //     document.getElementById(reportFrontendId)?.scrollIntoView();
//     // }
// }, []);
