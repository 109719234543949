import React from 'react'
import { ResponsiveFunnel } from '@nivo/funnel'
import { ICdata as data } from '../../data/graphsData'


const Funnel = () => (
    <ResponsiveFunnel
        data={data}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        direction="vertical"
        valueFormat=" >-.3s"
        colors={{ scheme: 'greens' }}
        borderWidth={20}
        labelColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    3
                ]
            ]
        }}
        enableBeforeSeparators= {false}
        beforeSeparatorLength={20}
        beforeSeparatorOffset={20}
        afterSeparatorLength={20}
        enableAfterSeparators= {false}
        afterSeparatorOffset={20}
        currentPartSizeExtension={10}
        currentBorderWidth={40}
        motionConfig="wobbly"
    />
)

export default Funnel