import React, { useState } from "react";
import IndividualDealCard from "./IndividualDealCard";
import Loader from "../components/tearSheet/loader";

const DealCard = ({ sortedData, loading }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  return (
    <div className="flex gap-5 flex-wrap w-full transition-all duration-300 delay-150 ease-linear">
      {loading && <Loader />}
      {sortedData &&
        sortedData
          .filter(
            (deal) => deal.Status === "Confirmed" || deal.Status === "Updated"
          )
          .map((deal, index) => (
            <IndividualDealCard
            key={index}
              deal={deal}
              index={index}
              setShowErrorMessage={setShowErrorMessage}
            />
          ))}

      {showErrorMessage && (
        <div className="absolute bottom-0 right-0 lg:m-10 sm:m-4 lg:p-3 sm:p-2 lg:text-xs sm:text-xs border-blue border-2 bg-slate-100 rounded-lg font-semibold font-roboto">
          We are expanding our coverage daily. Please check back again later.
        </div>
      )}
    </div>
  );
};
export default DealCard