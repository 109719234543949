import factacy from "../assets/factacy_logo.svg";
import Loader from "../components/tearSheet/loader";
import redirect from "../assets/redirect.png";
import React, { useEffect, useState } from "react";
import source1 from "../assets/arrow-down-double-line.png";
import axios from "axios";
import { useDispatch } from "react-redux";
import info from "../assets/information.svg";
import { goToCompanyPage, goToInvestorPage } from "../constant/function";
import useFetchInvestorActions from "../hooks/useFetchInvestorActions";
import { getCurrentMonth } from "../constant/function";
import { IN } from "country-flag-icons/react/3x2";
import { Sector_Pill } from "../constant/Tiny_Components";
import { GETARTS } from "../constant/api";



const ValueCart = ({ artIds, deal }) => {
  const [isLoading, setLoading] = useState(false);
  const [articlesData, setArticlesData] = useState([]);

  useEffect(() => {

    (async () => {
      try {
        setArticlesData(null);
        setLoading(true);
        const responses = [];
        for (const artId of artIds) {
          const response = await axios(GETARTS + artId);
          responses.push(response.data);
        }
        setArticlesData(responses);
        setLoading(false);
      } catch {
        setLoading(false);
        return null;
      }
    })();
  }, []);


  return (<div
    className="transition-all duration-300 lg:h-[400px] sm:h-max delay-150 ease-linear"
    id="scroller"
  >

    <div className="flex flex-col justify-between h-full">
      <div className="lg:pl-3 lg:mt-4 lg:p-0 lg:mx-4 lg:h-[490px] lg:mb-5 sm:mb-1 sm:h-80 sm:w-full lg:px-4 sm:p-2 lg:w-[400px] flex flex-col overflow-y-auto">
        {deal.Corrected_Vision != "-" &&
          deal.Corrected_Vision[0]?.length > 0 &&
          deal.Corrected_Vision != [] ? (
          <div className="my-1">
            <p className="text-primaryBlue">Vision</p>
            <p className="text-xs italic">
              "...{deal.Corrected_Vision}..."
            </p>
          </div>
        ) : (
          ""
        )}

        <p className="text-primaryBlue">Sources</p>
        {isLoading && (
          <div className="flex flex-col items-center justify-center h-full">
            <Loader />
          </div>
        )}
        {articlesData?.map((article, indexi) => (
          <ul key={indexi}>
            {article.map((art, i) => (
              <li className="py-2" key={i}>
                <div className="flex flex-col gap-1">
                  <div className="flex items-start gap-3">
                    <img
                      onError={(e) => {
                        e.target.src = factacy;
                        e.target.alt = "Alternative Image";
                      }}
                      src={art.image ? art.image : factacy}
                      className="w-16 h-12"
                    />
                    <a
                      className="text-primaryBlue text-xs underline font-semibold"
                      href={art.url}
                      target="_blank"
                    >
                      {art.headline && art.headline}
                    </a>
                  </div>
                  {art?.Tagged_Org && (

                    <div className="flex gap-1 flex-wrap max-h-[4rem] overflow-auto">

                      <div className=" flex gap-2 flex-wrap">
                        {art?.Tagged_Org &&
                          Object.entries(art?.Tagged_Org)?.map(([key, value], index) => (
                            <p className="text-black text-[11px] font-semibold bg-blue py-px px-2 rounded-full cursor-pointer max-w-fit" onClick={() => goToCompanyPage(value[1], [value[2]], value[0], [key])}>{key} </p>
                          ))}
                      </div>

                    </div>
                  )}
                  <p className="text-xs">
                    {art.summary_of_article && art.summary_of_article}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  </div>);
}

const IndividualDealCard = ({ deal, index, setShowErrorMessage, showNews }) => {
  const [isValueOpend, setIsValueOpend] = useState(false);

  const { getInvestor } = useFetchInvestorActions();


  const handleInvestorClick = async (investor) => {
    getInvestor(investor);
    if (investor !== "NULL" && investor !== "NUll") {
      window.location.href = `/investor-intelligence/investor/${investor}`;
    } else {
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);
    }
  };

  const handleIdClick = (id, investee, series, investors) => {
    window.open(
      `/deal-intelligence/deal/${id}/${investee}-raises-/${series}-round-with-/${investors ? investors.map((investors) => investors[1]) : investors
      }`
    );
  };

  return (
    <div
      key={index}
      className="bg-white flex lg:w-max sm:w-full lg:flex-row lg:h-[400px] sm:h-max flex-col rounded-lg px-2"
      style={{
        boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
      }}
    >
      <div className="lg:w-[400px] sm:w-full lg:max-h-[400px] sm:max-h-[450px] h-full flex flex-col justify-between p-2 transition-all duration-300 delay-150 ease-linear">
        <div className="flex flex-col gap-2">
          <div className="flex py-2 items-center justify-between">
            <div className="flex gap-3 items-center">
              <div className="rounded-lg border-[1px] flex items-center p-1 w-12 h-12">
                <img
                  className="w-12 h-12 rounded-lg object-contain"
                  src={deal.Logo_Url ? deal.Logo_Url : factacy}
                  alt="logo"
                />
              </div>
              <div className="flex flex-col gap-0">
                <a
                  rel="noreferrer"
                  target="_blank"
                  className="font-semibold lg:text-lg sm:text-xs cursor-pointer hover:underline text-primaryBlue"
                  onClick={() =>
                    goToCompanyPage(deal["Reference_Id"], [deal["Legal_Name"]], deal["Brand_Id"], [deal["Corrected_Investee"]])
                  }
                >
                  {deal.Corrected_Investee
                    ? deal.Corrected_Investee
                    : deal.Corrected_Investee}
                </a>
                {/* <p className="text-xs text-gray-600">Bengaluru</p> */}
                {deal.Entity_Type === "Indian Company" && (
                  <IN className="w-4 h-4" />
                )}
              </div>
            </div>
            <img
              src={redirect}
              className="w-4 h-4 cursor-pointer"
              onClick={() =>
                handleIdClick(
                  deal.id,
                  deal.Corrected_Investee,
                  deal.Corrected_Series,
                  deal.Corrected_Investors
                )
              }
            />
          </div>

          <hr />
          <div className="flex flex-col gap-8 pt-2">
            <div className="flex gap-4">
              <div className="p-3 w-1/3 rounded-xl shadow bg-lightGreen">
                <p className="text-xs text-gray-700">Round</p>
                <p className="font-bold sm:text-xs lg:text-xs">
                  {deal.Corrected_Series
                    ? deal.Corrected_Series?.toUpperCase()
                    : deal.Corrected_Series}
                </p>
              </div>
              <div className="p-3 w-1/3 rounded-xl shadow bg-lightPurple">
                <p className="text-xs text-gray-700">Raised</p>
                <p className="font-bold sm:text-xs lg:text-xs">
                  {deal.Corrected_Amount
                    ? deal.Corrected_Amount
                    : deal.Corrected_Amount}
                </p>
              </div>
              <div className="p-3 w-1/3 rounded-xl shadow bg-lightBlue">
                <p className="text-xs text-gray-700">Date</p>
                <p className="font-bold sm:text-xs lg:text-xs">
                  {deal.Deal_Date && getCurrentMonth(deal.Deal_Date)
                    ? `${getCurrentMonth(deal.Deal_Date).month} ${getCurrentMonth(deal.Deal_Date).year
                    }`
                    : "Date Not Available"}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2 items-center overflow-x-auto">
                <p className="text-xs text-gray-700">Sector</p>
                <p className="flex gap-3 ">
                  {deal.Sector_Classification ? (
                    deal.Sector_Classification &&
                    deal.Sector_Classification.length > 0 &&
                    deal.Sector_Classification.slice(0, 2).map(
                      (ar1, indexo) => (
                        // <span
                        //   className={`cursor-pointer hover:underline hover:text-primaryBlue text-xs font-semibold py-1 px-3 rounded-xl w-max bg-gray-100 border-[1px] border-gray-200`}
                        //   key={indexo}
                        //   onClick={() => handleSectorClick(ar1[0])}
                        // >
                        //   {ar1 != null && ar1[1].toUpperCase()}
                        //   <br />
                        // </span>
                        <Sector_Pill key={indexo} sector={ar1} />
                      )
                    )
                  ) : (
                    <p className="font-bold sm:text-xs lg:text-xs">
                      {deal.Sector ? deal.Sector.toUpperCase() : <span className="text-gray-500 font-normal">Updating...</span>}
                    </p>
                  )}
                </p>
              </div>

              {deal.Segment && (
                <div className="flex items-center gap-2 overflow-x-auto">
                  <p className="text-xs text-gray-700">Segments</p>
                  {deal.Segment && (
                    <p className="text-xs font-semibold  flex w-max gap-2">
                      {deal.Segment &&
                        deal.Segment[0] !== "No" &&
                        deal.Segment[0]?.split(",").map((segg, index) => (
                          <span
                            key={index}
                            className="w-max uppercase py-1 px-3 rounded-xl bg-gray-100 border-[1px] border-gray-200"
                          >
                            {segg}
                          </span>
                        ))}
                    </p>
                  )}
                </div>
              )}

              {deal.Tags && deal.Tags[0] !== "No" && (
                <div className="flex items-center gap-2 overflow-x-auto">
                  <p className="text-xs text-gray-700">Tags</p>
                  {deal.Tags[0] && (
                    <p className="text-xs font-semibold w-max flex gap-2">
                      {deal.Tags &&
                        deal.Tags[0]?.split(",")?.map((tagg, index) => (
                          <span
                            key={index}
                            className="w-max uppercase py-1 px-3 rounded-xl bg-gray-100 border-[1px] border-gray-200"
                          >
                            {tagg}
                          </span>
                        ))}
                      {/* deal.Tags[0] !== "No" &&
                        deal.Tags[0] */}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="gap-2 flex pt-2">
            <p className="text-xs text-gray-700">Investors</p>
            <div className="font-semibold text-xs flex flex-col h-12 w-full overflow-y-auto gap-5">
              <p>
                {deal.Corrected_Investors !== null &&
                  typeof deal.Corrected_Investors !== "string" &&
                  deal.Corrected_Investors != [] &&
                  deal.Corrected_Investors?.length > 0
                  ? deal.Corrected_Investors.map((ar1, index1) => (
                    <span
                      className={`cursor-pointer hover:underline sm:text-xs lg:text-xs sm:font-semibold hover:text-primaryBlue`}
                      key={index1}
                      onClick={() => goToInvestorPage(ar1[1], ar1[0])}
                    >
                      {ar1[1]}
                      {index1 !== deal.Corrected_Investors?.length - 1
                        ? // <br />
                        ", "
                        : null}
                    </span>
                  ))
                  : deal.Corrected_Investors}
              </p>
            </div>
          </div>
        </div>

        <div className="p-1 flex items-end justify-between border-t-2">
          <div className="flex gap-2">
            {deal.Status === "Updated" ? (
              <div
                title="New Sources Added"
                className="text-xs flex gap-1 items-center group relative"
              >
                <p>(UPDATED)</p>

                <img src={info} className="w-4 h-4 cursor-pointer" />
              </div>
            ) : (
              ""
            )}
          </div>

          {!showNews && (
            <>
              <img
                onClick={() => { setIsValueOpend(pre => !pre) }}
                src={source1}
                className={`lg:w-5 lg:h-5 sm:w-4 sm:h-4 cursor-pointer ${isValueOpend ? '-rotate-90' : 'rotate-90'}`}
              />
            </>
          )}
        </div>
      </div>

      {isValueOpend && (<ValueCart
        artIds={deal.Art_Id}
        deal={deal}
      />)}
    </div>
  );
};

export default IndividualDealCard;