import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ADD_INDIVIDUAL_NEWS } from '../../store/slices/news_intelligence';
import { GETARTS } from '../../constant/api';

const useFetchIndividualNews = (Art_Id) => {

    const dispatch = useDispatch();

    const isExist = useSelector(store => store?.news_intelligence?.news[Art_Id]);
    useEffect(() => {
        (
            async () => {

                if (!isExist && Art_Id) {
                    try {
                        const res = await fetch(GETARTS + Art_Id);
                        const data = await res.json();

                        dispatch(ADD_INDIVIDUAL_NEWS({ Art_Id, news: data[0] }))
                    }
                    catch (error) {
                    }
                }
            }
        )();
    }, []);
}

export default useFetchIndividualNews;

