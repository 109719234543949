import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_ESG_NEWS } from '../../store/slices/news_intelligence';
import { ESG_NEWS } from '../../constant/api';

const useFetchEsgNews = () => {
    const dispatch = useDispatch();
    const esgNewsAlreadyExists = useSelector(store => store?.news_intelligence?.esgNews?.length > 0 ? true : false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const esgNewsFetcher = async () => {
        if (!esgNewsAlreadyExists) {
            try {
                setLoading(true);
                const res = await fetch(ESG_NEWS);
                const data = await res.json();
                if (res.ok) {
                    const newslist = data;
                    dispatch(ADD_ESG_NEWS({ newslist }));
                    setLoading(false)
                }
                else {
                    setLoading(false)
                    setError("Somthing Went wrong");
                }

            }
            catch (err) {
                setLoading(false)
                setError("Somthing Went wrong");
            }
        }

    }
    return [loading, error, esgNewsFetcher];
}

export default useFetchEsgNews;