import React, { useState, useEffect, useRef } from "react";
import { useChat } from "./ChatContext";
import close from "../../assets/close-icon.svg";
import useHistoryFetch from "../../hooks/ChatWithGroot/useHistoryFetch";
import { chatWithGrootHistoryList } from "../../utils/keyConstants";
import AskQuestion from "./AskQuestion";
import useQuery from "../../hooks/ChatWithGroot/useQuery";

const AnswerComponent = ({ text }) => {

    const filtered = text?.split("\n")?.filter(item => item ? true : false);
    return (<div className=" flex justify-start"><div className="  my-4 p-2 border-[1px] rounded-lg border-primaryBlue bg-[#daeeff] max-w-[80%]">
        {
            filtered?.map((item, index) => (<p className=" my-4" key={index}>{item} <br/></p>))
        }
    </div>
    </div>)
}
const QuestionComponent = ({ text }) => (<div className=" flex justify-end"><p className=" my-4 mr-1  p-2 border-[1px] rounded-lg border-primaryBlue max-w-[80%]">{text}</p></div>)

const ChatBox = () => {

    const [scrolledPx, setScrollPx] = useState(window.scrollY);
    const { showChatWithGroot, closeChat } = useChat();
    const [historyLoading, historyError, firstHistoryGetter, historyGetter] = useHistoryFetch();
    const history = JSON.parse(window.localStorage.getItem(chatWithGrootHistoryList));
    const [questionloading, questionError, questionGetter] = useQuery();
    const divRef = useRef();


    // window.localStorage.setItem(chatWithGrootHistoryList, JSON.stringify(new Array(1).fill({
    //   question: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Est possimus facilis ut!?',
    //   answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem exercitationem vel labore? Libero consequuntur odit maiores inventore aspernatur accusamus unde distinctio tenetur amet. Soluta voluptatibus molestiae doloribus natus, repellat maxime, repellendus ad exercitationem laborum ex fugit, corrupti explicabo sit obcaecati.'
    // })));

    useEffect(() => {
        firstHistoryGetter();
    }, [])

    useEffect(() => {
        const scrollHeight = divRef?.current?.scrollHeight

        divRef?.current?.scrollTo({
            top: scrollHeight,
            left: 0,
            behavior: "smooth",
        });
    }, [questionloading]);

    return (
        <div
            className={`flex h-full relative flex-col items-center justify-center bg-white shadow-2xl px-5 pt-4 pb-16 rounded-lg`}>
            <div className="flex justify-between w-full">
                <p className="font-bold text-lg">Ask FactacyInsights about Latest Deals and Companies.</p>
                <img 
                    className=" cursor-pointer w-5"
                    src={close}
                    onClick={closeChat}
                />
            </div>
            <div
                ref={divRef}
                className="overflow-y-auto h-full py-6">
                {history?.map((item, index) => (<React.Fragment key={index}>
                    <QuestionComponent text={item.question} />
                    <AnswerComponent text={item.answer} />
                </React.Fragment>))}
                {
                    questionloading && (<div className=" flex justify-center"><span>loading...</span></div>)
                }
            </div>
            <AskQuestion loading={questionloading} error={questionError} getter={questionGetter} />
        </div>
    )
}

export default ChatBox

// ${showChatWithGroot ? 'translate-x-0' : 'translate-x-full'} transition-all 