import React, { useEffect, useState } from 'react';
import chat from "../../assets/ai-chat.png"
import useQuery from '../../hooks/ChatWithGroot/useQuery';

const AskQuestion = ({ getter, loading, error }) => {
    const [question, setQuestion] = useState('');
    useEffect(()=>{
        console.log('Question')
        setQuestion('');
    }, [loading])
    return (
        <form
            onSubmit={(e) => { e.preventDefault(); getter(question); }}
            className=" order-last absolute flex border-2 rounded-full bottom-0 w-2/3 mb-2 gap-2">
            <input
                className="appearance-none focus:outline-none w-full  rounded-full bg-white py-2 px-4"
                placeholder="Chat with Groot"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
            />
            <button
                onClick={() => getter(question)}
            >
                <img src={chat} className="w-6 mr-3" />
            </button>
        </form>
    )
}

export default AskQuestion;