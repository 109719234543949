import { useState } from "react"
import React from 'react'
import Loader from '../tearSheet/loader';
import SectorsSearch from "./components/SectorsSearch"
import useFetchSectorNews from "../../hooks/News/useFetchSectorNews"
import { useSelector } from "react-redux";
import IndividualNews from "./components/IndividualNews"

const SectorsInNews = () => {
  const increment = 10;
  const [activeSector, setActiveSector] = useState('');
  const fullSectorNews = useSelector(store => store?.news_intelligence?.sectorsNews?.[activeSector]);
  const [displayCount, setDisplayCount] = useState(10)
  const [loading, error] = useFetchSectorNews(activeSector);

  return (
    <div className='lg:pt-0 px-4 sm:pt-20 sm:w-screen lg:w-full flex flex-col'>
      <div className='flex flex-col w-full bg-[#F4F5F7] rounded-xl lg:px-5 sm:px-4 lg:py-3 sm:py-3 justify-between gap-5'>
        <p className='font-medium text-[25px] tracking-tight leading-9'>Sectors in News</p>

        {/* SEARCH BAR */}
        <SectorsSearch activeSector={activeSector} setActiveSector={setActiveSector} />
        {/* LOADER */}
        {loading && <div className='w-full flex h-screen justify-center'>
          <div className='fixed h-full'>
            <Loader />
          </div>
        </div>}
        {fullSectorNews?.slice(0, displayCount)?.map(item => (<IndividualNews Art_Id={item} key={item} />))}
        <div className='flex justify-center p-3'>
          {displayCount < fullSectorNews?.length && (
            <button
              className='text-sm hover:text-white p-2 hover:bg-primaryBlue flex gap-1 items-center bg-white border-[1px] text-primaryBlue border-primaryBlue'
              style={{ boxShadow: "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)" }}
              onClick={() => setDisplayCount(pre => pre + 10)}
            >
              <p>Load More</p>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
export default SectorsInNews;