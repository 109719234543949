import React from "react";
import StepIndicator from "../../pages/stepIndicator";
import Loader from "./loader";

const RequestAiProfile = ({
  isOpen,
  onClose,
  fetchAiProfileData,
  company,
  requestedProfileData,
  statusLoading,
}) => {
  return (
    <>
      {/* Overlay */}
      {isOpen && <div className="fixed inset-0 bg-black opacity-50 z-50"></div>}

      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white w-[500px] h-max p-6 rounded-lg shadow-lg transition-all duration-300">
            <div className="flex justify-end">
              <button
                className="text-gray-600 hover:text-gray-800"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-col gap-2 h-full">
              {statusLoading ? (
                <div className="w-[400px] h-[483px] flex items-center justify-center">
                  <Loader />{" "}
                </div>
              ) : (
                <StepIndicator
                  data={requestedProfileData}
                  statusLoading={statusLoading}
                />
              )}

              {/* button */}

              <button
                className="flex items-center gap-1 p-2 rounded-lg bg-primaryBlue w-max text-white shadow-lg"
                onClick={() => fetchAiProfileData(company.MCA_CIN)}
              >
                <p className="">Reload Status</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path d="M5.46257 4.43262C7.21556 2.91688 9.5007 2 12 2C17.5228 2 22 6.47715 22 12C22 14.1361 21.3302 16.1158 20.1892 17.7406L17 12H20C20 7.58172 16.4183 4 12 4C9.84982 4 7.89777 4.84827 6.46023 6.22842L5.46257 4.43262ZM18.5374 19.5674C16.7844 21.0831 14.4993 22 12 22C6.47715 22 2 17.5228 2 12C2 9.86386 2.66979 7.88416 3.8108 6.25944L7 12H4C4 16.4183 7.58172 20 12 20C14.1502 20 16.1022 19.1517 17.5398 17.7716L18.5374 19.5674Z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RequestAiProfile;
