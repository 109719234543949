import { useEffect, useState } from "react";
import { GET_PERSON_COMPANY } from "../Queries/companyQueries";
import { logDOM } from "@testing-library/react";
const useFetchPersonCompany = (companyId) => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {

        if (companyId) {
            (async () => {
                try {
                    setLoading(true)
                    const res = await fetch("https://factacy.io/graphql", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "api-key": "1234",
                        },
                        body: JSON.stringify({
                            query: GET_PERSON_COMPANY.loc.source.body,
                            variables: { id: companyId },
                        }),
                    });
                    const data = await res.json();
                    setData(data);
                    setLoading(false);
                }
                catch (err) {
                    setLoading(false);
                }
            })();
        }

    }, [companyId]);

    return [loading, error, data];
}
export default useFetchPersonCompany;