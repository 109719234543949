import React, { useState } from "react";

const Table = ({ data, text }) => {
  const headers = text === "Shareholders"
  ? data && Object?.values(data)?.[0] && Object?.values(Object?.values(data)?.[0])?.[0]
    ? Object.keys(Object.values(Object.values(data)?.[0])?.[0])
    : []
  : data && Object?.values(data)?.[0]
    ? Object.keys(Object.values(data)?.[0])
    : [];

  return (
    <table className="w-full border sm:text-sm overflow-y-auto">
      <thead>
        <tr>
          <th className="p-4 border-b text-start">Particulars (Rs.)</th>
          {headers?.map((key, i) => (
            <th className="p-4 border-b text-start" key={i}>
              {key}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {text === "Shareholders" ? (
          <>
            {data && Object?.keys(data)?.map((category, i) => (
              <>
                <tr key={i}>
                  <td colSpan={3} className="font-bold p-4 border-b">
                    {category ?? "-"}
                  </td>
                </tr>

                {Object?.entries(data?.[category])?.map(([name, details], j) => (
              <tr key={j}>
                <td className="p-4 border-b">{name ?? "-"}</td>
                <td className="p-4 border-b">{details?.["shares held"] ?? "-"}</td>
                <td className="p-4 border-b">{details?.["shareholding%"]?.toFixed(3) ?? "-"}</td>
              </tr>
            ))}
              </>
            ))}
          </>
        ) : (
          <>
            {data && Object?.keys(data)?.map((category, i) => (
              <tr key={i}>
                <td className="p-4 border-b">{category}</td>
                {headers?.map((header, j) => (
                  <td className="p-4 border-b" key={j}>
                    {data?.[category]?.[header] ?? "-"}
                  </td>
                ))}
              </tr>
            ))}{" "}
          </>
        )}
      </tbody>
    </table>
  );
};

const Table2 = ({ Data, text }) => {
  
  const keys = Object?.keys(Data);
  const [activeYear, setActiveYear] = useState(keys?.[keys?.length - 1]);

  const Tabs = keys?.reduce((acc, item) => {
    acc[item] = <Table data={Data?.[item]} />;
    return acc;
  }, {});

  return (
    <div className="gap-4 flex flex-col overflow-x-auto">
      <p className="text-2xl font-bold">{text}</p>

      <div className="bg-[#E8F5FF] rounded-full flex w-max lg:gap-4 font-roboto p-2">
        {Object?.keys(Tabs)?.map((item, i) => (
          <button
            onClick={() => setActiveYear(item)}
            key={i}
            className={`px-3 py-2 lg:text-xs sm:text-[10px] ${
              activeYear === item
                ? "active bg-white rounded-full text-primaryBlue"
                : ""
            }`}
          >
            {item?.replace(/FY_/g, "20")?.replace(/_/g, "-20")}
          </button>
        ))}
      </div>

      <div className="w-full">
        <Table data={Data?.[activeYear]} text={text} />
      </div>
    </div>
  );
};

export default Table2;
