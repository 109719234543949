import React from "react";
import { useAuth } from "../auth/authContext";
import LoginButton from "../auth/loginButton";

const SectorDash = () => {
  const { user } = useAuth();

  return (
    <div className="lg:pt-0 px-4 sm:pt-10 sm:w-full lg:w-full flex flex-col">
      <div className="flex flex-col w-full bg-[#F4F5F7] rounded-xl lg:px-5 sm:px-4 lg:py-3 sm:py-3 justify-between gap-5">
      {user.isAuthenticated == true ? (
<>
        <div className="flex items-center justify-between">
          <p className="font-medium text-[25px] tracking-tight leading-9">
            Sector Dashboard
          </p>
        </div>
        <iframe
          style={{
            clipPath:
              "polygon(0% 0%, 100% 0%, 100% calc(100% - 36px), 0% calc(100% - 36px))",
          }}
          title="Sector Leaderboard"
          className="w-full h-[800px]"
          src="https://app.powerbi.com/view?r=eyJrIjoiMzQ1OThlNjQtNmYyOS00MzhjLTgyOGYtMzg0YmFjYzAwMzI3IiwidCI6IjNmNGVmZDgwLTUzYjctNDc4MS04NDZhLTU2YTYyMzRiMTY0ZiJ9"

          frameborder="0"
          allowFullScreen="true"
        ></iframe>
        </>
      ) : (
        <div className="flex items-center justify-center flex-col gap-3 h-screen">
          Please login first <LoginButton className="w-5" />
        </div>
      )}
      </div>

    </div>
  );
};

export default SectorDash;
