import React from "react";
import { useRef, useState, useEffect } from "react";
import NewsRow from "./NewsRow";
import Loader from "../tearSheet/loader";
import { RECENT_NEWS } from "../../constant/api";


const Upper_Row = ({ newsRefresher }) => {
  const duration = 120;
  const [timeLeftToRefresh, setTimeLeftToRefresh] = useState(duration);
  const timer = useRef(
    {
      id: null,
      seTimer: function () {
        if (this.id) {
          clearInterval(this.id);
        }
        setTimeLeftToRefresh(duration);
        this.id = setInterval(() => {
          setTimeLeftToRefresh(pre => {

            if (pre <= 1) {
              newsRefresher();
              return duration;
            }
            else {
              return pre - 1;
            }
          });
        }, 1000);
      },
      clearTimer: function () {
        if (this.id) {
          clearInterval(this.id);
        }
      }
    }
  )

  const deg = (360 / duration) * timeLeftToRefresh;
  const d = new Date();
  const date = d.getDate();
  const month = d.getMonth();
  const year = d.getFullYear();

  useEffect(() => {
    timer.current.seTimer();
    return () => timer.current.clearTimer();
  }, [])

  return (
    <div className="flex justify-between items-center p-4 w-full bg-white rounded-lg h-24">
      <p
        className=" font-semibold text-lg"
      >
        Today {date}/{month + 1}/{year}
      </p>
      <div
        onClick={() => { newsRefresher(); timer.current.seTimer(); }}
        className=" w-10 h-10 bg-primaryBlue rounded-full flex items-center justify-center cursor-pointer"
        style={{ background: `conic-gradient(#3E91EE ${deg}deg, white ${deg}deg)` }}
      >
        <div className=" w-8 h-8 bg-white rounded-full flex items-center justify-center">
          <span className=" text-[0.75rem]">{timeLeftToRefresh}</span>
        </div>
      </div>

    </div>
  )
}



const LatestNews = () => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const newsRefresher = async () => setNewsData([]);


  const triggerElement = useRef();

  useEffect(() => {
    const element = triggerElement.current;
    let status = "";

    const observer = new IntersectionObserver(
      (items, obs) => {
        if (!status && items[0].isIntersecting) {
          setLoading("loading");
          status = "loading";

          (async () => {
            setLoading(true);
            const res = await fetch(RECENT_NEWS + `?offset=${newsData.length}&limit=20`);

            if (res.ok) {
              const data = await res.json();
              setLoading(false);
              setNewsData((pre) => [...pre, ...data]);
            }
          })();
          obs.unobserve(items[0].target);
        }
      },
      {
        rootMargin: "250px",
      }
    );
    observer.observe(element);

    return () => { observer.unobserve(element) }
  }, [newsData]);

  return (
    <div className="lg:w-1/2 sm:w-full flex flex-col gap-3">

      <Upper_Row newsRefresher={newsRefresher} />

      <div className="flex flex-col gap-3 px-1 h-full overflow-y-auto">
        {newsData?.map((item, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-lg">
            <NewsRow news={item} />
          </div>
        ))}
        <div
          className=" w-full flex items-center justify-center"
          ref={triggerElement}
        >
          {loading && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
