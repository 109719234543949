import React from "react";
import Highcharts from "highcharts";
import {
  HighchartsProvider,
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  Title,
  Subtitle,
  Legend,
  ColumnSeries,
  Tooltip,
} from "react-jsx-highcharts";
import { useState, useEffect } from "react";

const plotOptions = {
  series: {
    pointStart: 1,
  },
};

const FinancialBalanceSheet = ({ data, xAxis }) => {
  const [seriesData, setSeriesData] = useState([]);
  const [seriesName, setSeriesName] = useState([]);

  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      const seriesAmount = data.map((item, i) => item?.Value);
      const seriesName = data.map((item, i) => item?.name);

      setSeriesData(seriesAmount);
      setSeriesName(seriesName);
    } else {
      // console.error("Data is undefined, not an array, or empty.");
    }
  }, [data]);

  return (
    <div className="app">
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsChart>
          <Chart />

          <XAxis categories={seriesName}>
            <XAxis.Title>{xAxis}</XAxis.Title>
          </XAxis>
          <Tooltip />
          <YAxis>
            <ColumnSeries
              color="#BAE0FF"
              name="Amount"
              data={seriesData}
              dataLabels={{
                enabled: true,
                style: { fontSize: "10px", color: "#000000" },
              }}
            />
          </YAxis>
        </HighchartsChart>
      </HighchartsProvider>
    </div>
  );
};

export default FinancialBalanceSheet;
