import axios from "axios";
import React, { useEffect, useState } from "react";
import factacy from "../../assets/Factacy-grayLogomark (1).png";
import arrow from "../../assets/sidebar assets/arrow.svg";
import { useAuth } from "../auth/authContext";
import LoginButton from "../auth/loginButton";
import { goToCompanyPage } from "../../constant/function";
import { handleSectorClick } from "../../constant/function";
import { UNICORNS_COMPANIES } from "../../constant/api";

const Item = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className=" lg:flex w-full px-1 py-2 pl-2 lg:py-3 lg:px-4 border-b-[1px] hover:bg-gray-100 bg-white">
      <div className="flex-1 flex justify-between">
        <div className="flex gap-2 w-full">
          <img
            src={data?.LOGO_URL ?? factacy}
            className="w-12 h-6 object-contain"
            alt="company logo"
          />
          <p
            className="w-full hover:text-primaryBlue hover:underline cursor-pointer"
            onClick={() =>data?.Reference_Id && data?.MCA_COMPANY_NAME && goToCompanyPage(data?.Reference_Id, [data?.MCA_COMPANY_NAME])}
          >
            {data?.MCA_COMPANY_NAME}
          </p>
        </div>
        <p className="w-full sm:text-xs lg:text-base">{data?.CITY_STATE}</p>
        <img src={arrow} className={`h-6 lg:hidden cursor-pointer ${isOpen ? "rotate-180" : ""}`} onClick={() => setIsOpen(pre => !pre)} alt={"dropdownIcon"} />
      </div>

      <div className="flex-1 justify-between sm:hidden lg:flex">
        <p className={`w-full`}>{data?.UNICORN_ENTRY_YEAR}</p>
        <p
          className="w-full hover:text-primaryBlue hover:underline cursor-pointer "
          onClick={() => handleSectorClick(data?.SECTOR_ID[0])}
        >
          {data?.SECTOR_ID[1]}
        </p>
      </div>

      {isOpen && <div className="mt-4 sm:flex flex-col lg:hidden flex-1 justify-between">
        <p className=""><span className="font-medium pr-2">Entery in Unicron club:</span>{data?.UNICORN_ENTRY_YEAR}</p>
        <p
          className="w-full hover:text-primaryBlue hover:underline cursor-pointer "
          onClick={() => handleSectorClick(data?.SECTOR_ID[0])}
        >
          <span className="font-medium pr-2">Sector: </span> {data?.SECTOR_ID[1]}
        </p>
      </div>}
    </div>
  )
}

const Unicorn = () => {
  const [unicornData, setUnicornData] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchUnicornData = async () => {
      try {
        const response = await axios.get(UNICORNS_COMPANIES);
        setUnicornData(response.data);
      } catch { }
    };
    fetchUnicornData();
  }, []);

  return (
    <div className="lg:pt-0 px-4 sm:pt-20 sm:w-screen lg:w-full flex flex-col">
      <div
        className="flex flex-col w-full bg-[#F4F5F7] rounded-xl lg:px-5 sm:px-4 lg:py-3 sm:py-3 justify-between gap-5"
        style={{
          boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
        }}
      >
        <p className="font-medium text-[25px] tracking-tight leading-9">
          Unicorns of India
        </p>

        {user.isAuthenticated === true ? (
          <div className="border-2 rounded-xl">
            <div className="flex w-full justify-evenly py-3 px-4 bg-slate-200 rounded-t-xl font-semibold">
              <p className="w-full">Name</p>
              <p className="w-full">Location</p>
              <p className="w-full hidden lg:block">Year</p>
              <p className="w-full hidden lg:block">Industry Classification</p>
            </div>

            {unicornData.map((unicorn, index) => <Item key={index} data={unicorn} />)}
          </div>
        ) : (
          <div className="flex items-center justify-center flex-col gap-3 h-screen">
            Please login first <LoginButton className="w-5" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Unicorn;
