import { createSlice } from "@reduxjs/toolkit";

const investorReducer = createSlice({
  name: "investorReducer",
  initialState: {
    loading: false,
    stageloading: false,
    data: {},
    errorMsg: "",
    investor: "",
    investorData: [],
    investorDealsData: [],
    investorCohortData: {
      peopleCohort: [],
      companyCohort: [],
    },
    investorNews: [],
    investorSectorsChartData: [],
  },
  reducers: {
    INVESTOR_SEARCH_LOADING: (state, action) => {
      return { ...state, loading: true, errorMsg: "" };
    },
    INVESTOR_SEARCH_FAIL: (state, action) => {
      return { ...state, loading: false, errorMsg: "Unable to find investor" };
    },

    INVESTOR_DATA_SUCCESS: (state, action) => {
      const { data, investorName } = action.payload;
      return {
        ...state,
        loading: false,
        errorMsg: "",
        investorData: {
          ...state.investorData,
          [investorName]: data,
        },
      };
    },

    INVESTOR_DEALS_SUCCESS: (state, action) => {
      const { investorDealsData, investorName } = action.payload;
      return {
        ...state,
        loading: false,
        errorMsg: "",
        investorDealsData: {
          ...state.investorDealsData,
          [investorName]: investorDealsData,
        },
      };
    },

    INVESTOR_COHORT_SUCCESS: (state, action) => {
      const { peopleCohort, companyCohort } = action.payload;
      return {
        ...state,
        investorCohortData: {
          ...state.investorCohortData,
          peopleCohort: peopleCohort || state.investorCohortData.peopleCohort,
          companyCohort:
            companyCohort || state.investorCohortData.companyCohort,
        },
      };
    },

    INVESTOR_NEWS_SUCCESS: (state, action) => {
      const { investorNews, investorId } = action.payload;
      return {
        ...state,
        investorNews: {
          ...state.investorNews, [investorId]: investorNews
        },
      };
    },

    INVESTOR_SECTOR_CHART: (state, action) => {
      const { investorSectorsChartData, investorId } = action.payload;
      return {
        ...state,
        investorSectorsChartData: { ...state.investorSectorsChartData, [investorId]: investorSectorsChartData }
      };
    },

    INVESTOR_SIMILAR_INVESTORS: (state, action) => {
      const { data, investorId } = action.payload;
      return {
        ...state,
        similarInvestors: { ...state.similarInvestors, [investorId]: data }
      };
    },
  },
});

export const {
  INVESTOR_SEARCH_LOADING,
  INVESTOR_SEARCH_FAIL,
  INVESTOR_DATA_SUCCESS,
  INVESTOR_DEALS_SUCCESS,
  INVESTOR_COHORT_SUCCESS,
  INVESTOR_NEWS_SUCCESS,
  INVESTOR_SECTOR_CHART,
  INVESTOR_SIMILAR_INVESTORS,
} = investorReducer.actions;
export default investorReducer.reducer;