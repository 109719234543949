import React from 'react';
import Highcharts from 'highcharts';
import {
  HighchartsProvider, HighchartsChart, Chart, XAxis, YAxis, LineSeries
} from 'react-jsx-highcharts';

const plotOptions = {
  series: {
    pointStart: 2019
  }
};

const LineGraphForRatios = ({data}) => {
    return (
  <div className="app w-full lg:w-1/2">
    <HighchartsProvider Highcharts={Highcharts}>
      <HighchartsChart plotOptions={plotOptions}>
        <Chart />
        <XAxis>
          <XAxis.Title>Financial Year</XAxis.Title>
        </XAxis>
        <YAxis>
          <YAxis.Title>Ratio</YAxis.Title>
          <LineSeries name="Ratios" data={data} dataLabels={{ enabled: true, style: { fontSize: '10px', color: '#000000' }}} />
        </YAxis>
      </HighchartsChart>
    </HighchartsProvider>
  </div>
);
}

export default LineGraphForRatios;