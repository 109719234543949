import { goToInvestorPage } from "./function";

export const Investor_Pill = ({ investor }) => (
  <span
    className={`cursor-pointer ${investor[0] && "hover:text-primaryBlue hover:underline"
      }  text-xs font-semibold py-1 px-3 rounded-xl w-max bg-gray-100 border-[1px] border-gray-200`}
    onClick={() => goToInvestorPage(investor[1], investor[0])}
  >
    {investor != null && investor[1].toUpperCase()}
  </span>
);

export const Inventor_Pill = ({ inventor }) => (
  <span
    className={`text-xs font-semibold py-1 px-3 rounded-xl w-max bg-gray-100 border-[1px] border-gray-200`}
  >
    {inventor != null && inventor?.toUpperCase()}
  </span>
);

export const Sector_Pill = ({ sector }) =>
  sector &&
  sector[1] && (
    <span
      className={`cursor-pointer hover:underline hover:text-primaryBlue text-xs font-semibold py-1 px-3 rounded-xl w-max bg-gray-100 border-[1px] border-gray-200`}
      onClick={() =>
        sector[0] && window.open(`/sector-intelligence/sector/${sector[0]}`)
      }
    >
      {sector != null ? sector[1]?.toUpperCase() : "Unconfirmed"}
      <br />
    </span>
  );
