import React, { useEffect, useState } from "react";
import Loader from "../tearSheet/loader";
import { useSelector } from "react-redux";
import useFetchFundingNews from '../../hooks/News/useFetchFundingNews';
import IndividualNews from "./components/IndividualNews";

const FundingNews = () => {
  const [loading, error, getFundingNews] = useFetchFundingNews();
  const fullNewsArtIds = useSelector(store => store?.news_intelligence?.fundingNews);
  const [displayCount, setDisplayCount] = useState(10);
  const increment = 10;

  useEffect(() => {
    getFundingNews();
  }, []);

  return (
    <div className="lg:pt-0 px-4 sm:pt-20 sm:w-screen lg:w-full flex flex-col">
      <div
        className="flex flex-col w-full bg-[#F4F5F7]  rounded-xl lg:px-5 sm:px-4 lg:py-3 sm:py-3 justify-between gap-5"
        style={{
          boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
        }}
      >
        <p className="font-medium text-[25px] tracking-tight leading-9">
          Funding News
        </p>
        {loading && (
          <div className="w-full flex h-screen justify-center">
            <div className="fixed h-full">
              <Loader />
            </div>
          </div>
        )}
        <div className="flex flex-col w-full gap-8">
          {fullNewsArtIds ? fullNewsArtIds?.slice(0, displayCount)?.map((item) => (<IndividualNews Art_Id={item} key={item} />))
            : "No results found :("}
          <div className="flex justify-center p-3">
            {displayCount < fullNewsArtIds.length && (
              <button
                className="text-sm hover:text-white p-2 hover:bg-primaryBlue flex gap-1 items-center bg-white border-[1px] text-primaryBlue border-primaryBlue"
                style={{
                  boxShadow:
                    "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)",
                }}
                onClick={() => setDisplayCount(pre => pre + increment)}
              >
                <p>Load More</p>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundingNews;