import React, { useState } from "react";
import factacy from "../assets/Factacy-grayLogomark (1).png";
import redirect from "../assets/redirect.png";
import useMediaQuery from "../hooks/useMediaQuery";
import { goToSingleNewsPage, howOldTimeUpdater, goToCompanyPage } from "../constant/function";

const IndividualNews = ({ news }) => {
  const [showFullContent, setShowFullContent] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const toggleShowContent = (newsId) => {
    setShowFullContent((prevState) => ({
      ...prevState,
      [newsId]: !prevState[newsId],
    }));
  };

  return (
    <div className="sm:relative lg:static w-full lg:flex-row flex sm:flex-col sm:h-[600px] lg:h-max gap-6 bg-white lg:p-5 rounded-sm items-start  lg:justify-start sm:justify-end sm:bg-gradient-to-t from-white to-transparent z-10 shadow-sm">
      <div className="sm:absolute lg:static lg:w-44 lg:h-36 sm:w-fit sm:h-[400px] sm:p-0 lg:mb-0 sm:mb-[203px]">
        <img
          onError={(e) => {
            e.target.src = factacy;
            e.target.alt = "Alternative Image";
          }}
          onClick={() => goToSingleNewsPage(news?.Art_Id, news?.headline)}
          src={news?.image}
          className="lg:w-40 lg:h-36 sm:h-[500px] rounded-2xl object-cover cursor-pointer"
        />
      </div>
      <div className="sm:absolute lg:static w-full flex flex-col gap-6 justify-between sm:rounded-2xl sm:px-4 sm:pb-4 sm:pt-20 lg:pb-0 sm:w-fit lg:px-0 lg:pt-0 lg:bg-none lg:w-full sm:bg-gradient-to-t from-white from-73% via-white via-70% to-transparent to-100%">
        <div className="flex flex-col gap-2">
          <div className="flex justify-between items-center">
            <h1
              className="lg:text-xl sm:text-lg font-bold hover:underline hover:text-primaryBlue hover:cursor-pointer"
              style={{
                filter: `${!isDesktop ? "drop-shadow(0 10px 8px rgb(255 255 255))" : ""
                  }`,
              }}
              onClick={() => goToSingleNewsPage(news?.Art_Id, news?.headline)}
            >
              {news?.headline}
            </h1>
          </div>
          <div className="text-gray-600">
            <div className="lg:text-base sm:text-sm">
              {showFullContent[news?.id] ? (
                <>
                  {news?.summary_of_article}
                  <p
                    className="text-sm text-primaryBlue inline cursor-pointer"
                    onClick={() => toggleShowContent(news?.id)}
                  >
                    {" "}
                    Read Less{" "}
                  </p>
                </>
              ) : (
                <>
                  {news?.summary_of_article?.slice(0, 200)}
                  <p
                    className="text-sm text-primaryBlue inline cursor-pointer "
                    onClick={() => toggleShowContent(news?.id)}
                  >
                    {" "}
                    ...Read more{" "}
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="flex items-center gap-6">
            <div className="flex items-center gap-1">
              <a
                className="text-primaryBlue text-sm cursor-pointer"
                target="_blank"
                onClick={() => goToSingleNewsPage(news?.Art_Id, news?.headline)}
              >
                Source
              </a>
              <img src={redirect} className="w-3 h-3" />
            </div>

            {news?.Tagged_Org && (
              <div className="flex gap-1 flex-wrap max-h-[4rem] overflow-auto">
                {/* <p>Companies :</p> */}
                {news?.Tagged_Org &&
                  Object.entries(news?.Tagged_Org)?.map(([key, value], index) => (
                    <p className="text-black text-xs font-semibold bg-blue py-1 px-2 rounded-full cursor-pointer" onClick={() => goToCompanyPage(value[1], [value[2]], value[0], [key])}>{key} </p>
                  ))}
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div>
            <p className="text-xs text-gray-500">
              {howOldTimeUpdater(news?.Unique_date_time)}
            </p>
          </div>
          {news?.summary_IC ? (
            <p className="text-xs py-1 px-3 rounded-full bg-purple w-max">
              {" "}
              {news?.summary_IC === "nan" ? "Others" : news?.summary_IC}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
export default IndividualNews;
