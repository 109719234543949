import React, { useState } from 'react'
import { Route, Routes } from 'react-router'
import SectorPage from './sectorPage'
import SectorSearch from './sectorSearch'
import SideBarLayoutSector from './sideBarLayoutSector'
import SectorDash from './sectorDash'
import Sidebar from '../sideBarMain'
import UnderConstruction from '../underConstruction'
import Errorpage from '../errorpage'
import MetaTagesAdder from '../MetaTagesAdder'



const SectorIntelligence = () => {
  return (
    <div className="w-full h-screen overflow-y-auto pt-20">
      <Routes>
        <Route className='w-full' element={<SectorPage />} path='/sector/:sector' />
        <Route className='w-full' element={<MetaTagesAdder><SectorSearch /> </MetaTagesAdder>} path='/sector-search' />
        <Route element={<MetaTagesAdder><SectorDash /> </MetaTagesAdder>} path='/sector-dashboard' />
        <Route element={<MetaTagesAdder><UnderConstruction /> </MetaTagesAdder>} path='/investors-smes' />
        <Route element={<MetaTagesAdder><UnderConstruction /> </MetaTagesAdder>} path='/relevent-companies' />
        <Route element={<MetaTagesAdder><UnderConstruction /> </MetaTagesAdder>} path='/sector-innovations' />
        <Route element={<Errorpage />} path='/*' />
      </Routes>
    </div>
  )
}
export default SectorIntelligence