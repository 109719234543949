import React from "react";
import {
    getCurrentMonth,
    handleSectorClick,
    goToCompanyPage,
    goToInvestorPage,
} from "../../constant/function";
import factacy from "../../assets/factacy_logo.svg";
import redirect from "../../assets/redirect.png";
import doubleArrow from "../../assets/arrow-down-double-line.png";

// const IndividualOpenDeal = ({ deal }) => {
//     const classification_formatter = (classification) => Array.isArray(classification) ? classification?.every(item => Array.isArray(item)) ? classification : [classification] : [['', classification]];
//     const CIN = deal?.Output_CIN !== "No" && deal?.Output_CIN !== "null" && deal?.Output_CIN;
//     const amount = deal?.Corrected_Amount !== "No" ? deal?.Corrected_Amount : '';
//     const investee = deal?.Corrected_Investee !== "No" && deal?.Corrected_Investee !== "UNDISCLOSED" ? deal?.Corrected_Investee : '';
//     const vision = deal?.Corrected_Vision[0] !== "-" ? "..." + deal?.Corrected_Vision[0]?.slice(0, 100) + "..." : "";
//     const Segment = deal?.Segment;
//     const Tags = deal?.Tags;
//     const Reference_Id = deal["Reference_Id"];
//     const Legal_Name = deal["Legal_Name"];

//     const handleIdClick = (id, investee, series, investors) => {
//         window.open(
//             `/deal-intelligence/deal/${id}/${investee}-raises-/${series}-round-with-/${investors && investors[0].length > 0 ? investors.map((investors) => investors[1]) : "undisclosed"
//             }`
//         );
//     };

//     return (
//         <div className='flex flex-col gap-4 items-center w-[24rem] bg-white p-4 rounded-md justify-between shadow-md' >
//             <div className='flex flex-col gap-3 items-center w-full'>
//                 <div className="rounded-lg border-[1px] flex items-center p-1 w-14 h-14">
//                     <img
//                         className="w-10 h-10 rounded-full object-contain"
//                         src={deal?.Logo_Url ? deal?.Logo_Url : factacy}
//                     />
//                 </div>
//                 <div className=' flex justify-end w-full text-sm text-gray-600 font-medium'>
//                     {deal?.Unique_date_time[0] && getCurrentMonth(deal?.Unique_date_time[0]).month + ", " + getCurrentMonth(deal?.Unique_date_time[0]).year}
//                 </div>
//                 <h3
//                     className={`text-center text-primaryBlue font-semibold text-2xl ${Reference_Id && Legal_Name && "hover:underline cursor-pointer"}`}
//                     onClick={() => Reference_Id && Legal_Name && goToCompanyPage(Reference_Id, [Legal_Name])}
//                 >
//                     {investee}</h3>
//                 <p className='cursor-pointer uppercase hover:underline hover:text-primaryBlue text-xs font-semibold py-1 px-3 rounded-xl w-max bg-gray-100 border-[1px] border-gray-200'
//                     onClick={() => classification_formatter(deal?.Sector_Classification)[0][0] && handleSectorClick(classification_formatter(deal?.Sector_Classification)[0][0])}>
//                     {classification_formatter(deal?.Sector_Classification)[0][1]}</p>
//                 <h4 className='text-center min-h-[5.4rem]'>{vision}</h4>
//                 {amount &&
//                     (<div className='flex justify-center w-fit p-3 rounded-xl bg-green'>
//                         <span className='text-lg font-bold text-gray-700'>{amount}</span>
//                     </div>)
//                 }

//             </div>
//             <div className=' w-full'>
//                 <div className="font-semibold text-xs flex flex-col max-h-16 w-full overflow-y-auto gap-5">
//                     <p>
//                         {deal.Corrected_Investors !== null && deal?.Corrected_Investors !== "UNDISCLOSED" &&
//                             typeof deal.Corrected_Investors !== String &&
//                             deal.Corrected_Investors != [] &&
//                             deal.Corrected_Investors?.length > 0
//                             ? deal.Corrected_Investors?.slice(0, 4).map((ar1, index1) => (
//                                 <span
//                                     className={`cursor-pointer hover:underline sm:text-xs lg:text-[0.86rem] sm:font-semibold hover:text-primaryBlue`}
//                                     key={index1}
//                                     onClick={() => goToInvestorPage(ar1[1], ar1[0])}
//                                 >

//                                     {ar1[1]}
//                                     {index1 !== deal.Corrected_Investors?.length - 1
//                                         ? // <br />
//                                         ",  "
//                                         : null}
//                                 </span>
//                             ))
//                             : deal.Corrected_Investors}
//                     </p>
//                 </div>
//                 <div className='w-full flex justify-between mt-12'>
//                     <img src={redirect}
//                         className=' h-4 cursor-pointer hover:scale-110'
//                         alt="source"
//                         onClick={() =>
//                             handleIdClick(
//                                 deal.id,
//                                 deal.Corrected_Investee,
//                                 deal.Corrected_Series,
//                                 deal.Corrected_Investors
//                             )
//                         }
//                     />
//                     <img src={doubleArrow} onClick={() => Reference_Id && Legal_Name && goToCompanyPage(Reference_Id, [Legal_Name])} className={` -rotate-90 ${Reference_Id && Legal_Name && "cursor-pointer hover:scale-125"}`} alt="info" />
//                 </div>
//             </div>
//         </div>


//     );
// };

// export default IndividualOpenDeal;


const IndividualOpenDeal = ({ deal }) => {
    const classification_formatter = (classification) => Array.isArray(classification) ? classification?.every(item => Array.isArray(item)) ? classification : [classification] : [['', classification]];
    const CIN = deal?.Output_CIN !== "No" && deal?.Output_CIN !== "null" && deal?.Output_CIN;
    const amount = deal?.Corrected_Amount !== "No" ? deal?.Corrected_Amount : '';
    const investee = deal?.Corrected_Investee !== "No" && deal?.Corrected_Investee !== "UNDISCLOSED" ? deal?.Corrected_Investee : '';
    const vision = deal?.Corrected_Vision[0] !== "-" ? "..." + deal?.Corrected_Vision[0]?.slice(0, 100) + "..." : "";
    const Segment = deal?.Segment;
    const Tags = deal?.Tags;
    const Reference_Id = deal["Reference_Id"];
    const Legal_Name = deal["Legal_Name"];

    const handleIdClick = (id, investee, series, investors) => {
        window.open(
            `/deal-intelligence/deal/${id}/${investee}-raises-/${series}-round-with-/${investors && investors[0].length > 0 ? investors.map((investors) => investors[1]) : "undisclosed"
            }`
        );
    };

    return (
        <div className='flex flex-col gap-4 items-center w-[24rem] bg-white p-4 rounded-md justify-between shadow-md' >
            <div className='flex flex-col gap-3 items-center w-full'>
                <div className=" flex w-full gap-2">
                    <div className="rounded-lg border-[1px] flex items-center p-1 w-14 h-14">
                        <img
                            className="w-10 h-10 rounded-full object-contain"
                            src={deal?.Logo_Url ? deal?.Logo_Url : factacy}
                        />
                    </div>
                    <div className=" flex-1">
                        <h3
                            className={`w-xs text-center text-primaryBlue font-semibold text-lg ${Reference_Id && Legal_Name && "hover:underline cursor-pointer"}`}
                            onClick={() => Reference_Id && Legal_Name && goToCompanyPage(Reference_Id, [Legal_Name])}
                        >
                            {investee}</h3>
                    </div>
                    <div className='text-sm text-gray-600 font-medium'>
                        {deal?.Unique_date_time[0] && getCurrentMonth(deal?.Unique_date_time[0]).month + ", " + getCurrentMonth(deal?.Unique_date_time[0]).year}
                    </div>
                </div>
                <div className=" flex flex-wrap gap-3 mt-2">
                    {classification_formatter(deal?.Sector_Classification)[0][1] &&
                        (<p className='cursor-pointer uppercase hover:underline hover:text-primaryBlue text-xs font-semibold py-1 px-3 rounded-xl w-max bg-gray-100 border-[1px] border-gray-200'
                            onClick={() => classification_formatter(deal?.Sector_Classification)[0][0] && handleSectorClick(classification_formatter(deal?.Sector_Classification)[0][0])}>
                            {classification_formatter(deal?.Sector_Classification)[0][1]}</p>)
                    }

                    {Tags?.[0] !== "No" && Tags?.[0]?.split(",")?.[0] && (<p className='uppercase text-xs font-semibold py-1 px-3 rounded-xl w-max bg-gray-100 border-[1px] border-gray-200'>
                        {Tags?.[0]?.split(",")?.[0]
                        }</p>)}
                    {Segment?.[0] !== "No" && Segment?.[0]?.split(",")?.[0] && (<p className='uppercase text-xs font-semibold py-1 px-3 rounded-xl w-max bg-gray-100 border-[1px] border-gray-200'>
                        {Segment?.[0]?.split(",")?.[0]
                        }</p>)}
                </div>

                <h4 className='text-center min-h-[5.4rem]'>{vision}</h4>
                {amount &&
                    (<div className='flex justify-center w-fit p-3 rounded-xl bg-green'>
                        <span className='text-lg font-bold text-gray-700'>{amount}</span>
                    </div>)
                }

            </div>
            <div className=' w-full'>
                <div className="font-semibold text-xs flex flex-col max-h-16 w-full overflow-y-auto gap-5">
                    <p>
                        {deal.Corrected_Investors !== null && deal?.Corrected_Investors !== "UNDISCLOSED" &&
                            typeof deal.Corrected_Investors !== String &&
                            deal.Corrected_Investors != [] &&
                            deal.Corrected_Investors?.length > 0
                            ? deal.Corrected_Investors?.slice(0, 4).map((ar1, index1) => (
                                <span
                                    className={`cursor-pointer hover:underline sm:text-xs lg:text-[0.86rem] sm:font-semibold hover:text-primaryBlue`}
                                    key={index1}
                                    onClick={() => goToInvestorPage(ar1[1], ar1[0])}
                                >

                                    {ar1[1]}
                                    {index1 !== deal.Corrected_Investors?.length - 1
                                        ? // <br />
                                        ",  "
                                        : null}
                                </span>
                            ))
                            : deal.Corrected_Investors}
                    </p>
                </div>
                <div className='w-full flex justify-between mt-12'>
                    <img src={redirect}
                        className=' h-4 cursor-pointer hover:scale-110'
                        alt="source"
                        onClick={() =>
                            handleIdClick(
                                deal.id,
                                deal.Corrected_Investee,
                                deal.Corrected_Series,
                                deal.Corrected_Investors
                            )
                        }
                    />
                    <img src={doubleArrow} onClick={() => Reference_Id && Legal_Name && goToCompanyPage(Reference_Id, [Legal_Name])} className={` -rotate-90 ${Reference_Id && Legal_Name && "cursor-pointer hover:scale-125"}`} alt="info" />
                </div>
            </div>
        </div>


    );
};

export default IndividualOpenDeal;