import React, { useState } from 'react'
import LineGraphForRatios from '../../charts/lineGraphForRatios';

const DataComp = ({ data = {}, title }) => {

    const values = {};
    if (!data) return null

    for (let key in data) {
        if (key !== "SUMMARY" && key !== "RANKING" && key !== "SECTOR_RATIO") {
            const updateKey = key.split("_")?.filter(item => parseInt(item)).map(item => "20" + item).join("-");
            values[updateKey] = data[key];
        }
    }

    const summary = data?.["SUMMARY"] ?? "";

    const graphValues = (() => {
        const obj = { ...values };
        let startYear = 2018;
        let index = 0;
        while (undefined === obj[`${startYear + index}-${startYear + index + 1}`]) {
            obj[`${startYear + index}-${startYear + index + 1}`] = null;
            index++;
        }
        return Object.keys(obj).sort().map(key => obj[key]);
    })();

    const getColor = (index) => {
        const colors = [
            "bg-lightGreen",
            "bg-lightBlue",
            "bg-lightPurple",
            "bg-lightYellow",
            "bg-lightRed",
        ];
        return colors[index % colors.length];
    };
    return (<>
        <p className="font-roboto font-semibold">{title}</p>
        <div className="flex flex-col lg:flex-row">
            <LineGraphForRatios data={graphValues} />
            <p className="w-full lg:w-1/2 text-sm text-gray-600">{summary}</p>
        </div>

        <div className="grid sm:grid-cols-2 lg:grid-cols-5 gap-4">
            {Object.keys(values)?.map((key, index) => (
                <div
                    key={index}
                    className={`w-full p-5 min-h-48 max-h-56 rounded-xl flex flex-col items-center justify-center gap-2 ${getColor(
                        index
                    )}`}
                >
                    <p className="text-xs text-gray-600 font-semibold">
                        {key}
                    </p>
                    <p className="sm:text-2xl lg:text-3xl font-bold">
                        {values[key] ? parseFloat(values[key]).toFixed(2) : 0}
                    </p>
                    <p className='text-xs mt-2'>Industry Scores</p>

                    <div className='flex gap-5 justify-between w-full'>
                   {data?.["SECTOR_RATIO"] && <div className='flex flex-col items-center'>
                    <p className='text-xs'>Ratio</p>
                    <p className='text-2xl font-semibold'>{Object.values(data?.["SECTOR_RATIO"]) ? parseFloat(Object.values(data?.["SECTOR_RATIO"])?.[index]).toFixed(2) : ""}</p></div>}

                    {data?.["RANKING"] && <div className='flex flex-col items-center '>
                    <p className='text-xs'>Ranking</p>
                    <p className='text-2xl font-semibold'>{Object.values(data?.["RANKING"]) ? Object.values(data?.["RANKING"])?.[index] : ""}</p></div>}
                    </div>
                </div>))}
        </div>
    </>);
}



const Key_Indicators = ({ RATIO_AND_SUMMARY = {} }) => {

    const tabs = {};

    for (const key in RATIO_AND_SUMMARY) {
        const value = key?.split("_")?.map(subItem => subItem?.[0]?.toUpperCase() + subItem?.slice(1))?.join(" ");
        tabs[value] = key;
    }
    const [activeratio, setActiveRatio] = useState(Object.keys(tabs)?.[0]);

    return (
        <div className="flex flex-col gap-8 bg-white sm:w-full rounded-xl lg:p-8 sm:p-4 ">
            <div className="flex flex-col gap-4 lg:gap-8">
                <div className="flex gap-1">
                    <p className="font-medium text-[25px] tracking-tight leading-9 hover:underline hover:text-primaryBlue hover:cursor-pointer">
                        Key Indicators
                    </p>
                </div>
                <div className="flex flex-col lg:flex-row gap-4">
                    <div className="flex flex-row lg:flex-col sm:w-full lg:w-1/5 text-xs sm:overflow-x-scroll lg:overflow-x-auto">
                        {
                            Object.keys(tabs)?.map((item) => (
                                <div
                                    key={item}
                                    className={`p-3 cursor-pointer hover:bg-gray-50 ${activeratio === item ? "bg-gray-50 text-primaryBlue font-semibold" : "text-[#A1A1AA]"} `}
                                    onClick={() => setActiveRatio(item)}
                                >
                                    <p className=" w-max">{item}</p>
                                </div>))
                        }
                    </div>
                    <div
                        className="flex flex-col w-full gap-4"
                    >
                        <DataComp data={RATIO_AND_SUMMARY?.[tabs[activeratio]]} title={activeratio} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Key_Indicators;