import React, { useState, useEffect } from "react";
import Portfolio from "./InvestorPage/Portfolio";
import InvestorCohort from "./InvestorPage/InvestorCohort";
import InvestorNews from "./InvestorPage/InvestorNews";
import InvestorSectors from "./InvestorPage/InvestorSectors";
import ALT_Portfolio from "./InvestorPage/ALT_Portfolio";
import Similar_Investors from "./InvestorPage/Similar_Investors";
import LoginComponent from "../loginComponent";
import { useAuth } from "../auth/authContext";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const InvestorComponent = ({ investorId }) => {
  const queryName = "info";
  const { user } = useAuth();
  const investorData = useSelector((store) => store?.investor?.investorData?.[investorId]?.[0]);
  const searchParams = new URLSearchParams(window.location.search);
  const Navigate = useNavigate();

  const Tabs = {
    "portfolio": { TabName: "Portfolio", Component: <Portfolio investorId={investorId} /> },
    "news": { TabName: "News", Component: <InvestorNews investorId={investorId} investor = {investorData?.Investor} /> },
    "cohort": { TabName: "Cohort", Component: user?.isAuthenticated ? (<InvestorCohort investorId={investorId}  investor = {investorData?.Investor} />) : (<LoginComponent />) },
    "sectors": { TabName: "Sectors", Component: user?.isAuthenticated ? (<InvestorSectors investorId={investorId}  investor = {investorData?.Investor} />) : (<LoginComponent />) },
    "alt portfolio": { TabName: "ALT Portfolio", Component: user?.isAuthenticated ? (<ALT_Portfolio investorId={investorId} />) : (<LoginComponent />) },
    "similar investors": { TabName: "Similar Investors", Component: user?.isAuthenticated ? (<Similar_Investors investorId={investorId}  investor = {investorData?.Investor} />) : (<LoginComponent />) },
  };


  const queries = Object.keys(Tabs);
  if (!searchParams.get(queryName) || !queries.includes(searchParams.get(queryName))) {
    searchParams.set(queryName, queries[0])
    window.history.replaceState(null, null, `?${searchParams.toString()}`)
  }

  return (
    <div>
      <div className="flex justify-between text-center w-full sm:overflow-auto lg:overflow-auto bg-[#FAFAFA] rounded-t-xl">
        {Object.keys(Tabs).map((item, index) => (
          <div
            key={index}
            className={` lg:w-full sm:w-full  h-max bg-[#FAFAFA] flex flex-col w-full item lg:text-sm sm:text-xs items-center px-4 py-4  font-roboto hover:bg-white 
                ${searchParams.get(queryName) === item
                ? "bg-white text-primaryBlue font-bold relative z-100"
                : "text-[#A1A1AA]"
              }
              `}
            onClick={() => Navigate(`?info=${item}`)}
          >
            <div className="cursor-pointer sm:w-max lg:w-full">{Tabs?.[item]?.TabName}</div>
          </div>
        ))}
      </div>
      <div className="relative z-200">{Tabs?.[searchParams.get(queryName)]?.Component}</div>
    </div>
  );
};

export default InvestorComponent;